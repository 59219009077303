import React from "react";
import { Redirect } from "react-router-dom";

import { Form, Row, Col, Container, Spinner } from "react-bootstrap";

import apiClient from "../services/api";

import withPublicPanel from "../withPublicPanel";

const Login = (props) => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [toHome, setToHome] = React.useState(false);

    const [authError, setAuthError] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        apiClient.get("/sanctum/csrf-cookie").then((response) => {
            apiClient
                .post("/login", {
                    email: email,
                    password: password,
                })
                .then((response) => {
                    if (response.status === 204) {
                        props.login();
                        setToHome(true);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        setAuthError(true);
                    } else {
                        setUnknownError(true);
                        console.error(error);
                    }
                });
        });
    };

    if (toHome === true) {
        return <Redirect to="/" />;
    }

    return (
        <Row className="pt-5 pt-md-6 pb-9 bg-light">
            <Col>
                <Container>
                    <Row>
                        <Col md={7} xl={5}>
                            <div class="bg-white rounded-3 ms-xl-n5 px-4 py-5 pt-md-6 pb-md-5 ps-md-5 pe-md-9  pb-xl-5 pe-xl-15">
                                <h1>Login</h1>
                                <form onSubmit={handleSubmit}>
                                    <Form.Group
                                        controlId="formEmail"
                                        className="mt-md-2 mb-1"
                                    >
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="formPassword"
                                        className="mt-md-2 mb-1"
                                    >
                                        <Form.Label>Passwort</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>

                                    <div className="mt-4 mb-1">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-0"
                                        >
                                            Login
                                        </button>
                                    </div>

                                    {authError ? (
                                        <div className="alert alert-danger mt-3">
                                            Zugangsdaten nicht erkannt. Bitte
                                            versuche es nochmal.
                                        </div>
                                    ) : null}
                                    {unknownError ? (
                                        <div className="alert alert-danger mt-3">
                                            Das hat nicht funktioniert. Bitte
                                            versuche es nochmal.
                                        </div>
                                    ) : null}
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};

const LoginWithPublicPanel = withPublicPanel(Login);
export default LoginWithPublicPanel;
