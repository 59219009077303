import React from "react";

import withPublicPanel from "../withPublicPanel";

const Error404 = (props) => {
    return (
        <div class="text-center mt-6 mt-lg-10">
            <h1 class="display-1">404</h1>
            <h2>Dwit Dora!</h2>
            <p>Dreh' am besten um. Die Seite konnte nicht gefunden werden.</p>
        </div>
    );
};

const ErrorWithPublicPanel = withPublicPanel(Error404);
export default ErrorWithPublicPanel;
