import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import KyorugiAndi from "../images/content/IMG_0536-2.jpg";
import KyorugiBernd from "../images/content/IMG_8724.jpg";

const Kyorugi = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center mt-5">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row className="align-items-center">
                            <Col
                                md="6"
                                xxl="7"
                                className="pe-md-4 pe-xl-5 mb-6"
                            >
                                <h1 class="page-header text-lg-start">
                                    Jayu-Kyorugie
                                </h1>

                                <p>
                                    Jayu-Kyorugi, zu Deutsch Freikampf oder
                                    Wettkampf, wird in Form von
                                    Turnieren/Meisterschaften (Landes-, Staats,
                                    Kontinental- und Weltmeisterschaften)
                                    ausgetragen. Seit dem Jahr 2000 wurde
                                    Taekwondo in der Disziplin Jayu-Kyorugi
                                    erstmals bei den Olympischen Spielen
                                    praktiziert. Es gilt, eigene Techniken
                                    schnell und präzise anzubringen bzw.
                                    gegnerische Attacken im Ansatz zu erkennen
                                    und zu vereiteln. Der Kämpfer muss
                                    selbstständig und blitzschnell entscheiden.
                                    Jede Einseitigkeit im Kampfstil gilt es zu
                                    vermeiden und sämtliche
                                    Kombinationsmöglichkeiten sollten voll
                                    ausgeschöpft werden. Im rechtzeitigen
                                    Erkennen, Blocken und Kontern gegnerischer
                                    Angriffe besteht die eigene Überlegenheit.
                                    Der Wettkampf im Taekwondo ist ein
                                    Vollkontaktsport, daher unterliegt der
                                    Wettkämpfer strengen, sportlichen Regeln,
                                    den WT-Wettkampfregeln (siehe &nbsp;
                                    <a
                                        href="http://www.worldtaekwondo.org/rules-wt/rules.html"
                                        target="_blank"
                                    >
                                        http://www.worldtaekwondo.org/rules-wt/rules.html
                                    </a>
                                    ).
                                </p>
                            </Col>
                            <Col
                                md="6"
                                xl="5"
                                xxl="4"
                                className="d-none d-lg-block"
                            >
                                <img
                                    class="img-fluid"
                                    alt="Kyorugi Training bei Kwonbaek Vorau"
                                    src={KyorugiBernd}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + KyorugiAndi + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={KyorugiAndi}
                    alt="Kyorugi bei der Prüfung zum 1. Kup"
                />
            </Row>
        </>
    );
};

const KyorugiWithPublicPanel = withPublicPanel(Kyorugi);
export default KyorugiWithPublicPanel;
