import React, { useState } from "react";

import apiClient from "../../services/api";

import { Form, Row, Col, Button, Spinner } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachMembersAdd = (props) => {
    /*
     *   Add Member
     */
    const [newMemberState, setNewMemberState] = React.useState({
        firstname: "",
        lastname: "",
        passnumber: "",
        isActive: true,
    });
    const [startDate, setStartDate] = React.useState(new Date());
    const [birthday, setBirthday] = React.useState(new Date());

    const addMemberIsLoading = false;

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = {
            firstname: newMemberState.firstname,
            lastname: newMemberState.lastname,
            passnumber: newMemberState.passnumber,
            startDate: startDate,
            birthday: birthday,
            isActive: newMemberState.isActive,
        };

        apiClient
            .post("/api/member", data)
            .then((response) => {
                console.log(response.data);
                props.setUpdateSwitch(!props.updateSwitch);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewMemberState({
            ...newMemberState,
            [evt.target.name]: value,
        });
    }

    if (props.loggedIn) {
        return (
            <div className="mb-6 d-print-none">
                <h3>Add new member</h3>
                <Form id="athlete-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-md-2 mb-1">
                            <Form.Group controlId="formFirstname">
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstname"
                                    value={newMemberState.firstname}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-md-2 mb-1">
                            <Form.Group controlId="formLastname">
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastname"
                                    value={newMemberState.lastname}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6} xl={6} className="mt-md-2 mb-1">
                            <Form.Group controlId="formBirthday">
                                <Form.Label className="d-block">
                                    Birthday
                                </Form.Label>
                                <DatePicker
                                    selected={birthday}
                                    onChange={(date) => setBirthday(date)}
                                    dateFormat="d. MMMM yyyy"
                                    locale="de"
                                    className="form-control"
                                    calendarStartDay={1}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6} xl={3} className="mt-md-2 mb-1">
                            <Form.Group controlId="formPassnumber">
                                <Form.Label>Pass number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="passnumber"
                                    value={newMemberState.passnumber}
                                    onChange={handleChange}
                                />
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6} xl={3} className="mt-md-2 mb-1">
                            <Form.Group controlId="formStartDate">
                                <Form.Label className="d-block">
                                    Start Date
                                </Form.Label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="d. MMMM yyyy"
                                    locale="de"
                                    className="form-control"
                                    calendarStartDay={1}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6} xl={3} className="mt-md-2 mb-1">
                            <Form.Group
                                controlId="formIsActive"
                                className="mb-4"
                            >
                                <Form.Label className="d-block">
                                    Aktiv
                                </Form.Label>
                                <Form.Check
                                    name="isActive"
                                    checked={newMemberState.isActive}
                                    label=""
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mt-2 mt-md-3 mb-1">
                            <Button
                                type="submit"
                                //disabled={this.state.submitButtonDisabled}
                            >
                                {addMemberIsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    "Add member"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachMembersAdd;
