import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import FAQ from "./FAQ";

import withPublicPanel from "../withPublicPanel";

const Pricing = (props) => {
    return (
        <Row className="bg-light">
            <Col>
                <Container>
                    <h1 class="page-header">Mitgliedsbeiträge</h1>
                    <Row className="justify-content-center mb-5">
                        <Col
                            md="5"
                            lg="4"
                            xl="3"
                            className="d-flex flex-column bg-white px-5 py-4 mb-5 mb-md-0"
                        >
                            <h2 class="h5">Mitgliedsbeitrag</h2>
                            <div class="mt-auto pt-6">
                                <p class="mb-0">
                                    <span class="display-1">56</span>€
                                </p>
                                <p class="mb-4">/Quartal</p>
                                <p>zzgl. ÖTDV Jahresmarke € 19/jährlich </p>
                            </div>
                        </Col>
                        {/*
                        <Col md="1" className="d-none d-md-block" />
                        <Col
                            md="5"
                            lg="4"
                            xl="3"
                            className="d-flex flex-column bg-white px-5 py-4"
                        >
                            <h2 class="h5">Kinder</h2>
                            <div class="mt-auto pt-6">
                                <p class="mb-0">
                                    <span class="display-1">28</span>€
                                </p>
                                <p class="mb-4">/Quartal</p>
                                <p>Training nur Freitags</p>
                            </div>
                        </Col>
    */}
                    </Row>

                    <Row className="justify-content-center mb-7">
                        <Col md="11" lg="9" xl="7">
                            <h2 class="h4">Einmalige Kosten</h2>
                            <p>
                                Einschreibgebühr: € 37 pro Mitglied, inkl.
                                Taekwondopass und erster Jahresmarke (einmalig)
                            </p>

                            <h2 class="h4">Familienrabatt</h2>
                            <p>
                                ab 2 Familienmitgliedern: € 52 pro Quartal und
                                Mitglied
                            </p>
                        </Col>
                    </Row>

                    <div class="mb-7">
                        <FAQ />
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

const PricingWithPublicPanel = withPublicPanel(Pricing);
export default PricingWithPublicPanel;
