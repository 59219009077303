import React, { useState, useEffect } from "react";

import apiClient from "../../services/api";

import TimeHelper from "../helpers/TimeHelper";

import { Spinner } from "react-bootstrap";

import CoachCompetitionClubresults from "./CoachCompetitionClubresults";
import CoachCompetitionTeamresults from "./CoachCompetitionTeamresults";
import CoachCompetitionMemberresults from "./CoachCompetitionMemberresults";

import CoachCompetitionResultAdd from "./CoachCompetitionResultAdd";

import withCoachPanel from "../../withCoachPanel";

const CoachCompetition = (props) => {
    /*
     *   Show Competition
     */
    const [competition, setCompetition] = useState({
        club_results: [],
        team_results: [],
        member_results: [],
    });
    const [updateSwitch, setUpdateSwitch] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        var { id } = props.match.params;

        if (props.loggedIn) {
            setIsLoading(true);
            apiClient
                .get("/api/competition/" + id)
                .then((response) => {
                    setCompetition(response.data);
                    console.log("competition: ", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.loggedIn, updateSwitch]);

    const switchUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };

    if (props.loggedIn) {
        return (
            <>
                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <div class="mt-3 mt-md-5">
                        <p class="mb-1">
                            {TimeHelper.formatDate(competition.date)} in{" "}
                            {competition.location}
                        </p>
                        <h1>{competition.name}</h1>
                    </div>
                )}
                <CoachCompetitionResultAdd
                    {...props}
                    setIsLoading={setIsLoading}
                    switchUpdateSwitch={switchUpdateSwitch}
                    checkAuthError={props.checkAuthError}
                    competition_id={competition.id}
                />

                <CoachCompetitionMemberresults
                    memberresults={competition.member_results}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    switchUpdateSwitch={switchUpdateSwitch}
                    checkAuthError={props.checkAuthError}
                />
                <CoachCompetitionTeamresults
                    teamresults={competition.team_results}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    switchUpdateSwitch={switchUpdateSwitch}
                    checkAuthError={props.checkAuthError}
                />
                <CoachCompetitionClubresults
                    clubresults={competition.club_results}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    switchUpdateSwitch={switchUpdateSwitch}
                    checkAuthError={props.checkAuthError}
                />
            </>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachCompetitionWithCoachPanel = withCoachPanel(CoachCompetition);

export default CoachCompetitionWithCoachPanel;
