import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { FiFacebook, FiInstagram } from "react-icons/fi";

import withPublicPanel from "../withPublicPanel";

import Juergen from "../images/content/20180721-kuppruefung-31.jpg";

const Imprint = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col md="10" xl="8" xxl="6" className="px-xl-6">
                    <Container className="mb-5">
                        <h1 class="page-header">Impressum</h1>

                        <Row>
                            <Col lg="6">
                                <h2 class="h5">
                                    SV Tae Kwon Do Kwon Baek Vorau
                                </h2>
                                <p>
                                    Vornholz 121
                                    <br />
                                    A-8250 Vorau
                                </p>
                                <p>ZVR: 362352979</p>
                                <p>office@kwonbaek.com</p>
                                <p>
                                    <span class="me-2">
                                        <a
                                            href="https://fb.com/kwonbaekvorau"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FiFacebook />
                                        </a>
                                    </span>
                                    <span class="me-3">
                                        <a
                                            href="https://instagram.com/kwonbaekvorau"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FiInstagram />
                                        </a>
                                    </span>
                                    <small>#teamkwonbaek</small>
                                </p>
                            </Col>
                            <Col lg="6" className="ps-md-5">
                                <h2 class="h5">Bankverbindung</h2>
                                <p>
                                    Tae Kwon Do Kwon Baek Vorau
                                    <br />
                                    AT46 2081 5000 4466 7905
                                    <br />
                                    STSPAT2GXXX
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

const ImprintWithPublicPanel = withPublicPanel(Imprint);
export default ImprintWithPublicPanel;
