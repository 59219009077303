import React from "react";

import apiClient from "../../services/api";

import { Table, Form, Button, Spinner } from "react-bootstrap";
import { MdEdit } from "react-icons/md";

import CoachMembersAdd from "./CoachMembersAdd";
import withCoachPanel from "../../withCoachPanel";

const CoachMembers = (props) => {
    const [members, setMembers] = React.useState([]);
    const [membersActive, setMembersActive] = React.useState([]);

    const [updateSwitch, setUpdateSwitch] = React.useState(false);
    const [showActiveOnly, setShowActiveOnly] = React.useState(true);
    const showActiveOnlyAction = () => {
        setShowActiveOnly(!showActiveOnly);
    };

    const [isLoading, setIsLoading] = React.useState(false);

    const [showColumns, setShowColumns] = React.useState({
        grading: true,
        membershipPrice: true,
    });

    React.useEffect(() => {
        if (props.loggedIn) {
            setIsLoading(true);
            let query = showActiveOnly ? "?onlyActives=true" : "";
            apiClient
                .get("/api/member" + query)
                .then((response) => {
                    setMembers(response.data);
                    setMembersActive(
                        response.data.filter((member) => member.isActive)
                    );
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.loggedIn, updateSwitch, showActiveOnly]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const memberSince = (memberships) => {
        let date = "";
        memberships.forEach((membership) => {
            if (membership.start_date && !membership.end_date) {
                date = formatDate(membership.start_date);
            }
        });
        return date;
    };

    const formatDate = (dateString) => {
        var options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        let date = new Date(dateString);
        return date.toLocaleDateString("de-DE", options);
    };

    const getGrading = (gradings) => {
        if (gradings.length) {
            var max = gradings.reduce(function (prev, current) {
                return prev.belt_grade_order > current.belt_grade_order
                    ? prev
                    : current;
            });
            return max.grade;
        }
        return "";
    };

    const getGroupNameByID = (id) => {
        let group = props.trainingsgroups.find((group) => group.id === id);

        if (group) return group.name;
    };

    const memberList = () => {
        let array = members;

        if (showActiveOnly) {
            array = membersActive;
        }

        return array.map((member, index) => (
            <tr key={member.id}>
                <td>{index + 1}</td>
                <td>{member.lastname}</td>
                <td>{member.firstname}</td>
                {showColumns.membershipPrice && member.membership_price ? (
                    <td>{member.membership_price.price} €</td>
                ) : (
                    <td></td>
                )}
                {showColumns.membershipPrice && member.membership_price ? (
                    <td>{member.membership_price.name}</td>
                ) : (
                    <td></td>
                )}
                {showColumns.grading && <td>{getGrading(member.gradings)}</td>}
                <td>{formatDate(member.birthday)}</td>
                <td>{getGroupNameByID(member.trainingsgroup)}</td>
                <td>{member.passnumber}</td>
                <td>{memberSince(member.memberships)}</td>
                <td>
                    <Form.Check
                        id={"${member.id}-active"}
                        checked={member.isActive}
                        label=""
                        disabled
                    />
                </td>
                <td className="d-print-none">
                    <Button href={"/coach/mitglieder/" + member.slug} size="sm">
                        <MdEdit />
                    </Button>
                </td>
            </tr>
        ));
    };

    if (props.loggedIn) {
        let activeMembers = members.filter((member) => {
            return member.isActive;
        });

        return (
            <div class="mt-3 mt-md-5">
                <h1 className="mb-1">Mitglieder</h1>
                <p>{activeMembers.length} active members</p>

                <div className="mt-4 mb-5 d-print-none">
                    <Form>
                        <Form.Check
                            type="checkbox"
                            onChange={showActiveOnlyAction}
                            label="Nur Aktive"
                            checked={showActiveOnly}
                        />
                    </Form>
                </div>

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <Table responsive className=" text-nowrap">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                {showColumns.membershipPrice && (
                                    <th>Beitrag</th>
                                )}
                                {showColumns.membershipPrice && (
                                    <th>Tarifname</th>
                                )}
                                {showColumns.grading && <th>Graduierung</th>}
                                <th>Geburtstag</th>
                                <th>Gruppe</th>
                                <th>Passnummer</th>
                                <th>Mitglied seit</th>
                                <th>Aktiv</th>
                                <th className="d-print-none">Edit</th>
                            </tr>
                        </thead>
                        <tbody>{memberList()}</tbody>
                    </Table>
                )}

                <CoachMembersAdd
                    {...props}
                    setUpdateSwitch={setUpdateSwitch}
                    updateSwitch={updateSwitch}
                ></CoachMembersAdd>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachMembersWithCoachPanel = withCoachPanel(CoachMembers);

export default CoachMembersWithCoachPanel;
