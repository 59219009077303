import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import Figure from "./Figure";

import withPublicPanel from "../withPublicPanel";

import PandalMarieTheres from "../images/content/IMG_0800.jpg";
import DwitdollyoAndi from "../images/content/IMG_0559.jpg";
import PowerJuergen from "../images/content/IMG_0344.jpg";
import SpeedManfred from "../images/content/AJS_7622.jpg";
import SpeedJuergen from "../images/content/IMG_1335.jpg";

const Kyokpa = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center pb-5 pb-md-7">
                <Col lg="10" xl="8" xxl="6">
                    <Container>
                        <h1 class="page-header">Kyokpa</h1>
                        <div class="px-xl-6">
                            <p>
                                Der Bruchtest ist einer der bekanntesten
                                Elemente des traditionellen Taekwondo.
                                Bruchtests bei Kampfkunst-Vorführungen sind
                                äußerst spektakulär. Dies erweckt bei
                                Nichtfachkundigen jedoch den Eindruck, dass er
                                ein Hauptbestandteil des TKD ist. Dem ist
                                natürlich nicht so. Der Kyokpa ist nur ein Teil
                                der Kampfkunst Taekwondo und gehört in der Regel
                                nicht zum regelmässigen Trainingsinhalt. Ab
                                bestimmten Graduierungen ist er jedoch fixer
                                Bestandteil der Prüfung und dient dem Schüler
                                zum Überprüfen seiner Technik.
                            </p>
                            <p>
                                Wenn der Schüler noch nicht so weit ist, einen
                                Bruchtest auszuführen, so muss er mit Gewalt und
                                Verletzungen das ausgleichen, was ihm andere an
                                Technik und Können voraus haben.
                            </p>
                        </div>

                        <Row className="my-5 align-items-center ">
                            <Col md={6}>
                                <Figure
                                    alt="Speed Bruchtest von Andi"
                                    src={DwitdollyoAndi}
                                    figureClass="pe-md-5 pe-xl-7"
                                    caption="Speed Bruchtest von Andi"
                                />
                            </Col>
                            <Col md="6" className="">
                                <p>
                                    Körper und Geist müssen eine Einheit bilden
                                    und im Bruchteil einer Sekunde das gesamte
                                    Denken und Handeln in einem einzigen Punkt
                                    des Körpers konzentrieren. Als Material
                                    werden in der Regel Fichtenbretter mit ca.
                                    30 x 30 cm und einer Dicke von 1,5cm
                                    (Kinder), 3cm (Erwachsene) und 4cm (Meister)
                                    verwendet. Bei Vorführungen und
                                    Demonstrationen werden auch oft Dachziegel,
                                    Steine, Eisblöcke ... verwendet, frei nach
                                    dem Motto je größer, je dicker, je härter,
                                    desto spektakulärer. Man unterscheidet beim
                                    Kyokpa 3 verschiedene Arten: den
                                    Standardbruchtest, den Power-Bruchtest und
                                    den Geschwindigkeitsbruchtest.
                                </p>
                            </Col>
                        </Row>

                        <Row className="my-5 align-items-center ">
                            <Col md="6" className="pe-md-5 pe-xl-7">
                                <h2>Standardbruchtest</h2>
                                <p>
                                    Hierbei wird die Technik auf einem Brett mit
                                    einer der Graduierung entsprechenden Dicke
                                    ausgeführt, wobei dieses an mindestens zwei
                                    Seiten von einer oder mehreren Personen
                                    festgehalten wird. Die Art der Technik kann
                                    bei niederen Gürtelgraden frei gewählt
                                    werden, bei höheren Graduierungen wird sie
                                    vom Prüfer vorgegeben.
                                </p>
                            </Col>
                            <Col md={6}>
                                <Figure
                                    alt="Standardbruchtest Pandalchagi Marie-Theres"
                                    src={PandalMarieTheres}
                                    figureClass="pe-md-5 pe-xl-7"
                                    caption="Standardbruchtest Pandalchagi Marie-Theres"
                                />
                            </Col>
                        </Row>

                        <Row className="my-5 align-items-center ">
                            <Col md="6" className="">
                                <h2>Geschwindigkeits&shy;bruchtest</h2>
                                <p>
                                    Das Material wird nur mit einer Hand
                                    gehalten, einfach aufgestellt, frei in die
                                    Luft geworfen oder auf ein Tuch gelegt. Es
                                    gibt auch Varianten bei denen der Sportler
                                    selbst das Brett in die Luft wirft, hält,
                                    oder fallen lässt, wie im Bild nebenan.
                                    Durch die Trägheit der Masse kann es
                                    brechen, wenn die Belastung so plötzlich
                                    auftritt, dass es sich nicht schnell genug
                                    zurück bewegen kann. Bei einer langsamen
                                    Technik würde das Material zurückweichen und
                                    dadurch nicht zerbrechen. Es ist daher
                                    wichtig, den Körperteil (Hand, Fuß) mit dem
                                    man die Technik ausführt, stark zu
                                    beschleunigen um eine extrem hohe
                                    Geschwindigkeit zu erreichen. Dieser
                                    Bruchtest erfordert langjährige Erfahrung
                                    und Können.
                                </p>
                            </Col>
                            <Col md={6} className="order-lg-first">
                                <Figure
                                    alt="Speed Bruchtest von Manfred"
                                    src={SpeedManfred}
                                    figureClass="pe-md-5 pe-xl-7"
                                    caption="Speed Bruchtest von Manfred"
                                />
                            </Col>
                        </Row>

                        <Row className="my-5 align-items-center ">
                            <Col md="6" className="pe-md-5 pe-xl-7">
                                <h2>Powerbruchtest</h2>
                                <p>
                                    Beim Power-Bruchtest ist ein enormer
                                    Kraftaufwand erforderlich. Hierbei werden
                                    mehrere Bretter, Ziegel etc. aufeinander
                                    gelegt oder besonders dickes Material
                                    gewählt, das sicher und stabil aufgelegt
                                    oder gehalten werden muß. Bei dieser
                                    Bruchtestart ist die Geschwindigkeit der
                                    Technik nicht so relevant, sondern wichtig
                                    ist die Einbeziehung des ganzen Körpers.
                                    Auch Power-Bruchtests erfordern langjährige
                                    Erfahrung und ein hohes Maß an Können.
                                </p>
                            </Col>
                            <Col md={6}>
                                <Figure
                                    alt="Powerbruchtest von Jürgen"
                                    src={PowerJuergen}
                                    figureClass="pe-md-5 pe-xl-7"
                                    caption="Powerbruchtest von Jürgen"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + SpeedJuergen + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img src={SpeedJuergen} alt="Speed Bruchtest von Jürgen" />
            </Row>
        </>
    );
};

const KyokpaWithPublicPanel = withPublicPanel(Kyokpa);
export default KyokpaWithPublicPanel;
