import React from "react";

import { NavLink } from "react-router-dom";
import { Nav, Col } from "react-bootstrap";

import Logo from "../images/logo.svg";

const CoachNavigation = (props) => {
    var navLinks = [];

    const getNavItem = (name, link) => {
        return (
            <NavLink to={link} className="nav-item">
                {name}
            </NavLink>
        );
    };

    if (props.loggedIn) {
        navLinks.push(getNavItem("Mitglieder", "/coach/mitglieder"));
        navLinks.push(
            getNavItem("Anwesenheit eintragen", "/coach/trainingssessions")
        );
        navLinks.push(getNavItem("Prüfungen", "/coach/pruefungen"));
        navLinks.push(getNavItem("Meisterschaften", "/coach/meisterschaften"));
        navLinks.push(getNavItem("Poomsae-Teams", "/coach/teams"));
    }

    return (
        <Col xs={12} className="mt-3 mt-md-5">
            <Nav defaultActiveKey="/home" className="flex-column secondary-nav">
                <Nav.Item className="nav-header-1">Trainerbereich</Nav.Item>

                {navLinks}
            </Nav>
        </Col>
    );
};

export default CoachNavigation;
