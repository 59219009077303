import React from "react";

import { Row, Col } from "react-bootstrap";

import { MdChevronRight } from "react-icons/md";

const HomepageBanner = (props) => {
    return (
        <div class="vh-100-minus-navbar bg-blue-schraeg">
            <div class="container-fluid h-100 bg-fighters">
                <Row className="h-100 align-items-lg-end">
                    <Col
                        sm={7}
                        xl={5}
                        className="ms-xl-10 ms-xxl-20 text-white"
                    >
                        <p class="display-1 mt-6">
                            EAT <br />
                            SLEEP <br />
                            <span class="text-danger">TAEKWONDO</span> <br />
                            REPEAT.
                        </p>
                        <p class="font-weight-bold mb-5 mb-xl-7 mb-xxl-8 mt-3 mt-md-5">
                            <a href="/kontakt">
                                KOMM' ZUM PROBETRAINING{" "}
                                <span class="lead">
                                    <MdChevronRight />
                                </span>
                            </a>
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default HomepageBanner;
