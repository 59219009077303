import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import Figure from "./Figure";

import withPublicPanel from "../withPublicPanel";

import Subak from "../images/taekwondohistory/2_mustache_Kokuryeo_Korean_Subak_frontal_slap.jpg";

const TaekwondoHistory = (props) => {
    return (
        <Row className="bg-light justify-content-center pb-5 pb-md-7">
            <Col lg="10" xl="8" xxl="6">
                <Container>
                    <h1 class="page-header">Geschichte des Taekwondo</h1>

                    <div class="px-xl-6">
                        <p>
                            Bevor der Name "Korea" in unserem heutigen
                            Breitengrad bekannt wurde, gab es noch andere Namen
                            und Dynastien. Beginnen wir mit dem Ursprung{" "}
                            <b>"Choson"</b> (Land der Morgenfrische oder Land
                            der Morgenröte). Wir schreiben das Jahr 2333 vor
                            Christus. Zu dieser Zeit gab es den Halbgott{" "}
                            <b>Tangun</b>, von dem das koreanische Volk
                            abstammt. So besagt es zumindest die Legende von der
                            Gründung Koreas. Danach kam das Königreich{" "}
                            <b>Koguryo</b>, das vom König{" "}
                            <b>Tang-Myong-Son-Gwang</b> um <b>37 v. Ch.</b>{" "}
                            südlich und östlich der Manschurei gegründet wurde.
                            Hier wurde Tae Kwon Do in einer sogenannten Urform
                            als Kampfkunst ausgeübt - <b>Tae Kyon</b>.
                        </p>
                    </div>

                    <Row className="my-5 align-items-center ">
                        <Col md="6" className="pe-md-5 pe-xl-7">
                            <p>
                                Die Koreaner wurden früher oft vom
                                berühmt-berüchtigten Reitervolk der Mongolen
                                überfallen. Da sie sich nur schwer gegen diese
                                berittenen Armeen zur Wehr setzen konnten, gaben
                                sie den Fußtechniken den Vorzug (Tae Kyon). Mit
                                der Beherrschung der Fußtechniken war es
                                leichter die Reiter der Mongolen vom Pferd zu
                                holen und den Waffen der Gegner nicht zu nahe zu
                                kommen. Koguryo bestand bis in das Jahr 668 n.
                                Ch.
                            </p>
                        </Col>
                        <Col md={6} className="order-md-first">
                            <Figure
                                alt="Wandgemälde aus der Koguryo-Dynastie"
                                src={Subak}
                                figureClass="pe-md-5 pe-xl-7"
                                caption={
                                    <>
                                        <a href="https://commons.wikimedia.org/wiki/File:2_mustache_Kokuryeo_Korean_Subak_frontal_slap.jpg">
                                            Wandgemälde aus der
                                            Koguryo-Dynastie: Zwei Personen beim
                                            Soobakhi
                                        </a>
                                        ,{" "}
                                        <a href="https://creativecommons.org/licenses/by-sa/4.0">
                                            CC BY-SA 4.0
                                        </a>
                                        , via Wikimedia Commons
                                    </>
                                }
                            />
                        </Col>
                    </Row>

                    <div class="px-xl-6">
                        <p>
                            Dann kam die Dynastie <b>Silla,</b> die im Südosten
                            der Halbinsel lag und schon <b>20 Jahre</b> früher
                            entstand (<b>57 v. Ch. - 935 n. Ch.</b>). Silla war
                            das kleinste Königreich, aber das kämpferisch
                            stärkste Volk. Tae Kwon Do kannte man früher in der
                            Silla-Dynastie auch noch unter den Namen{" "}
                            <b>KwonBaek, Subyokta, Taekyon und Soobakgi. </b>
                            Silla war bekannt für seine heldenhaften Ritter, die{" "}
                            <b>Hwarangdo</b>
                            (Blumenritter). Die Hwarangdo Ritterschaft wurde vom{" "}
                            <b>24. Sillakönig Chin-Hung</b> ins Leben gerufen.
                            Silla wurde immer wieder vom viel größeren Volk
                            Koguryo im Norden angegriffen.
                        </p>
                        <p>
                            Die Hwarangdo Ritter waren zuerst eine Art
                            Jugendorganisation, entwickelten sich aber später zu
                            einer Ritterkaste (verglichen mit den japanischen
                            Samurai). Die wichtigsten sportlichen Aktivitäten
                            waren Bogenschießen, Jagen, Reiten, Tae Kwon Do und
                            Ringen. Koreanisches Ringen (Shireum) ist
                            vergleichbar mit dem japanischen Sumoringen. Es
                            wurde schon 3000 Jahre v. Ch. in Korea ausgeübt.
                        </p>

                        <p>
                            Das 3. Reich war <strong>Baekje</strong> und lag
                            südwestlich im heutigen Korea. Baekje auch genannt
                            der Hundertmannstamm. Es existierte 18 - 660 n. Ch.
                            bevor es von den Silla erobert wurde. Hier wurde
                            Taekwondo unter dem Namen Subakhi gelehrt, und als
                            Nationalsport für die Bevölkerung ausgerufen.
                        </p>

                        <h2>Zusammenschluss der Königreiche</h2>
                        <p>
                            Durch den Zusammenschluss der 3 Königreiche kam der
                            Name <b>Groß-Silla</b>. Danach wurde es umbenannt in{" "}
                            <b>Koryo</b>. Das Königreich Koryo{" "}
                            <b>(935 - 1392 n. Ch.)</b> dessen Name noch heute in
                            unserer Bezeichnung Korea enthalten ist, hatte noch
                            andere revolutionäre Erfindungen vorzuweisen. So zB.
                            sind sie die ersten die <b>1234 n. Ch</b>. schon{" "}
                            <b>Metalldruckbuchstaben</b> hatten (ca. 200 Jahre
                            vor Guttenberg). Sie waren und sind auch noch heute
                            berühmte <b>Keramikhersteller</b>, wie das bekannte{" "}
                            <b>Seladon</b>.
                        </p>
                        <p>
                            Für TKD begann jetzt das goldene Zeitalter.{" "}
                            <b>1790</b> in der <b>Yi-Dynastie</b> wurde ein Buch
                            herausgegeben, das verschiedene Kampfkünste
                            beschrieb. So auch Taekwondo. Nach und nach verlor
                            Taekwondo aber an Bedeutung und wurde vergessen. Bis
                            auf wenige alte Meister die es in kleinen Gruppen
                            weiterlehrten.
                        </p>

                        <h2>Taekwondo im 20. Jahrhundert</h2>
                        <p>
                            Bis 1950 war Taekwondo fast ausschließlich dem
                            Militär und einigen hohen Danträgern vorbehalten.{" "}
                            <b>Am 25. Juni 1950</b> begann der Koreakrieg und
                            dauerte 3 Jahre. Seither sind Nordkorea und Südkorea
                            am <b>38. Breitengrad</b> geteilt. 1953, am Ende des
                            Krieges, kam TKD über Amerika nach Europa, wo sich
                            das moderne TKD unter dem Namen <b>Tae Soo Do</b>{" "}
                            bzw. <b>Tang Soo Do</b> sehr rasch weiterentwickelte
                            und zu einem Selbstverteidigungs- und Wettkampfsport
                            wurde.
                        </p>
                        <p>
                            1955 wurde Tang Soo Do durch den General{" "}
                            <b>Choi Hong-Hi</b> in Tae Kwon Do umbenannt. Er
                            gründete in Kanada die ITF (International Taekwondo
                            Federation). 1965 kam Tae Kwon Do erstmals nach{" "}
                            <b>Österreich</b> (Innsbruck). 1973 wurde in
                            Seoul/Südkorea die"<b>World Taekwondo Federation</b>
                            " (WTF) mit ihrem Zentrum, dem <b>Kukkiwon</b>,
                            gegründet.
                        </p>
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

const TaekwondoHistoryWithPublicPanel = withPublicPanel(TaekwondoHistory);
export default TaekwondoHistoryWithPublicPanel;
