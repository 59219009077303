import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import CoachNavigation from "./components/CoachNavigation";
import MemberNavigation from "./components/MemberNavigation";
import Navigation from "./components/Navigation";

function withCoachPanel(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <>
                    <Container fluid className="bg-primary">
                        <Navigation
                            loggedIn={this.props.loggedIn}
                            authLink={this.props.authLink}
                            variant="dark"
                            bg="primary"
                        />
                    </Container>
                    <Container fluid>
                        <Row>
                            <Col
                                md={3}
                                xl={2}
                                className="d-print-none d-none d-md-block bg-gray-200"
                            >
                                <Row className="pb-6">
                                    <CoachNavigation {...this.props} />
                                    <MemberNavigation {...this.props} />
                                </Row>
                            </Col>
                            <Col>
                                <div class="ps-md-3">
                                    <WrappedComponent {...this.props} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }
    };
}

export default withCoachPanel;
