import React from "react";

import { Table, Form } from "react-bootstrap";

import apiClient from "../../services/api";

import GradingsHelper from "../helpers/GradingsHelper";

const CoachTrainingssessionMembers = (props) => {
    const [members, setMembers] = React.useState([]);
    React.useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/member")
                .then((response) => {
                    let activeMembers = response.data.filter((member) => {
                        return member.isActive;
                    });
                    activeMembers =
                        GradingsHelper.sortMembersByGrade(activeMembers);
                    setMembers(activeMembers);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, props.trainingssession]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const isPresent = (id) => {
        if (props.trainingssession) {
            let present = props.trainingssession.presentMembers.some(
                (member) => {
                    return member.member_id === id;
                }
            );
            return present;
        } else {
            return false;
        }
    };

    const handleChangeCheckboxMemberPresence = (event) => {
        var sessionMember = props.trainingssession.presentMembers.filter(
            (sessionMember) => {
                return sessionMember.member_id == event.target.id;
            }
        );
        let clickedMembers = members.filter((member) => {
            return member.id == event.target.id;
        });
        let name =
            clickedMembers[0].firstname + " " + clickedMembers[0].lastname;

        if (sessionMember.length) {
            apiClient
                .delete("/api/trainingssessionmember/" + sessionMember[0].id)
                .then((response) => {
                    console.log(response.data);
                    props.setUpdateSwitch(!props.updateSwitch);
                    props.setToastBody("Removed " + name);
                    props.setShowToast(true);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        } else if (event.target.checked == true) {
            //state AFTER clicking
            var data = {
                trainingssession_id: props.trainingssession.id,
                member_id: event.target.id,
            };
            apiClient
                .post("/api/trainingssessionmember", data)
                .then((response) => {
                    console.log(response.data);
                    props.setUpdateSwitch(!props.updateSwitch);
                    props.setToastBody("Added " + name);
                    props.setShowToast(true);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    };

    var memberList = members.map((member) => (
        <tr key={member.id}>
            <td>
                <Form.Group controlId={member.id}>
                    <Form.Check
                        type="checkbox"
                        label=""
                        checked={isPresent(member.id)}
                        onChange={handleChangeCheckboxMemberPresence}
                    />
                </Form.Group>
            </td>
            <td>{member.lastname}</td>
            <td>{member.firstname}</td>
            <td>{GradingsHelper.getMaxGrading(member.gradings).grade}</td>
            {console.log(GradingsHelper.getMaxGrading(member.gradings))}
        </tr>
    ));

    if (props.loggedIn) {
        return (
            <div className="mt-3 mt-md-5 mb-6">
                <Table responsive className="text-nowrap">
                    <thead>
                        <tr>
                            <th>Present</th>
                            <th>Lastname</th>
                            <th>Firstname</th>
                            <th>Graduierung</th>
                        </tr>
                    </thead>
                    <tbody>{memberList}</tbody>
                </Table>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachTrainingssessionMembers;
