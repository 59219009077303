import React from "react";

import { Row, Col, Container, Table } from "react-bootstrap";
import Figure from "./Figure";

import withPublicPanel from "../withPublicPanel";

import Mitgliederverlauf from "../images/vereinsgeschichte/mitgliederverlauf-2021.jpg";
import Gruppenfoto2002 from "../images/vereinsgeschichte/gruppenfoto-2002.jpg";
import Taepoong1 from "../images/vereinsgeschichte/taepoong-1.jpg";
import Taepoong2 from "../images/vereinsgeschichte/taepoong-2.jpg";
import Poom2005 from "../images/vereinsgeschichte/2005-poom.jpg";
import Dan2006 from "../images/vereinsgeschichte/2006-dan.jpg";
import Dan2007 from "../images/vereinsgeschichte/2007-dan.jpg";
import Stm2007 from "../images/vereinsgeschichte/2007-stm.jpg";
import Stm2009 from "../images/vereinsgeschichte/2009-stm.jpg";
import Dan2011 from "../images/vereinsgeschichte/2011-dan-manfred-raphi-anton.jpg";
import Bernd2013 from "../images/vereinsgeschichte/2013-dritter-dan-bernd.jpg";
import Anna2014 from "../images/vereinsgeschichte/2014-dan-anna.jpg";
import Hermann2016 from "../images/vereinsgeschichte/2016-weihnachten-ehrendan-hermann.jpg";
import LM2017 from "../images/vereinsgeschichte/2017-lm-vorau.jpg";
import Ehrenmitglieder2021 from "../images/vereinsgeschichte/2021-ehrenmitglieder.jpg";
import Vereinsmeisterschaft2021 from "../images/vereinsgeschichte/2021-vereinsmeisterschaft.jpg";

const History = (props) => {
    return (
        <Row className="bg-light">
            <Col>
                <Container>
                    <h1 class="page-header">Vereinschronik</h1>
                    <Row className="mb-5 mb-md-7">
                        <Col>
                            <img
                                src={Mitgliederverlauf}
                                class="img-fluid float-start mb-4"
                                alt="Mitgliederverlauf Kwonbaek Vorau"
                            />

                            <h2>Von der Gründung im Jahr 2000 bis heute</h2>

                            <p>
                                Am 21. Oktober 2000 fand auf Initiative von
                                Andreas Mosbacher im Turnsaal der HS Vorau eine
                                Taekwondo Vorführung des Vereins Taebaek
                                Dechantskirchen statt. Diese Vorführung war sehr
                                gut besucht, das Interesse war enorm und so
                                wurde bereits am 1. November 2000 mit der ersten
                                Trainingseinheit begonnen. Andreas Mosbacher aus
                                Vorau, der im August 2000 in Dechantskirchen die
                                Prüfung zum 1. DAN abgelegt hatte, leitete
                                dieses Training. Als Hilfstrainer fungierten
                                zwei höhergraduierte Taekwondo-Schüler, Martin
                                Mosbacher aus Vornholz und Bernd Kraussler aus
                                dem Schnellerviertel.
                            </p>

                            <p>
                                Am 23.3.2001 erfolgte die offizielle
                                Vereinsgründung mit{" "}
                                <strong>
                                    Obmann Andreas Mosbacher, Kassier Martin
                                    Mosbacher
                                </strong>{" "}
                                und{" "}
                                <strong>Schriftführer Bernd Kraussler</strong>.
                                Als Vereinsname wurde die Bezeichnung{" "}
                                <strong> „Kwon Baek Vorau“ </strong> gewählt.
                                „Kwon Baek“ ist ein Vorgänger des heutigen
                                Taekwondo aus dem Königreich Silla (Vorläufer
                                des heutigen Korea) 57 v. Chr.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-5 mb-md-7">
                        <Col>
                            <img
                                src={Gruppenfoto2002}
                                class="img-fluid float-end mb-4"
                                alt="Gruppenfoto aus dem Jahr 2002"
                            />

                            <p>
                                Im April 2001 legten erstmals 37
                                Taekwondo-Schüler des Vereins Kwon Baek Vorau
                                ihre erste Kup-Prüfung (Prüfung für den 10. und
                                9. Kup) erfolgreich ab.
                            </p>
                            <p>
                                Die Begeisterung der Kinder und Jugendlichen war
                                groß und somit auch die anfängliche Zahl der
                                Mitglieder. Inzwischen wurden für die
                                Taekwondo-Schüler bereits einige Kup - Prüfungen
                                durchgeführt. Seit 16. Februar 2002 wird auch
                                eine eigene Trainingseinheit für Erwachsene
                                durchgeführt.
                            </p>
                        </Col>
                    </Row>
                    {/* 2002 */}
                    <Row className="mb-4 mb-md-6 align-items-center">
                        <Col md={6}>
                            <Row>
                                <Col
                                    md="12"
                                    xl="6"
                                    className="d-none d-md-block mb-5 mb-xl-0"
                                >
                                    <img
                                        src={Taepoong1}
                                        class="img-fluid"
                                        alt="Das Taepoong Demonstrationsteam in Vorau."
                                    />
                                </Col>
                                <Col>
                                    <img
                                        src={Taepoong2}
                                        class="img-fluid mb-4 mb-md-0"
                                        alt="Das Taepoong Demoteam zu Gast bei Kwonbaek Vorau."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h3>2002</h3>
                            <p>
                                Im Februar 2002 stand die erste Teilnahme an
                                einem Kinderturnier in Wimpassing auf dem
                                Programm. Die Wettkämpfer konnten 3 x Gold, 4 x
                                Silber und eine Bronzemedaille erkämpfen. Ebenso
                                begann im Februar 2002 ein eigener Kurs für
                                Erwachsene mit ca. 20 Teilnehmern.
                            </p>
                            <p>
                                Im Mai 2002 legten Martin Mosbacher und Bernd
                                Kraussler im Rahmen ihrer Trainerausbildung die
                                Prüfung für den Übungsleiter ab.
                            </p>
                            <p>
                                Der Höhepunkt dieses Jahres fand am 14.
                                September 2002 auf dem Sportplatz des TUS-Vorau
                                statt. Der Verein holte das weltbeste
                                Vorführteam, das{" "}
                                <strong>Taepoong Taekwondo Demoteam</strong>,
                                nach Vorau, um den Bekanntheitsgrad von
                                Taekwondo in der heimischen Bevölkerung zu
                                steigern. Dieses Team zeigte Taekwondo in
                                Vollendung.
                            </p>
                        </Col>
                    </Row>
                    {/* 2003 */}
                    <Row className="mb-5 mb-md-6">
                        <Col md={6} xl={5} className="mb-4 mb-md-0">
                            <h3>2003</h3>
                            <p>
                                Im März 2003 legte Bernd Kraussler die Prüfung
                                zum ersten Meistergrad (1. Dan) ab. Ebenso im
                                März veranstaltete unser Verein das erste
                                steirische Kinderturnier, den „Young Tigers
                                Cup“, bei dem KwonBaek Vorau sogar die
                                Vereinswertung gewann.
                            </p>
                            <p>
                                Am 2. August bestand Martin Mosbacher die
                                Prüfung für den 1. Dan und wurde somit zum
                                Meister. Der Dezember 2003 war für unseren
                                Trainer Andreas Mosbacher ein Höhepunkt: Er
                                legte die Prüfung zum 3. Meistergrad (3. Dan)
                                ab.
                            </p>
                        </Col>

                        <Col md={6} xl={5} className="offset-xl-1">
                            <h3>2004</h3>
                            <p>
                                Der 2. Young Tigers Cup im April 2004 in Vorau
                                wurde zu einem Großereignis. 170 Starter aus
                                Österreich, Slowenien, Tschechien und Kroatien
                                maßen ihr Können in sportlich fairen
                                Wettkämpfen.
                            </p>
                            <p>
                                Bernd Kraussler legte im August seine Funktion
                                als Schriftführer zurück, ihm folgte Ing. Franz
                                Frauenthaler.
                            </p>
                        </Col>
                    </Row>
                    {/* 2005 */}
                    <Row className="mb-4 mb-md-6 align-items-center">
                        <Col className="pe-lg-5 pe-xl-7">
                            <h3>2005</h3>

                            <p>
                                Im Frühjahr 2005 musste Martin Mosbacher aus
                                beruflichen Gründen die Funktion des Kassiers
                                zur Verfügung stellen. Als neuer Kassier wurde
                                Otmar Kernbauer gewählt.
                            </p>
                            <p>
                                Von den ursprünglich 37 Taekwondo-Schülern, die
                                im Jahr 2001 ihre erste Kup-Prüfung
                                absolvierten, erreichten nach 5 Jahren Training
                                erstmals zwei Schüler im August 2005 den 1.
                                Meistergrad . Es waren dies die Brüder Christoph
                                und Philipp Frauenthaler. Als Prüfer fungierten
                                Eduard Marliot und Raimund Vaseghi.
                            </p>
                        </Col>
                        <Col md={6} className="order-md-first">
                            <Figure
                                figureClass="pe-md-5 pe-xl-7"
                                alt="Christoph und Philipp mit ihren Prüfern und Trainer Andreas Mosbacher"
                                src={Poom2005}
                                caption="Christoph und Philipp mit ihren Prüfern Raimund Vaseghi und Edi Marliot sowie Trainer Andreas Mosbacher"
                            />
                        </Col>
                    </Row>
                    {/* 2006 */}
                    <Row className="mb-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2006</h3>

                            <p>
                                Am 5. August 2006 erreichten drei weitere
                                Schüler, unter den strengen Augen der Prüfer
                                Eduard Marliot und Raimund Vaseghi, den
                                Meistergrad. Es waren dies Martin Ehrenhöfer und
                                die beiden Schwestern Melanie und Daniela
                                Maierhofer. Erstmals in der Vereinsgeschichte
                                erreichten dieses Ziel nach jahrelangen hartem
                                Training auch 2 weibliche Mitglieder unseres
                                Vereines.
                            </p>
                            <p>
                                Am 16. Dezember 2006 legte auch Andreas
                                Mosbacher die Prüfung für den 4. Dan erfolgreich
                                ab.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Figure
                                figureClass="ps-md-5 ps-xl-7"
                                captionClass="text-end"
                                alt="Martin, Melanie und Daniela mit ihren Prüfern und Trainer Andreas Mosbacher"
                                src={Dan2006}
                                caption="Martin, Melanie und Daniela mit ihren Prüfern sowie Trainer Andreas Mosbacher"
                            />
                        </Col>
                    </Row>
                    {/* 2007 */}
                    <Row className="mb-5 align-items-center">
                        <Col md={9} xl={7}>
                            <h3>2007</h3>
                            <p>
                                Im Jänner 2007 legte{" "}
                                <strong>Andreas Mosbacher</strong> aus
                                persönlichen Gründen seine Funktion als Obmann
                                und Trainer des Vereines zurück. In einer
                                außerordentlichen Mitgliederversamlung wurde{" "}
                                <strong>Martin Mosbacher</strong> (bisher
                                Obmannstellvertreter) zum neuen Obmann von
                                KwonBaek Vorau gewählt. Die freigewordene
                                Funktion des Obmann-Stellvertreters übernahm ab
                                diesem Zeitpunkt{" "}
                                <strong>Hermann Steinhöfler</strong>. Das
                                Training übernahmen die vorhandenen DAN-Träger
                                und höher graduierte Schüler.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-5 align-items-center">
                        <Col md={6} className="mb-3 mb-md-0">
                            <Figure
                                alt="Markus, Gerlinde, Philipp und Franz mit ihren Prüfern"
                                src={Dan2007}
                                figureClass="pe-md-5 pe-xl-7"
                                caption="Markus, Gerlinde, Philipp und Franz mit ihren Prüfern"
                            />
                        </Col>
                        <Col className="pe-xl-7">
                            <p>
                                Im Februar 2007 bestanden 7 Mitglieder unseres
                                Vereines die Abschlussprüfung des
                                Übungsleiterlehrganges, dies ist die Ausbildung
                                zur Trainerlizenz D. Es waren dies{" "}
                                <strong>
                                    Otmar Kernbauer, Michael Haspl, Melanie
                                    Maierhofer, Franz Frauenthaler, Daniela
                                    Maierhofer, Martin Ehrenhöfer und Philipp
                                    Frauenthaler
                                </strong>
                                .
                            </p>
                            <p>
                                Am 18. August 2007 legten{" "}
                                <strong>Gerlinde Doppler</strong> und{" "}
                                <strong>Franz Frauenthaler</strong> die Prüfung
                                für den 1.DAN erfolgreich ab.{" "}
                                <strong>Markus Kernbauer</strong> erreichte den
                                1. Poom. Er ist somit der jüngste Poom-Träger
                                des Vereines.{" "}
                                <strong>Philipp Frauenthaler</strong> legte die
                                Prüfung für den 2. DAN erfolgreich ab.
                            </p>
                            <p>
                                Als Prüfer fungierte das bereits bewährte Team
                                Eduard Marliot und Raimund Vaseghi.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h4>Der erste Österreichische Meistertitel</h4>
                            <p>
                                Am 13. Oktober 2007 fanden in Stockerau die
                                österreichischen Staatsmeisterschaften statt.{" "}
                                <strong>Markus Kernbauer</strong> holte mit
                                einer souveränen Leistung "Gold" und wurde somit
                                der erste{" "}
                                <strong>Österreichische Meister</strong> in der
                                Vereinsgeschichte.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Figure
                                alt="Markus Kernbauer holte den 1. Österreichischen Meistertitel für Kwonbaek Vorau"
                                src={Stm2007}
                                figureClass="ps-md-5 ps-xl-7"
                                captionClass="text-end"
                                caption="Markus Kernbauer holte den 1. Österreichischen Meistertitel für Kwonbaek Vorau"
                            />
                        </Col>
                    </Row>
                    {/* 2008 */}
                    <Row className="mb-5 mb-md-6  align-items-center">
                        <Col md={9} xl={7}>
                            <h3>2008</h3>
                            <p>
                                Im März 2008 legten{" "}
                                <strong>Martin Mosbacher</strong> aus
                                beruflichen und <strong>Hannes Zingl</strong>{" "}
                                aus privaten, familiären Gründen ihre Funktionen
                                als Obmann bzw. Schriftführer-Stellvertreter
                                zurück. Als neuer Obmann wurde{" "}
                                <strong>Ing. Hermann Steinhöfler</strong> und
                                als Schriftführer-Stellvertreter{" "}
                                <strong>Philipp Frauenthaler</strong> bestellt.
                                Die freigewordene Funktion des
                                Obmann-Stellvertreters übernahm{" "}
                                <strong>Bernd Kraussler</strong>.
                            </p>
                            <p>
                                In diesem Jahre legten 2 weitere Schüler den Weg
                                zum Meistergrad erfolgreich zurück. Am 27.
                                September legte <strong>Michael Haspl</strong>{" "}
                                und am 20. Dezember{" "}
                                <strong>Otmar Kernbauer</strong> die Prüfung für
                                den 1.Dan erfolgreich ab. Ebenfalls am 20.
                                Dezember erreichte{" "}
                                <strong>Bernd Kraussler</strong> den 2.Dan.
                            </p>
                        </Col>
                    </Row>
                    {/* 2009 */}
                    <Row className="mb-4 mb-md-6 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2009</h3>
                            <p>
                                Im Frühjahr 2009 wechselte der bisherige Kassier
                                Otmar Kernbauer zum Verein Taebaek
                                Dechantskirchen. Die dadurch freigewordene
                                Funktion des Kassiers übernahm{" "}
                                <strong>Herbert Hirschhofer</strong>. Als
                                Kassier-Stellvertreter wurde{" "}
                                <strong>Elke Haspl-Maderbacher</strong>{" "}
                                bestellt.
                            </p>

                            <h4 class="mt-4 mt-md-5">
                                Weitere Österreichische Meistertitel für
                                Kwonbaek
                            </h4>
                            <p>
                                Am 31. Oktober 2009 nahm unser Verein an der{" "}
                                <strong>
                                    Poomsae Staatsmeisterschaft in Nenzing
                                    (Voralberg)
                                </strong>{" "}
                                teil. <strong>Philipp Frauenthaler</strong>{" "}
                                holte Gold und wurde{" "}
                                <strong>Österreichischer Meister</strong> der
                                Junioren in der Leistungsklasse 1.{" "}
                                <strong>Herbert Hirschhofer</strong> wurde{" "}
                                <strong>
                                    Österreichischer Nachwuchsmeister
                                </strong>{" "}
                                in seiner Klasse. Diesen großartigen Erfolg
                                rundete <strong>Elke Haspl-Maderbacher</strong>{" "}
                                mit einer Silber-Medaille ab.
                            </p>
                        </Col>

                        <Col md={6} className="order-md-first">
                            <Figure
                                alt="Philipp, Elke und Herbert bei der Österreichischen Staatsmeisterschaft 2009"
                                src={Stm2009}
                                figureClass="pe-md-5 pe-xl-7"
                                caption="Philipp, Elke und Herbert bei der Österreichischen Staatsmeisterschaft 2009"
                            />
                        </Col>
                    </Row>
                    {/* 2010-2012 */}
                    <Row className="mb-5 mb-md-7 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2010</h3>
                            <p>
                                Nach einer umfassenden Ausbildung, seit Herbst
                                2009, hat <strong>Bernd Kraussler</strong> am 3.
                                März 2010 die staatliche Prüfung für den{" "}
                                <strong>
                                    Taekwondo-Lehrwart (Trainerlizenz C)
                                </strong>{" "}
                                mit Auszeichnung bestanden.
                            </p>
                            <h3>2011</h3>
                            <p>
                                Im Dezember 2011 erreichten gleich 3 weitere
                                Schüler den 1. Dan. Dies waren{" "}
                                <strong>
                                    Manfred Milchrahm, Raphael Steinhöfler{" "}
                                </strong>
                                und<strong> Anton Storer</strong>.
                            </p>
                            <h3>2012</h3>
                            <p>
                                <strong>Franz Frauenthaler</strong> absolvierte
                                am 15. Dezember 2012 erfolgreich die Prüfung zum
                                2. Dan.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Figure
                                alt="Die 3 neuen Meister Manfred, Raphael und Anton"
                                src={Dan2011}
                                figureClass="ps-md-5 ps-xl-7"
                                captionClass="text-end"
                                caption="Die 3 neuen Meister Manfred Milchrahm, Raphael Steinhöfler und Anton Storer"
                            />
                        </Col>
                    </Row>
                    {/* 2013 */}
                    <Row className="mb-5 mb-md-7">
                        <Col md="6" className="pe-md-5 pe-lg-7 pe-xl-9">
                            <h3>2013</h3>
                            <p>
                                Am 14. Dezember 2013 legte{" "}
                                <strong>Bernd Kraussler</strong> die Prüfung zum
                                3. Dan ab. <strong>Manfred Milchrahm</strong>{" "}
                                erreichte den 2. Meistergrad. Mit{" "}
                                <strong>Herbert Hirschhofer </strong>und
                                <strong> Martin Riebenbauer</strong> erhält der
                                Verein zwei weitere Meister, diese legten die
                                Prüfung zum 1. Dan ab.
                            </p>
                            <Figure
                                alt="Bruchtest von Bernd bei seiner Prüfung zum 3. Dan"
                                src={Bernd2013}
                                figureClass="mb-5 mb-md-0"
                                caption="Bernd Kraussler bei seinem selbstgehaltenen freihängenden Bruchtest"
                            />
                        </Col>
                        <Col
                            md="6"
                            className="pe-md-5 pe-lg-7 pe-xl-9 d-flex flex-column"
                        >
                            <h3>2014</h3>
                            <p>
                                Im Dezember 2014 erreichte{" "}
                                <strong>Anna Sara Haspl</strong> den 1. Dan.
                            </p>

                            <Figure
                                alt="Anna Sara mit Prüfer Edi Marliot und Trainer Martin Riebenbauer"
                                src={Anna2014}
                                figureClass="mt-auto mb-md-0"
                                captionClass="text-end"
                                caption="Anna Sara mit Prüfer Edi Marliot und Trainer Martin Riebenbauer"
                            />
                        </Col>
                    </Row>
                    {/* 2015 */}
                    <Row className="mb-5 mb-md-7">
                        <Col xs="12">
                            <h3>2015</h3>
                            <p>
                                Im Dezember 2015 legten
                                <strong> Herbert Hirschhofer </strong>und
                                <strong> Martin Riebenbauer</strong> erfolgreich
                                die Prüfung zum 2. Dan ab.
                            </p>
                        </Col>
                    </Row>
                    {/* 2016 */}
                    <Row className="mb-4 mb-md-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2016</h3>
                            <p>
                                Im Juli 2016 legte mit
                                <strong> Elke Haspl-Maderbacher</strong> ein
                                weiteres unserer Mitglieder erfolgreich die
                                Prüfung zum 1. Meistergrad ab.
                                <strong> Franz Frauenthaler</strong> absolvierte
                                erfolgreich die Prüfung zum 3. Dan und gehört
                                somit zu den höchstgraduiertesten Sportlern
                                unseres Vereins.
                            </p>
                            <p>
                                Bei unserer Weihnachtsfeier im Dezember 2016
                                wurde unserem langjährigen{" "}
                                <strong>Obmann Hermann Steinhöfler</strong>{" "}
                                aufgrund besonderer Verdienste ehrenhalber der
                                1. Dan verliehen.
                            </p>
                        </Col>
                        <Col md={6} className="order-md-first">
                            <Figure
                                alt="Eduard Marliot überreicht unserem Obmann Hermann ehrenhalber den 1. Dan"
                                src={Hermann2016}
                                figureClass="pe-md-5 pe-xl-7"
                                caption="Eduard Marliot und der Vorstand von Kwonbaek Vorau überreichen Obmann Hermann ehrenhalber den 1. Dan"
                            />
                        </Col>
                    </Row>
                    {/* 2017*/}
                    <Row className="mb-4 mb-md-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2017</h3>
                            <p>
                                Am 4. November 2017 richtete Kwonbaek Vorau die
                                Steirische Taekwondo Landesmeisterschaft in
                                Vorau aus. Mit einem starken Poomsaeteam
                                erreichte Kwonbaek Vorau den{" "}
                                <strong>
                                    1. Platz in der Poomsae Vereinswertung
                                </strong>{" "}
                                sowie den 3. Platz in der Vereinswertung Gesamt.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Figure
                                alt="Das Wettkampfteam von Kwonbaek Vorau mit Obmann Hermann Steinhöfler bei der Landesmeisterschaft 2017"
                                src={LM2017}
                                figureClass="ps-md-5 ps-xl-7"
                                captionClass="text-md-end"
                                caption="Das Wettkampfteam von Kwonbaek Vorau mit Obmann Hermann Steinhöfler bei der Landesmeisterschaft 2017"
                            />
                        </Col>
                    </Row>

                    {/* 2018*/}
                    <Row className="mb-5 mb-md-6">
                        <Col xs="12" md="9" xl="7">
                            <h3>2018</h3>
                            <p>
                                Der bisherige Schriftführer Stellvertreter{" "}
                                <strong>Philipp Frauenthaler</strong> legte
                                seine Funktion zurück. Für die frei gewordene
                                Funktion wurde durch die Mitgliederversammlung{" "}
                                <strong>Manfred Milchrahm</strong> gewählt.{" "}
                            </p>
                        </Col>
                    </Row>

                    {/* 2021*/}
                    <Row className="mb-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <h3>2021</h3>
                            <p>
                                Die langjährigen Vorstandsmitglieder{" "}
                                <strong>
                                    Hermann Steinhöfler, Franz Frauenthaler,
                                    Herbert Hirschhofer und Elke Maderbacher
                                </strong>{" "}
                                legten ihre jeweiligen Funktionen im April 2021
                                zurück. Als neuer Obmann wurde der bisherige
                                Obmann Stellvertreter{" "}
                                <strong>Bernd Kraussler</strong> gewählt. Der
                                bisherige Schriftführer Stellvertreter{" "}
                                <strong>Manfred Milchrahm</strong> wurde als
                                neuer Schriftführer gewählt. Der restliche
                                Vorstand wurde wie folgt gewählt:{" "}
                            </p>
                            <Table responsive className="mb-5">
                                <tbody>
                                    <tr>
                                        <td>Kassier</td>
                                        <td>Werner Gugatschka</td>
                                    </tr>
                                    <tr>
                                        <td>Kassier Stv.</td>
                                        <td>Christian Koller</td>
                                    </tr>
                                    <tr>
                                        <td>Obmann Stv.</td>
                                        <td>Stefanie Haider</td>
                                    </tr>
                                    <tr>
                                        <td>Schriftführer Stv.</td>
                                        <td>Robert Schlögl</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <p>
                                Den ehemaligen Vorstandsmitgliedern{" "}
                                <strong>
                                    Hermann Steinhöfler, Franz Frauenthaler und
                                    Herbert Hirschhofer
                                </strong>{" "}
                                wurde aufgrund ihrer langen besonderen
                                Verdienste für den Verein durch eine
                                außerordentliche Mitgliederversammlung am 17.
                                Juli 2021 die Ehrenmitgliedschaft verliehen.
                            </p>
                        </Col>
                        <Col md={6} className="order-md-first">
                            <Figure
                                alt="Die neuen Ehrenmitglieder und der Vorstand von Kwonbaek Vorau"
                                src={Ehrenmitglieder2021}
                                figureClass="pe-md-5 pe-xl-7"
                                caption="Die neuen Ehrenmitglieder und der Vorstand von Kwonbaek Vorau"
                            />
                        </Col>
                    </Row>

                    <Row className="mb-5 align-items-center">
                        <Col className="pe-md-5 pe-xl-7">
                            <p>
                                2021 war ein weiteres Trainingsjahr in dem uns
                                die COVID-Pandemie beschäftigte und im Training
                                etwas einschränkte. Nichts destotrotz konnten
                                wir im Juni endlich wieder Kup-Prüfungen
                                abhalten.
                            </p>
                            <p>
                                Die am 13. November 2021 geplante Steirische
                                Landesmeisterschaft wurde leider kurzfristig
                                aufgrund der Corona-Vorgaben abgesagt. Wir
                                ließen uns jedoch die Motivation nicht nehmen
                                und veranstalteten stattdessen die 1. Kwonbaek
                                Vereinsmeisterschaft. 15 Mitglieder konnten sich
                                somit in Poomsae- und Speedbreakbewerben messen.
                                Als Punkterichter fungierten die langjährigen
                                Trainer und Dan-Träger des Vereins Bernd
                                Kraussler, Franz Frauenthaler, Philipp
                                Frauenthaler sowie Manfred Milchrahm.
                            </p>
                        </Col>
                        <Col md={6} className="">
                            <Figure
                                alt="Wettkämpfer, Trainer und Punkterichter bei der 1. Kwonbaek Vereinsmeisterschaft"
                                src={Vereinsmeisterschaft2021}
                                figureClass="pe-md-5 pe-xl-7"
                                caption="Wettkämpfer, Trainer und Punkterichter bei der 1. Kwonbaek Vereinsmeisterschaft"
                            />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};

const HistoryWithPublicPanel = withPublicPanel(History);
export default HistoryWithPublicPanel;
