import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import Juergen from "../images/content/20180721-kuppruefung-31.jpg";

const Dojang = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5 mb-md-6">
                        <h1 class="page-header">Der Dojang</h1>
                        <p>
                            Der Dojang (Übungsraum) ist für den Sportler ein Ort
                            der inneren Sammlung. Die Grundsätze oder inneren
                            Werte des Taekwondo "Höflichkeit, Toleranz,
                            Integrität, Selbstdisziplin" sollten nicht nur im
                            Übungsraum gelebt werden, sondern uns in allen
                            Lebenslagen beeinflussen.
                        </p>

                        <h2 class="h4">Betreten des Dojang</h2>
                        <p>
                            Halsketten, Ringe, Uhren, etc. sind vor dem Betreten
                            des Übungsraumes zu entfernen, denn bei diversen
                            Partnerübungen kann es durch etwaige Schmuckstücke,
                            Uhren etc. zu ernsthaften Verletzungen kommen.
                        </p>
                        <p>
                            Beim Betreten sollte jeder den koreanischen Gruß
                            (Kyongle) als Respektsbezeugung gegenüber seinem
                            Trainer, seinen Trainigskollegen und der Sportart,
                            die es zu erlernen gilt, durchführen. Diese
                            Verbeugung dient auch dazu den Dojang nicht mit den
                            Gedanken und Ereignissen, die uns vielleicht gerade
                            noch beschäftigt haben, zu belasten. Kyongle besteht
                            aus zwei Wörtern:
                        </p>
                        <ul>
                            <li>
                                Kyong: Achtung, Respekt (vor dem Partner, der
                                Sportart, dem Trainer)
                            </li>
                            <li>Le: Höflichkeit, Etikette</li>
                        </ul>

                        <h2 class="h4">Verhalten im Dojang</h2>
                        <p>
                            Sollte das Training noch nicht begonnen haben, so
                            kann durch leichtes Aufwärmen die Zeit zum
                            Trainingsbeginn überbrückt werden.
                        </p>
                        <p>
                            Wie eingangs erwähnt, ist der Dojang ein Ort der
                            inneren Sammlung, man sollte sich daher auch mental
                            auf das bevorstehende Training vorbereiten. Für
                            Außenstehende und Neulinge mag der Anschein
                            entstehen, daß im Übungsraum (bedingt durch eine
                            Vielzahl an Kommandos) militärischer Drill
                            vorherrscht. Diese Kommandos dienen zur
                            Aufrechterhaltung der Disziplin und diese ist beim
                            TKD-Training aus den verschiedensten Gründen
                            unerlässlich:
                        </p>

                        <ul>
                            <li>Sicherstellung des Trainingserfolges</li>
                            <li>
                                Heranführung der Schüler von der Disziplin zur
                                Selbstdisziplin
                            </li>
                            <li>
                                Vermeidung von Verletzungen durch einen
                                unkontrollierten Übungsablauf
                            </li>
                            <li>Zur Förderung der Konzentration</li>
                        </ul>

                        <p>
                            Ein Verlassen des Dojangs während des Trainings
                            sollte - wenn möglich - vermieden werden. Besteht
                            trotzdem die Notwendigkeit (zB. WC aufsuchen), so
                            hat sich der Schüler beim Trainer abzumelden und
                            beim Verlassen des Übungsraumes ebenso zu verbeugen.
                            Trinkpausen sind notwendig und möglich, jedoch
                            werden sie in der Regel vom Trainer vorgegeben.
                        </p>

                        <h2 class="h4">
                            Zuschauer, Angehörige, Freunde von
                            Taekwondo-Schülern
                        </h2>
                        <p>
                            Es ist für Interessierte jederzeit möglich bei einem
                            Training zuzusehen, jedoch sollte auch hier ein
                            gewisses Benehmen im Vordergrund stehen. Nichts
                            stört die Konzentration der TKD-Schüler mehr, als
                            klingelnde Handys, Diskussionen und Tratsch unter
                            den Zusehern, dazu ist nach Trainingsende noch
                            genügend Zeit.
                        </p>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + Juergen + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={Juergen}
                    alt="Jürgen beim Bruchtest mit Pandalchagi"
                />
            </Row>
        </>
    );
};

const DojangWithPublicPanel = withPublicPanel(Dojang);
export default DojangWithPublicPanel;
