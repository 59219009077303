import React from "react";

class MemberHelper extends React.Component {
    static formatDate = (dateString) => {
        var options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        if (dateString) {
            let date = new Date(dateString);
            return date.toLocaleDateString("de-DE", options);
        }
    };

    static memberSince = (memberships, sqlFormat = false) => {
        let date = "";
        memberships.forEach((membership) => {
            if (membership.start_date && !membership.end_date) {
                if (sqlFormat) date = membership.start_date;
                else date = this.formatDate(membership.start_date);
            }
        });
        return date;
    };
}

export default MemberHelper;
