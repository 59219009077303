import React from "react";

import { Container, Table } from "react-bootstrap";

const MemberAchievements = (props) => {
    const singlePodiumPlacesWithOpponent = props.achievements.single.filter(
        (achievement) => {
            return (
                achievement.result <= 3 &&
                ((achievement.num_opponents !== null &&
                    achievement.result <= achievement.num_opponents) ||
                    achievement.num_opponents == null)
            );
        }
    );

    const singleAchievementsList = singlePodiumPlacesWithOpponent.map(
        (result) => {
            return (
                <tr>
                    <td>{result.result}. Platz</td>
                    <td>{result.competition}</td>
                    <td>{result.class}</td>
                </tr>
            );
        }
    );

    const teamPodiumPlacesWithOpponent = props.achievements.team.filter(
        (achievement) => {
            return (
                achievement.result <= 3 &&
                ((achievement.num_opponents !== null &&
                    achievement.result <= achievement.num_opponents) ||
                    achievement.num_opponents == null)
            );
        }
    );

    const teamAchievementsList = teamPodiumPlacesWithOpponent.map((result) => {
        return (
            <tr>
                <td>{result.result}. Platz</td>
                <td>{result.competition}</td>
                <td>{result.class}</td>
                <td>
                    mit{" "}
                    {result.members[0].firstname +
                        " " +
                        result.members[0].lastname}
                    {result.members.length > 1
                        ? " und " +
                          result.members[1].firstname +
                          " " +
                          result.members[1].lastname
                        : ""}
                </td>
            </tr>
        );
    });

    return (
        <Container>
            {singleAchievementsList.length || teamAchievementsList.length ? (
                <>
                    <h2>Erfolge *</h2>
                    <Table
                        responsive
                        striped
                        hover
                        className="mb-4 text-nowrap"
                    >
                        <thead>
                            <tr>
                                <th>Platz</th>
                                <th>Meisterschaft</th>
                                <th>Kategorie</th>
                            </tr>
                        </thead>
                        <tbody>{singleAchievementsList}</tbody>
                    </Table>

                    {teamAchievementsList.length ? (
                        <>
                            <h3 class="h5">Im Team</h3>

                            <Table
                                responsive
                                striped
                                hover
                                className="text-nowrap"
                            >
                                <thead>
                                    <tr>
                                        <th>Platz</th>
                                        <th>Meisterschaft</th>
                                        <th>Kategorie</th>
                                        <th>Team</th>
                                    </tr>
                                </thead>
                                <tbody>{teamAchievementsList}</tbody>
                            </Table>
                        </>
                    ) : (
                        ""
                    )}

                    <div class="mt-4 fst-italic">
                        <small>*nur erkämpfte Medaillenplätze</small>
                    </div>
                </>
            ) : (
                ""
            )}
        </Container>
    );
};
export default MemberAchievements;
