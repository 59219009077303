import React from "react";
import apiClient from "../../services/api";
import { Line } from "react-chartjs-2";
import TimeHelper from "../helpers/TimeHelper";

const TrainingssessionMemberOverTime = (props) => {
    const [trainingssessions, setTrainingssessions] = React.useState([]);

    React.useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/trainingssession")
                .then((response) => {
                    let sessions = response.data;
                    sessions.sort(function (a, b) {
                        var dateA = new Date(a.date);
                        var dateB = new Date(b.date);
                        if (dateA < dateB) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    setTrainingssessions(sessions);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn]);

    const values = trainingssessions.map((session) => {
        return session.presentMembersCount;
    });

    const avg = values.reduce((a, b) => a + b, 0) / values.length || 0;

    const data = {
        labels: trainingssessions.map((session) => {
            return TimeHelper.formatDate(session.date, {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
            });
        }),
        datasets: [
            {
                label: "Mitglieder pro Trainingseinheit",
                data: values,
                fill: false,
                backgroundColor: "rgb(0, 149, 183)",
                borderColor: "rgba(0, 149, 183, 0.3)",
                tension: 0.1,
            },
            {
                label: "Durchschnitt",
                data: values.map((x) => {
                    return Math.round(avg);
                }),
                backgroundColor: "rgba(108, 117, 125, 0)",
                pointBorderColor: "rgba(108, 117, 125, 0)",
                pointBackgroundColor: "rgba(108, 117, 125, 0)",
                borderColor: "rgba(108, 117, 125, 0.4)",
                pointStyle: "line",
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        responsive: true,
        tooltips: {
            filter: function (tooltipItem) {
                return tooltipItem.datasetIndex === 0;
            },
        },
    };

    return (
        <>
            <div className="d-none d-lg-block">
                <Line data={data} options={options} height={50} />
            </div>
            <div className="d-lg-none">
                <Line data={data} options={options} />
            </div>
        </>
    );
};

export default TrainingssessionMemberOverTime;
