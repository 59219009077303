import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import TLGirls from "../images/content/2021-trainingslager-luise-hanna-isa.jpg";
import Robert from "../images/content/2021-trainingslager-robert.jpg";
import Juergen from "../images/content/20180721-kuppruefung-31.jpg";

const Taekwondo = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5">
                        <h1 class="page-header">Was ist Taekwondo?</h1>
                        <p>
                            Taekwondo (koreanisch 태권도, kurz TKD) ist eine
                            koreanische Kampfkunst. Die drei Silben des Namens
                            stehen für Fußtechnik (tae), Handtechnik (kwon) und
                            Weg (do). Taekwondo ist sehr auf Schnelligkeit und
                            Dynamik ausgelegt, was nicht zuletzt durch den
                            Wettkampf bedingt ist. Im Taekwondo dominieren
                            Fußtechniken deutlicher als in vergleichbaren
                            Kampfsportarten.
                        </p>
                        <p>
                            Einen Überblick gibt es auch auf{" "}
                            <a
                                href="https://de.wikipedia.org/wiki/Taekwondo"
                                target="_blank"
                            >
                                Wikipedia
                            </a>
                        </p>

                        <div class="ratio ratio-16x9 mt-4 mb-5">
                            <iframe
                                src="https://www.youtube.com/embed/HkAmVkESI1E"
                                title="Taekwondo Promo Video"
                                allowfullscreen
                            ></iframe>
                        </div>

                        <p>
                            Taekwondo hat mehrere Ausbildungsdisziplinen, welche
                            die Grundlage dieser Kampfkunst bilden:
                        </p>
                        <ul>
                            <li>
                                Poomse: Formenlauf, Grundschule und
                                Pflichtübungen
                            </li>
                            <li>
                                Hanbon-Kyorugi: Partnerübungen/abgesprochener
                                Kampf
                            </li>
                            <li>
                                Jayu-Kyorugi: Freikampf/Wettkampf (olympische
                                Disziplin)
                            </li>
                            <li>Kyokpa: Bruchtest </li>
                            <li>Ho-Sin-Sul: Selbstverteidigung</li>
                        </ul>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row>
                            <Col md="6">
                                <img
                                    src={Robert}
                                    alt="Schlagpolstertraining im Trainingslager"
                                    class="img-fluid"
                                />
                                <p class="text-muted mt-2">
                                    <small>
                                        Trainingslager am Wörthersee. Spaß an
                                        der Bewegung und der Ausgleich zum
                                        Alltag stehen bei uns im Vordergrund.
                                    </small>
                                </p>
                            </Col>
                            <Col md="6" className="d-none d-md-block">
                                <img
                                    src={TLGirls}
                                    alt="Spaß im Trainingslager"
                                    class="img-fluid"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5 mb-md-7">
                        <h2>Macht Kampfkunst aggressiv?</h2>
                        <p>
                            Das soziale Miteinander spielt in jeder Kampfkunst
                            eine große Rolle. Konflikten aus dem Weg zu gehen
                            ist eigentlich das große Ziel. Im Training geht es
                            natürlich nicht darum dem anderen zu schaden. Es ist
                            allerdings eine wertvolle Erfahrung Schläge und
                            Tritte am eigenen Körper zu erleben, bzw. umgekehrt
                            selbst Gewalt auszuüben. Wichtig hierbei ist, dass
                            das geschützt in unserem Übungsraum unter Anleitung
                            von Trainern und angepasst an die Voraussetzung des
                            Sportlers passiert. Vor allem anfangs wird auf
                            Polster (wir nennen sie Pratzen) geschlagen oder
                            getreten, man merkt aber schon hierbei welche Kräfte
                            sich entfalten können und lernt dass Gewalt auch
                            Konsequenzen hat.
                        </p>
                        <p>
                            Taekwondo ist nicht nur das Erlernen und Trainieren
                            von Selbstverteidigungs- und Wettkampftechniken,
                            sondern stellt auch eine Art Lebensschule im
                            geistigen Sinne dar. Durch die Kombination von
                            physischer und psychischer Stärke entsteht ein
                            starker Leistungswille, der es ermöglicht den
                            Herausforderungen des Lebens mit{" "}
                            <strong>
                                innerer Ruhe, Gelassenheit und Sicherheit
                            </strong>{" "}
                            entgegenzusehen.
                        </p>
                        <p>
                            Durch eine sogenannte „Meditation in Bewegung“ kommt
                            es zur{" "}
                            <strong>Ausschüttung von Glückshormonen</strong>. In
                            der Folge sind die Lernenden ruhig und zufrieden.
                            Kampfkünste gelten als Form der aktiven Meditation.
                        </p>
                        <p>
                            Die Trainer ermutigen die Schüler durch das Training
                            in der Gruppe einen höflichen Umgang zu pflegen und
                            voneinander zu lernen. Gewalt wird nicht toleriert.
                            Aggressives Verhalten oder Stören des Trainings wird
                            nicht akzeptiert. Im Extremfall können Trainierende,
                            die sich nicht an die Regeln halten, vom Training
                            ausgeschlossen werden. Kampfkunst lehrt andere
                            Dinge:{" "}
                            <strong>
                                Konzentration, Disziplin, Selbstbewusstsein und
                                Steigerung der Leistungsfähigkeit
                            </strong>
                            .
                        </p>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + Juergen + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={Juergen}
                    alt="Jürgen beim Bruchtest mit Pandalchagi"
                />
            </Row>

            <Row className="bg-light justify-content-center pt-5">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-6 mb-md-8">
                        <h2>Kampfkunst birgt ein hohes Verletzungsrisiko?</h2>
                        <p>
                            Risiken gibt es überall. Minimiert werden diese im
                            Taekwondo durch gezieltes Aufwärmtraining und
                            Dehnen, gegenseitigen Respekt bei Partnerübungen und
                            das genaue Befolgen von Anweisungen des Trainers.
                        </p>
                        <p>
                            Diverse Studien reihen Taekwondo bzgl. des
                            Verletzungsrisikos (prozentuell unter Rücksichtnahme
                            der Anzahl an ausübenden Sportlern) am hinteren
                            Ende, weit hinter Fußball, Handball, Volleyball,
                            Judo, Squash und Radsport.
                        </p>
                        <p>
                            Wir sind ein Breitensportverein der die{" "}
                            <strong>Gesundheit</strong> seiner Mitglieder
                            fördern will. Ziel des Trainings ist nicht ein K.O
                            des Trainingspartners, sondern das sportliche
                            Miteinander.
                        </p>
                        <p>
                            Selbst bei Bruchtests, bei denen Bretter oder Ziegel
                            zerschlagen oder zertreten werden, ist das
                            Verletzungsrisiko gering, da diese Bruchtests nur
                            von Schülern erwartet werden deren Technik
                            entsprechend fortgeschritten ist.
                        </p>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

const TaekwondoWithPublicPanel = withPublicPanel(Taekwondo);
export default TaekwondoWithPublicPanel;
