import React from "react";

import { Toast } from "react-bootstrap";

const ToastHelper = (props) => {
    return (
        <div
            style={{
                position: "fixed",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%)",
            }}
        >
            <Toast
                onClose={() => props.setShowToast(false)}
                show={props.show}
                delay={5000}
                autohide
            >
                <Toast.Body>{props.body}</Toast.Body>
            </Toast>
        </div>
    );
};

export default ToastHelper;
