import React from "react";

const Error = (props) => {
    const error = props.error;

    return (
        <div class="text-center">
            <h1 class="display-1">{error.status}</h1>
            <p>
                {error.data}
                <br />
                <small>{error.responseURL}</small>
            </p>
        </div>
    );
};

export default Error;
