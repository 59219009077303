import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import FAQ from "./FAQ";

import withPublicPanel from "../withPublicPanel";

const Hours = (props) => {
    return (
        <Row className="bg-light">
            <Col>
                <Container className="mb-6 mb-md-8 text-center">
                    <h1 class="page-header">Trainingszeiten</h1>
                    <p>
                        Mittwoch &amp; Freitag, 18:30 bis 20:00 Uhr <br />
                        Turnsaal der Mittelschule Vorau <br />
                        <small>
                            <a
                                href="https://goo.gl/maps/zgJ1nBJJRAGGfMsw8"
                                target="_blank"
                                title="Google Maps"
                            >
                                Navigation
                            </a>
                        </small>
                    </p>
                    <p>Samstags unregelmäßiges Training für Fortgeschrittene</p>
                    <p>
                        <a href="/kontakt">
                            Für Probetraining bitte um Voranmeldung
                        </a>
                    </p>

                    <div class="mt-6 text-start">
                        <FAQ />
                    </div>
                </Container>
            </Col>
        </Row>
    );
};

const HoursWithPublicPanel = withPublicPanel(Hours);
export default HoursWithPublicPanel;
