import React from "react";

import apiClient from "../services/api";

import { Spinner, Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

const MehrVomLeben = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <Row className="bg-light">
            <Col>
                <Container>
                    <div class="page-header mt-3 mt-md-5 mb-0">
                        <h1 className="mb-3">Initiative "Mehr vom Leben"</h1>
                    </div>
                    <Row className="justify-content-center mb-5">
                        <Col md={8} lg={6} className="">
                            <p>
                                Anfang 2022 wurden wir auf die Initiative{" "}
                                <a
                                    href="https://www.mehr-vom-leben.jetzt/"
                                    target="_blank"
                                >
                                    Mehr vom Leben - Weniger Alkohol
                                </a>{" "}
                                aufmerksam und entschlossen uns dazu, uns aktiv
                                mit dem Thema Alkohol und Sport
                                auseinanderzusetzen. Vorweg, wir wollen
                                grundsätzlich Genussmittel nicht verteufeln,
                                jedoch bringt ein verantwortungsvoller Umgang
                                damit wesentliche Vorteile, besonders für
                                unseren Sport.
                            </p>
                            <p>
                                Wir achten schon immer darauf, bei unserer
                                Grillfeier, Weihnachtsfeier und beim
                                Trainingslager, alkoholfreie Getränke anzubieten
                                (z.B. die Fruchtsäfte vom Kuchlbauer). Auch nach
                                Wettkämpfen stoßen wir gerne auch mit
                                alkoholfreien Getränken an um unsere Erfolge zu
                                feiern. Druck oder Gruppenzwang zum
                                Alkoholkonsum gibt es bei uns nicht!
                            </p>

                            <Row>
                                <Col lg={8} className="py-4">
                                    <p>
                                        Uns geht es nun vielmehr darum,
                                        sachliche Aufklärung zum Thema Alkohol
                                        und Sport zu leisten, um schlussendlich
                                        auch den Trainingserfolg positiv zu
                                        beeinflussen. Wusstest Du zum Beispiel,
                                        dass bereits ein kleines Bier nach dem
                                        Training negative Auswirkungen auf die
                                        Regeneration hat?
                                    </p>
                                </Col>
                                <Col className="bg-secondary text-white p-4 mb-4 ms-lg-5 me-lg-n10">
                                    <p className="mb-0">
                                        Schon kleine Mengen Alkohol behindern
                                        nach dem Training die Regeneration des
                                        Muskelgewebes. Alkohol entzieht dem
                                        Körper Wasser, das für die Nähr- und
                                        Mineralstoffversorgung der Muskulatur
                                        benötigt wird.
                                    </p>
                                </Col>
                            </Row>

                            <p>
                                Vor allem als Kampfkünstler (Stichwort „Do“)
                                haben wir eine gesellschaftliche und soziale
                                Verantwortung und nehmen somit auch eine
                                Vorbildwirkung ein. Im Taekwondo legen wir
                                besonderen Wert auf Disziplin,
                                Selbstbeherrschung, Durchhaltevermögen,
                                Zielorientiertheit, Gemeinschaft und
                                gegenseitige Unterstützung. Ein unmäßiger
                                Alkoholkonsum stünde diesen Grundsätzen
                                entgegen.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};

const MehrVomLebenWithPublicPanel = withPublicPanel(MehrVomLeben);
export default MehrVomLebenWithPublicPanel;
