import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

const CoachCompetitionResultAddTeam = (props) => {
    const [addResultIsLoading, setAddResultIsLoading] = React.useState(false);

    const [newTeamResult, setNewTeamResult] = React.useState({});

    const handleSubmit = (event) => {
        event.preventDefault();

        setAddResultIsLoading(true);

        var data = { ...newTeamResult, competition: props.competition_id };

        apiClient
            .post("/api/competition_team", data)
            .then((response) => {
                console.log(response.data);
                props.switchUpdateSwitch();
                //reset form
                setNewTeamResult({
                    team: "",
                    class: "",
                    result: "",
                    num_opponents: "",
                });
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setAddResultIsLoading(false);
            });
    };

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewTeamResult({
            ...newTeamResult,
            [evt.target.name]: value,
        });
    }

    const teamSelect = props.teams.map((team) => (
        <option value={team.id}>
            {team.member_1.lastname}, {team.member_2.lastname}
            {team.member_3 ? ", " + team.member_3.lastname : ""}
        </option>
    ));

    const classesSelect = props.classes.map((cls) => (
        <option value={cls.id}>{cls.name}</option>
    ));

    if (props.loggedIn) {
        return (
            <>
                <Form id="competition-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Team</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleChange}
                                name="team"
                                value={newTeamResult.team}
                            >
                                <option value="0"></option>
                                {teamSelect}
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Klasse</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleChange}
                                name="class"
                                value={newTeamResult.class}
                            >
                                <option value="0"></option>
                                {classesSelect}
                            </Form.Control>
                        </Col>
                        <Col xs={6} md={3} lg={2} className="mt-3 mb-1">
                            <Form.Label>Platzierung</Form.Label>
                            <Form.Control
                                type="text"
                                name="result"
                                value={newTeamResult.result}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={6} md={3} lg={2} className="mt-3 mb-1">
                            <Form.Label>Anzahl Gegner</Form.Label>
                            <Form.Control
                                type="text"
                                name="num_opponents"
                                value={newTeamResult.num_opponents}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} className="mt-3 mb-1">
                            <Button type="submit">
                                {addResultIsLoading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    "Ergebnis hinzufügen"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachCompetitionResultAddTeam;
