import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachGradingsAdd = (props) => {
    /*
     *   Add Grading Date
     */
    const [newDate, setNewDate] = useState(new Date());

    const addSessionIsLoading = false;

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = {
            date: newDate,
        };

        apiClient
            .post("/api/gradingdate", data)
            .then((response) => {
                console.log(response.data);
                props.switchUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    if (props.loggedIn) {
        return (
            <div>
                <h3>Neuer Prüfungstermin</h3>

                <Form id="gradingdate-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <DatePicker
                                selected={newDate}
                                onChange={(date) => setNewDate(date)}
                                dateFormat="d. MMMM yyyy"
                                locale="de"
                                className="form-control"
                                calendarStartDay={1}
                            />
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Button
                                type="submit"
                                //disabled={this.state.submitButtonDisabled}
                            >
                                {addSessionIsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    "Prüfungstermin hinzufügen"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachGradingsAdd;
