import React from "react";

import { Container, Form, Spinner, Row, Col, Button } from "react-bootstrap";

import apiClient from "../../services/api";
import { IMG_BASE_URL, IMG_URL_DEFAULT_MEMBER } from "../../constants";
import GradingsHelper from "../helpers/GradingsHelper";

import UnknownMember from "../../images/unknown_member.jpg";

import LuisePandal from "../../images/luise-pandalchagi.jpg";
import Guetesiegel from "../../images/guetesiegel.png";

const WhyKwonbaek = (props) => {
    //let trainerList = [{ image: LuisePandal }, { image: LuisePandal }];

    const [coaches, setCoaches] = React.useState([]);

    React.useEffect(() => {
        apiClient
            .get("/api/coach")
            .then((response) => {
                console.log(response.data);

                let coachesHelper = GradingsHelper.sortMembersByGrade(
                    response.data
                );
                while (coachesHelper.length < 6) {
                    coachesHelper = coachesHelper.concat(coachesHelper);
                }
                setCoaches(coachesHelper);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    }, []);

    const trainerSlides = coaches.map((trainer) => {
        return (
            <li class="uk-width-1-1 uk-width-1-3@s uk-width-1-5@m">
                <Row className="pe-6 pe-sm-0">
                    <Col xs={10}>
                        <img
                            src={
                                trainer.avatar_url
                                    ? IMG_BASE_URL + trainer.avatar_url
                                    : UnknownMember //IMG_BASE_URL + IMG_URL_DEFAULT_MEMBER
                            }
                            alt={trainer.firstname + " " + trainer.lastname}
                            class="img-fluid"
                        />
                    </Col>
                    <Col xs={2}>
                        <div class="position-relative h-100">
                            <div class="text-vertical ms-n3">
                                <h3 class="h2 my-0">
                                    {trainer.firstname + " " + trainer.lastname}
                                </h3>
                            </div>
                        </div>
                    </Col>
                </Row>
            </li>
        );
    });

    return (
        <Container>
            <Row className="min-vh-75 align-items-center">
                <Col lg={6} className="mt-5 mb-5 mb-md-7">
                    <h2 class="h1 mb-4">
                        <span class="text-primary">Warum</span> Kwonbaek Vorau?
                    </h2>
                    <div class="pe-lg-10">
                        <p>
                            Wir sind Mitglied des Österreichischen Taekwondo
                            Verbandes (ÖTDV), dem einzigen olympischen Taekwondo
                            Sportverband in Österreich, welcher wiederum in den
                            Europäischen sowie den Welt Taekwondo Verband (World
                            Taekwondo) eingegliedert ist.
                        </p>
                        <p>
                            Unterrichtet wird das Taekwondo des olympischen
                            Weltverbandes (World Taekwondo) von unseren
                            ausgebildeten und anerkannten Instruktoren und
                            Übungsleitern.
                        </p>
                        <p>
                            Dies gewährleistet ein Training nach weltweit
                            gültigen Standards. Gürtelprüfungen die bei uns
                            abgelegt werden sind weltweit anerkannt und erlauben
                            die Teilnahme an nationalen sowie internationalen
                            Turnieren und Meisterschaften.
                        </p>
                    </div>
                    <p>
                        <a href="http://oetdv.at" target="_blank">
                            Weitere Infos auf der Website des ÖTDV
                        </a>
                    </p>
                    <div class="mt-5 px-3 px-sm-5 pe-lg-15">
                        <img
                            src={Guetesiegel}
                            alt="Gütesiegel ÖTDV"
                            class="img-fluid"
                        />
                    </div>
                </Col>
                <Col lg={6} className="mb-5">
                    <div class="vw-100">
                        <div
                            class="uk-position-relative"
                            tabindex="-1"
                            data-uk-slider
                        >
                            <ul class="uk-slider-items uk-grid align-items-end">
                                {trainerSlides}
                            </ul>

                            <div class="uk-slidenav-container uk-position-bottom-left">
                                <a
                                    class="uk-slidenav"
                                    href="#"
                                    data-uk-slidenav-previous
                                    data-uk-slider-item="previous"
                                ></a>
                                <a
                                    class="uk-slidenav"
                                    href="#"
                                    data-uk-slidenav-next
                                    data-uk-slider-item="next"
                                ></a>
                            </div>
                        </div>
                    </div>

                    <h2 class="h1 mt-5 mb-2">
                        Unsere <span class="text-primary">Trainer</span>
                    </h2>
                    <p>
                        Wir bringen Dich an dein Ziel! Alle unsere Trainer sind
                        von der Landessport- bzw. Bundessportorganisation und
                        dem Taekwondofachverband ausgebildete Lehrwarte oder
                        Übungsleiter mit jahrelanger Erfahrung.
                    </p>
                    <p class="mt-4">
                        <Button href="/trainer" variant="secondary">
                            Mehr über unsere Trainer
                        </Button>
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default WhyKwonbaek;
