import React from "react";

import { Row, Col, Container, Table } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import Pruefung from "../images/content/2019-pruefung-alexander.jpg";

const Dobok = (props) => {
    const getBeltsRow = (color, kup, text) => {
        return (
            <tr>
                <td>
                    <p>{color}</p>
                    <p class="d-md-none">{kup}</p>
                </td>
                <td class="d-none d-md-table-cell text-nowrap">{kup}</td>
                <td>{text}</td>
            </tr>
        );
    };

    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5 mb-md-6">
                        <h1 class="page-header">Dobok &amp; Ty</h1>
                        <p>
                            Der Kampfanzug (Dobok) ist ein Anzug aus leichtem,
                            weißem Stoff, der aus einer Art Jacke und einer Hose
                            besteht. Der weiße Dobok symbolisiert moralische
                            Reinheit. Er ist stets sauber und ordentlich zu
                            halten, denn dies trägt wesentlich zum äußerlichen
                            Erscheinungsbild des Taekwondoin bei.
                        </p>
                        <p>
                            Schüler tragen ein weißes Revers, ein schwarzes
                            Revers ist ausschließlich Dan-Trägern vorbehalten.
                            Der Gürtel (Ty) dient nicht nur dazu den Dobok
                            zusammenzuhalten, sondern symbolisiert durch seine
                            Farbe auch den Leistungsgrad des Sportlers. Diese
                            farbliche Unterscheidung der Gürtel ist vor allem
                            bei den <strong>Schülergraden (Kup)</strong> von
                            Bedeutung damit der Trainer oder Meister das Können
                            des jeweiligen Schülers einordnen und
                            dementsprechend fordern und fördern kann. Die{" "}
                            <strong>Meistergrade</strong> werden auch als Dan
                            bezeichnet.
                        </p>
                        <p>
                            Um den nächsten Leistungsgrad (Kup, Dan) zu
                            erreichen, muss der Taekwondo-Sportler eine Prüfung
                            vor einem lizenzierten Prüfer ablegen. Er stellt
                            sein Können durch die Vorführung der jeweils
                            geforderten Techniken unter Beweis, sowie bei
                            fortgeschrittenen Graduierungen Poomsae (Formen),
                            Kyorugi (Freikampf) und Bruchtests.
                        </p>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + Pruefung + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={Pruefung}
                    alt="Jürgen beim Bruchtest mit Pandalchagi"
                />
            </Row>

            <Row className="justify-content-center pt-4 ">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5">
                        <h2>Schülergrade</h2>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th class="d-md-none">Farbe/Kup</th>
                                    <th class="d-none d-md-table-cell">
                                        Farbe
                                    </th>
                                    <th class="d-none d-md-table-cell">Kup</th>
                                    <th>Beschreibung</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getBeltsRow(
                                    "Weiß",
                                    "",
                                    "Weiß steht für den Beginn des Trainings. Es bedeutet Leere und die Reinheit. Der Schüler hat noch keine Vorkenntnisse."
                                )}

                                {getBeltsRow(
                                    "Gelb",
                                    "10. und 9. Kup",
                                    "Die Farbe Gelb sollte Wärme und Einsicht bedeuten, darunter versteht man, daß die erlernten Techniken und Kombinationen einen Sinn erhalten. Es werden Grundtechniken und leichte Kombinationen überprüft."
                                )}

                                {getBeltsRow(
                                    "Grün",
                                    "8. und 7. Kup",
                                    "Beginnt mit den beiden Formen Taeguk Il-Jang und I-Jang. Das Grün steht für Wachstum, Schönheit und Kraft. Dabei bilden die verschiedenen Schlag-, Tritt- und Blocktechniken eine Einheit und werden zu einer Form."
                                )}

                                {getBeltsRow(
                                    "Blau",
                                    "6. und 5. Kup",
                                    "Beginnt mit den beiden Formen Taeguk Sam-Jang und Sa-Jang. Bei der Farbe Blau denkt man an den Himmel, an die Weite und die Ewigkeit. Hier beginnt der Tae Kwon Doin schon seine Stärken in der Technik zu zeigen und diese in der jeweiligen Form umzusetzen. Der Schüler bekommt die Entwicklung des Selbstvertrauens zu spüren."
                                )}

                                {getBeltsRow(
                                    "Braun",
                                    "4. und 3. Kup",
                                    "Beginnt mit den beiden Formen Taeguk Oh-Jang und Juk-Jang. Mit der Farbe Braun verbindet man die Erde und holt damit den Schüler wieder vom Himmel auf die Erde zurück um ihn jetzt zu überzeugen, daß sein Lernen noch nicht vollendet ist und es ihm an Ausdauer, Geschmeidigkeit und Konzentration fehlt."
                                )}

                                {getBeltsRow(
                                    "Rot",
                                    "2. und 1. Kup",
                                    "Beginnt mit den beiden Formen Taeguk Chil-Jang und Pal-Jang. Rot steht für Achtung, aber auch für Feuer. Man kann sich dabei verbrennen, etwas Wertvolles zerstören und vom eigentlichen Weg abkommen. Hingegen sich selbst und den anderen eine Hilfe zu sein zeigt schon, daß man mit Ehrfurcht und Wissen auf dem richtigen Weg zu einem Schwarzgurt (Meister) ist."
                                )}
                            </tbody>
                        </Table>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center pt-4">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5 mb-md-6">
                        <h2>Meistergrade (Dan)</h2>
                        <p>
                            Ein Meister, oder Dan-Träger, darf den schwarzen
                            Gürtel tragen. Die Farbe Schwarz hat viele
                            Bedeutungen: Es kann Angst Dunkelheit, Macht und
                            Stärke bedeuten. Es macht den Danträger aber nicht
                            zu dem der alles weiß und kann. Im Schwarzgurt
                            verkörpern sich alle anderen Farben und man merkt,
                            daß man als Meister immer noch Schüler ist. Der
                            Träger des 1. Dan ist mit seinem Lernen noch lange
                            nicht am Ziel angekommen. Er selbst muss sich erst
                            verwirklichen und sein ganzes Wissen und Können
                            verarbeiten, um seinen persönlichen Stil (Kraft,
                            Dehnung und Erscheinungsbild der Techniken) im
                            Taekwondo zu vervollkommnen.
                        </p>
                        <p>
                            Ab dem 6. Dan bekommt der Träger den Ehrentitel
                            "Großmeister" zugeteilt. Ziele sind durch den
                            eigenen Willen zu erreichen , dazu gehören
                            Gesundheit, körperliche und geistige Kraft sowie
                            Freude am Leben. Man ist nie am Ziel, es gibt immer
                            Höheres zu erstreben.
                        </p>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

const DobokWithPublicPanel = withPublicPanel(Dobok);
export default DobokWithPublicPanel;
