import apiClient from "../../services/api";

import React from "react";

import { Table, Button } from "react-bootstrap";
import { MdClear } from "react-icons/md";

const CoachCompetitionTeamresults = (props) => {
    /*
     * Display Team Results
     */

    let setIsLoading = props.setIsLoading,
        switchUpdateSwitch = props.switchUpdateSwitch,
        isLoading = props.isLoading;

    const handleRemoveTeamResult = (event) => {
        setIsLoading(true);
        var targetId = event.target.id
            ? event.target.id
            : event.target.parentNode.id;

        apiClient
            .delete("/api/teamresult/" + targetId)
            .then((response) => {
                console.log(response.data);
                switchUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const teamResultsList = props.teamresults.map((team_result) => (
        <tr key={team_result.id}>
            <td>
                {team_result.member_1.firstname} {team_result.member_1.lastname}
                <br />
                {team_result.member_2.firstname} {team_result.member_2.lastname}
                {team_result.member_3 ? (
                    <>
                        <br />
                        {team_result.member_3.firstname}{" "}
                        {team_result.member_3.lastname}
                    </>
                ) : (
                    ""
                )}
            </td>
            <td>{team_result.classname}</td>
            <td>{team_result.result}. Platz</td>
            <td>{team_result.num_opponents}</td>
            <td>
                <Button
                    variant="danger"
                    size="sm"
                    id={team_result.id}
                    onClick={!isLoading ? handleRemoveTeamResult : null}
                >
                    <MdClear id={team_result.id} />
                </Button>
            </td>
        </tr>
    ));

    var teamResultsTable = (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th>Team</th>
                    <th>Kategorie</th>
                    <th>Platzierung</th>
                    <th>Anzahl Gegner</th>
                    <th>Ergebnis löschen</th>
                </tr>
            </thead>
            <tbody>{teamResultsList}</tbody>
        </Table>
    );

    return (
        <>
            <h2>Teambewerbe</h2>
            {teamResultsTable}
        </>
    );
};

export default CoachCompetitionTeamresults;
