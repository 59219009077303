import React, { useState, useEffect } from "react";

import apiClient from "../../services/api";

import CoachCompetitionsAdd from "./CoachCompetitionsAdd";

import { Table, Button } from "react-bootstrap";
import { MdModeEdit } from "react-icons/md";

import withCoachPanel from "../../withCoachPanel";

const CoachCompetitions = (props) => {
    /*
     *   Show Competitions
     */
    const [competitions, setCompetitions] = useState([]);
    const [updateSwitch, setUpdateSwitch] = useState(false);

    useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/competition")
                .then((response) => {
                    let competitions = response.data;
                    competitions.sort(function (a, b) {
                        var dateA = new Date(a.date);
                        var dateB = new Date(b.date);
                        if (dateA < dateB) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    setCompetitions(competitions);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const competitionsList = competitions.map((competition) => {
        var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        var date = new Date(competition.date);

        return (
            <tr key={competition.id}>
                <td>{date.toLocaleDateString("de-DE", options)}</td>
                <td>{competition.name}</td>
                <td>{competition.location}</td>
                <td>
                    <Button
                        href={"/coach/meisterschaften/" + competition.id}
                        size="sm"
                    >
                        <MdModeEdit />
                    </Button>
                </td>
            </tr>
        );
    });

    const switchUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };

    if (props.loggedIn) {
        return (
            <div class="mt-3 mt-md-5">
                <h1>Meisterschaften</h1>
                <CoachCompetitionsAdd
                    {...props}
                    switchUpdateSwitch={switchUpdateSwitch}
                ></CoachCompetitionsAdd>

                <h3>Vergangene Meisterschaften</h3>
                <div className="mb-4">
                    <Table responsive striped hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Meisterschaft</th>
                                <th>Ort</th>
                                <th>Ergebnisse verwalten</th>
                            </tr>
                        </thead>
                        <tbody>{competitionsList}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachCompetitionsWithCoachPanel = withCoachPanel(CoachCompetitions);
export default CoachCompetitionsWithCoachPanel;
