import React from "react";

class GradingsHelper extends React.Component {
    static getMaxGrading = (gradings) => {
        if (gradings.length) {
            var max = gradings.reduce(function (prev, current) {
                return prev.belt_grade_order > current.belt_grade_order
                    ? prev
                    : current;
            });
            return max;
        }
        return { grade: "", belt_grade_order: -Infinity };
    };

    static sortMembersByGrade = (members, grade_order_varname) => {
        if (members.length) {
            var sortedMembers = members.sort((a, b) => {
                let aGrading = 0,
                    bGrading = 0;

                if (!grade_order_varname) {
                    aGrading = this.getMaxGrading(a.gradings);
                    bGrading = this.getMaxGrading(b.gradings);
                } else {
                    aGrading = { belt_grade_order: a[grade_order_varname] };
                    bGrading = { belt_grade_order: b[grade_order_varname] };
                }

                //if same grade, compare lastname
                if (aGrading.belt_grade_order === bGrading.belt_grade_order) {
                    if (a.lastname === b.lastname) {
                        return a.firstname.localeCompare(b.firstname);
                    } else {
                        //if same lastname, compare firstname
                        return a.lastname.localeCompare(b.lastname);
                    }
                }

                if (aGrading.belt_grade_order < bGrading.belt_grade_order) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return sortedMembers;
        }

        return members;
    };

    static sortGradings = (gradings) => {
        //TODO: sort by gradings_order if date is same
        return gradings.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            if (dateA < dateB) {
                return 1;
            } else if (dateA > dateB) {
                return -1;
            } else {
                //same date, look at manual order
                return a.belt_grade_order < b.belt_grade_order ? 1 : -1;
            }
        });
    };
}

export default GradingsHelper;
