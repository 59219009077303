import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import { FiFacebook, FiInstagram } from "react-icons/fi";

import Logo from "../images/logo.svg";
import Statuten from "../documents/verein-statut-17012007.pdf";

const Footer = (props) => {
    return (
        <>
            <Container className="pt-5 pt-lg-8 pb-5 d-print-none footer">
                <Row>
                    <Col xs="6" lg="2" className="footer-nav">
                        <p class="text-muted text-uppercase">Verein</p>
                        <p>
                            <a href="/mitglieder">Mitglieder</a>
                            <br />
                            <a href="/trainer">Trainer</a>
                            <br />
                            <a href="/vorstand">Vorstand</a>
                            <br />
                            <a href="/vereinsgeschichte">Vereinschronik</a>
                        </p>
                    </Col>
                    <Col xs="6" lg="3" className="footer-nav">
                        <p> &nbsp; </p>
                        <p>
                            <a href="/kontakt">Kontakt</a>
                            <br />
                            <a href="/impressum">Impressum</a>
                            <br />
                            Datenschutz
                            <br />
                            <a href={Statuten} target="_blank">
                                Statuten
                            </a>
                            <br />
                            <a href="/mitgliedsbeitraege">Mitgliedsbeiträge</a>
                        </p>
                    </Col>
                    <Col md="6" lg="3" className="mt-4 mt-lg-0 footer-nav">
                        <p class="text-muted text-uppercase"> Taekwondo </p>
                        <p>
                            <a href="/taekwondo" title="Über Taekwondo">
                                Was ist Taekwondo
                            </a>
                            <br />
                            <a href="/dojang" title="Dojang - Der Übungsraum">
                                Dojang
                            </a>
                            <br />
                            <a
                                href="/taekwondo/geschichte"
                                title="Geschichte des Taekwondo"
                            >
                                Geschichte
                            </a>
                            <br />
                            <a href="/dobok" title="Dobok und Ty">
                                Dobok und Ty
                            </a>
                            <br />
                            <a href="/poomsae" title="Poomsae">
                                Poomsae
                            </a>
                            <br />
                            <a href="/kyorugi" title="Kyorugi">
                                Kyorugi
                            </a>
                            <br />
                            <a href="/hosinsul" title="Hosinsul">
                                Hosinsul
                            </a>
                            <br />
                            <a href="/kyokpa" title="Kyokpa">
                                Kyokpa
                            </a>
                        </p>
                    </Col>
                    <Col md="6" lg="4" className="mt-4 mt-lg-0">
                        <p class="text-muted text-uppercase">Training</p>
                        <p>
                            Mittwoch &amp; Freitag, <br />
                            18:30 bis 20:00 Uhr
                        </p>
                        <p>Turnsaal der Mittelschule Vorau</p>
                        <p>
                            <a href="/kontakt">
                                Für Probetraining bitte um Voranmeldung
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className="pt-7 justify-content-between align-items-end text-center text-md-start">
                    <Col lg="5">
                        <img
                            src={Logo}
                            alt="Taekwondo Kwonbaek Vorau"
                            width="140"
                            class="img-fluid"
                        />
                    </Col>
                    <Col lg="4" className="mt-3 mt-lg-1">
                        <div class="h6">
                            <span class="me-2">
                                <a
                                    href="https://fb.com/kwonbaekvorau"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FiFacebook />
                                </a>
                            </span>
                            <span class="me-3">
                                <a
                                    href="https://instagram.com/kwonbaekvorau"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FiInstagram />
                                </a>
                            </span>
                            <small>#teamkwonbaek</small>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Footer;
