import apiClient from "../../services/api";

import React from "react";

import { Table, Button } from "react-bootstrap";
import { MdClear } from "react-icons/md";

const CoachCompetitionClubresults = (props) => {
    /*
     * Display Club Results
     */

    let setIsLoading = props.setIsLoading,
        switchUpdateSwitch = props.switchUpdateSwitch,
        isLoading = props.isLoading;

    const handleRemoveClubResult = (event) => {
        setIsLoading(true);
        var targetId = event.target.id
            ? event.target.id
            : event.target.parentNode.id;

        apiClient
            .delete("/api/clubresult/" + targetId)
            .then((response) => {
                console.log(response.data);
                switchUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const clubResultsList = props.clubresults.map((club_result) => (
        <tr key={club_result.id}>
            <td>{club_result.name}</td>
            <td>{club_result.result}. Platz</td>
            <td>
                <Button
                    variant="danger"
                    size="sm"
                    id={club_result.id}
                    onClick={!isLoading ? handleRemoveClubResult : null}
                >
                    <MdClear id={club_result.id} />
                </Button>
            </td>
        </tr>
    ));

    var clubResultsTable = (
        <Table responsive>
            <thead>
                <tr>
                    <th>Kategorie</th>
                    <th>Platzierung</th>
                    <th>Ergebnis löschen</th>
                </tr>
            </thead>
            <tbody>{clubResultsList}</tbody>
        </Table>
    );

    return (
        <>
            <h2>Vereinswertung</h2>
            {clubResultsTable}
        </>
    );
};

export default CoachCompetitionClubresults;
