import React from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

const CoachGradingAdd = (props) => {
    /*
     *   Add Grading
     */
    const addSessionIsLoading = false;

    const [newGradingState, setNewGradingState] = React.useState({
        member_id: 0,
        belt_grade_id: 0,
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        if (
            newGradingState.member_id != 0 &&
            newGradingState.belt_grade_id != 0
        ) {
            var data = {
                grading_date_id: props.grading.id,
                member_id: newGradingState.member_id,
                belt_grade_id: newGradingState.belt_grade_id,
            };

            apiClient
                .post("/api/grading", data)
                .then((response) => {
                    console.log(response.data);
                    props.switchUpdateSwitch();
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });

            //reset select field
            setNewGradingState({
                ...newGradingState,
                member_id: 0,
                belt_grade_id: 0,
            });
        } else {
            //TODO: display warning that select fields have to be chosen
        }
    };

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewGradingState({
            ...newGradingState,
            [evt.target.name]: value,
        });
    }

    var addableMembers = props.members.filter((member) => {
        return true;
        //because I don't want to filter now, since  one person could take 2 exams at once

        let alreadyAdded = props.grading.gradings.some((grad) => {
            return grad.member_id === member.id;
        });
        return !alreadyAdded;
    });

    const memberSelect = addableMembers.map((member) => (
        <option value={member.id}>
            {member.lastname}, {member.firstname}
        </option>
    ));

    const beltGradeSelect = props.beltGrades.map((beltGrade) => (
        <option value={beltGrade.id}>{beltGrade.grade}</option>
    ));

    if (props.loggedIn) {
        return (
            <div>
                <Form id="gradingdate-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Group controlId="gradingdate-collapse.member">
                                <Form.Label>Mitglied</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleChange}
                                    name="member_id"
                                    value={newGradingState.member_id}
                                >
                                    <option value="0"></option>
                                    {memberSelect}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Group controlId="gradingdate-collapse.beltGrade">
                                <Form.Label>Graduierung</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleChange}
                                    name="belt_grade_id"
                                    value={newGradingState.belt_grade_id}
                                >
                                    <option value="0"></option>
                                    {beltGradeSelect}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Button
                                type="submit"
                                //disabled={this.state.submitButtonDisabled}
                            >
                                {addSessionIsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    "Prüfung hinzufügen"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachGradingAdd;
