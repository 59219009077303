import apiClient from "../../services/api";

import React from "react";

import { Table, Button } from "react-bootstrap";
import { MdClear } from "react-icons/md";

const CoachCompetitionMemberresults = (props) => {
    /*
     * Display Member Results
     */

    let setIsLoading = props.setIsLoading,
        switchUpdateSwitch = props.switchUpdateSwitch,
        isLoading = props.isLoading;

    const handleRemoveMemberResult = (event) => {
        setIsLoading(true);
        var targetId = event.target.id
            ? event.target.id
            : event.target.parentNode.id;

        apiClient
            .delete("/api/memberresult/" + targetId)
            .then((response) => {
                console.log(response.data);
                switchUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const memberResultsList = props.memberresults.map((member_result) => (
        <tr key={member_result.id}>
            <td>
                {member_result.firstname} {member_result.lastname}
            </td>
            <td>{member_result.classname}</td>
            <td>{member_result.result}. Platz</td>
            <td>{member_result.num_opponents}</td>
            <td>
                <Button
                    variant="danger"
                    size="sm"
                    id={member_result.id}
                    onClick={!isLoading ? handleRemoveMemberResult : null}
                >
                    <MdClear id={member_result.id} />
                </Button>
            </td>
        </tr>
    ));

    var memberResultsTable = (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th>Sportler</th>
                    <th>Kategorie</th>
                    <th>Platzierung</th>
                    <th>Anzahl Gegner</th>
                    <th>Ergebnis löschen</th>
                </tr>
            </thead>
            <tbody>{memberResultsList}</tbody>
        </Table>
    );

    return (
        <>
            <h2>Einzelbewerbe</h2>
            {memberResultsTable}
        </>
    );
};

export default CoachCompetitionMemberresults;
