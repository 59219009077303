import React from "react";

import apiClient from "../../services/api";
import TimeHelper from "../helpers/TimeHelper";

import CoachMemberMembershipsAdd from "./CoachMemberMembershipsAdd";

import { Row, Col, Spinner, Form, Button } from "react-bootstrap";
import { MdNavigateBefore, MdClear } from "react-icons/md";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachMemberMemberships = (props) => {
    //let memberships = JSON.parse(JSON.stringify(props.member.memberships));

    const [memberships, setMemberships] = React.useState([]);

    React.useEffect(() => {
        //create a deep-copy of props attribute so that we don't manipulate props
        let memberships = JSON.parse(JSON.stringify(props.member.memberships));
        setMemberships(memberships);
    }, [props]); //update only when parent updates

    const updateMemberships = (id, date, attrName) => {
        let tmpMemberships = JSON.parse(JSON.stringify(memberships));
        let idx = tmpMemberships.findIndex((x) => x.id === id);
        if (tmpMemberships[idx]) {
            tmpMemberships[idx][attrName] = date;
        }
        setMemberships(tmpMemberships);

        var data = {
            start_date: tmpMemberships[idx].start_date,
            end_date: tmpMemberships[idx].end_date,
        };

        apiClient
            .post("/api/membership/" + id, data)
            .then((response) => {
                console.log(response.data);
                props.toggleUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    const onStartDateChange = (id, date) => {
        updateMemberships(id, date, "start_date");
    };

    const onEndDateChange = (id, date) => {
        date = TimeHelper.noUTCDate(date);
        updateMemberships(id, date, "end_date");
    };

    const handleRemoveMembership = (evt) => {
        console.log("remove: ", evt.target.id);
        var targetId = evt.target.id ? evt.target.id : evt.target.parentNode.id;

        apiClient
            .delete("/api/membership/" + targetId)
            .then((response) => {
                console.log(response.data);
                props.toggleUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    const membershipsList = memberships.map((membership) => {
        let endDate = membership.end_date
            ? new Date(membership.end_date)
            : null;
        return (
            <Row className="align-items-end">
                <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                    <p class="mb-2">Eintrittsdatum</p>
                    <DatePicker
                        selected={new Date(membership.start_date)}
                        onChange={onStartDateChange.bind(this, membership.id)}
                        dateFormat="d. MMMM yyyy"
                        locale="de"
                        className="form-control"
                        calendarStartDay={1}
                    />
                </Col>
                <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                    <p class="mb-2">Austrittsdatum</p>
                    <DatePicker
                        selected={endDate}
                        onChange={onEndDateChange.bind(this, membership.id)}
                        dateFormat="d. MMMM yyyy"
                        locale="de"
                        className="form-control"
                        calendarStartDay={1}
                        isClearable
                    />
                </Col>
                <Col className="mt-3 mb-1">
                    {/*
                    <Button
                        id={membership.id}
                        className="mr-2"
                        onClick={handleUpdateMembership}
                        //disabled={this.state.submitButtonDisabled}
                    >
                        Speichern
                    </Button>
                    */}
                    <Button
                        variant="danger"
                        id={membership.id}
                        onClick={handleRemoveMembership}
                    >
                        Löschen
                    </Button>
                </Col>
            </Row>
        );
    });

    if (props.loggedIn) {
        return (
            <div>
                <h3>Mitgliedschaften</h3>
                {membershipsList}
                <CoachMemberMembershipsAdd
                    {...props}
                    toggleUpdateSwitch={props.toggleUpdateSwitch}
                />
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachMemberMemberships;
