import React from "react";
import withCoachPanel from "../withCoachPanel";
import CoachNavigation from "./CoachNavigation";
import MemberNavigation from "./MemberNavigation";

const Coach = (props) => {
    return (
        <div class="d-md-none mt-3 mt-md-5 ">
            <CoachNavigation {...props} />
            <MemberNavigation {...props} />
        </div>
    );
};

const CoachWithCoachPanel = withCoachPanel(Coach);
export default CoachWithCoachPanel;
