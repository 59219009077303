import React, { useState } from "react";

import apiClient from "../../services/api";

import { Form, Row, Col, Button, Spinner } from "react-bootstrap";

const FileUpload = (props) => {
    const [file, setFile] = React.useState(undefined);
    const [inProgress, setInProgress] = React.useState(false);

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = (event) => {
        event.preventDefault();

        setInProgress(true);

        const data = new FormData();
        data.append("file", file);
        data.append("path", props.path);

        apiClient
            .post("/api/file", data)
            .then((response) => {
                console.log(response.data);
                setFile(undefined);
                document.getElementById("uploadFile").value = null;
                props.afterUpload();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    return (
        <>
            <Row>
                <Col xs={12} className="mt-2 mb-1">
                    <Form.Group controlId="uploadFileGroup">
                        {/*<Form.Label className="d-block">Upload</Form.Label>*/}
                        <Form.Control
                            type="file"
                            id="uploadFile"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="align-items-end">
                <Col xs={12} className="mt-2 mb-1">
                    <Button
                        type="submit"
                        onClick={handleUpload}
                        disabled={inProgress}
                    >
                        {inProgress ? <Spinner animation="border" /> : "Upload"}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default FileUpload;
