import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

import CoachCompetitionResultAddClub from "./CoachCompetitionResultAddClub";
import CoachCompetitionResultAddTeam from "./CoachCompetitionResultAddTeam";
import CoachCompetitionResultAddSingle from "./CoachCompetitionResultAddSingle";

const CoachCompetitionResultAdd = (props) => {
    /*
     *   Add Result
     */

    const [isLoading, setIsLoading] = React.useState(false);

    const resultTypeOptions = ["Single", "Team", "Verein"];
    const [resultType, setResultType] = useState("");

    const [classes, setClasses] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [teams, setTeams] = React.useState([]);

    const [isShowActiveCheckboxVisible, setIsShowActiveCheckboxVisible] =
        React.useState(false);
    const [showActiveOnly, setShowActiveOnly] = React.useState(true);
    const showActiveOnlyAction = () => {
        setShowActiveOnly(!showActiveOnly);
    };

    React.useEffect(() => {
        if (props.loggedIn) {
            setIsLoading(true);
            let query = showActiveOnly ? "?onlyActives=true" : "";
            apiClient
                .get("/api/member" + query)
                .then((response) => {
                    setMembers(response.data);
                    console.log("members:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });

            apiClient
                .get("/api/competitionteam")
                .then((response) => {
                    setTeams(response.data);
                    console.log("teams:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setMembers([]);
            setTeams([]);
        }
    }, [showActiveOnly]);

    React.useEffect(() => {
        if (props.loggedIn) {
            setIsLoading(true);
            apiClient
                .get("/api/competitionclass")
                .then((response) => {
                    setClasses(response.data);
                    console.log("classes:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setClasses([]);
        }
    }, [props.loggedIn]);

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setResultType(value);
        if (value === resultTypeOptions[0]) {
            //if Single member result, let choose if all members should be selectable
            setIsShowActiveCheckboxVisible(true);
        } else {
            setIsShowActiveCheckboxVisible(false);
        }
    }

    function handleSubmit(evt) {
        evt.preventDefault();
    }

    const resultTypeSelect = resultTypeOptions.map((resultType) => (
        <option value={resultType}>{resultType}</option>
    ));

    if (props.loggedIn) {
        return (
            <>
                <h4>Neues Ergebnis</h4>

                <Form id="competition-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Ergebnisart</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleChange}
                                name="resultType"
                                value={resultType}
                            >
                                <option value="0"></option>
                                {resultTypeSelect}
                            </Form.Control>
                        </Col>

                        {isShowActiveCheckboxVisible ? (
                            <Col xs={12} md={6} xl={3} className="mt-3 mb-4">
                                <Form.Check
                                    type="checkbox"
                                    onChange={showActiveOnlyAction}
                                    label="Nur Aktive"
                                    checked={showActiveOnly}
                                />
                            </Col>
                        ) : (
                            ""
                        )}
                    </Row>
                </Form>

                {/*Show Form depending on result type */}
                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <>
                        {resultType === resultTypeOptions[0] ? (
                            <CoachCompetitionResultAddSingle
                                {...props}
                                members={members}
                                classes={classes}
                            />
                        ) : resultType === resultTypeOptions[1] ? (
                            <CoachCompetitionResultAddTeam
                                {...props}
                                teams={teams}
                                classes={classes}
                            />
                        ) : resultType === resultTypeOptions[2] ? (
                            <CoachCompetitionResultAddClub
                                {...props}
                                classes={classes}
                            />
                        ) : (
                            ""
                        )}
                    </>
                )}
            </>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachCompetitionResultAdd;
