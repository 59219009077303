import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

const CoachCompetitionTeamsAdd = (props) => {
    /*
     *   Add Team
     */

    const [newTeam, setNewTeam] = useState({});
    const [members, setMembers] = React.useState([]);
    const [addTeamIsLoading, setAddTeamIsLoading] = React.useState(false);

    const [showActiveOnly, setShowActiveOnly] = React.useState(true);
    const showActiveOnlyAction = () => {
        setShowActiveOnly(!showActiveOnly);
    };

    const [isLoading, setIsLoading] = React.useState(false);

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewTeam({
            ...newTeam,
            [evt.target.name]: value,
        });
    }

    React.useEffect(() => {
        if (props.loggedIn) {
            setIsLoading(true);
            let query = showActiveOnly ? "?onlyActives=true" : "";
            apiClient
                .get("/api/member" + query)
                .then((response) => {
                    setMembers(response.data);
                    console.log("members:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setMembers([]);
        }
    }, [props.loggedIn, showActiveOnly]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = newTeam;

        apiClient
            .post("/api/competitionteam", data)
            .then((response) => {
                console.log(response.data);
                props.switchUpdateSwitch();
                setNewTeam({ member_1: "", member_2: "", member_3: "" });
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    const memberSelect = members.map((member) => (
        <option value={member.id}>
            {member.lastname}, {member.firstname}
        </option>
    ));

    if (props.loggedIn) {
        return (
            <div>
                <h3>Neues Team</h3>

                <Form className="mt-4 mb-1">
                    <Form.Check
                        type="checkbox"
                        onChange={showActiveOnlyAction}
                        label="Nur Aktive"
                        checked={showActiveOnly}
                    />
                </Form>

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <Form id="competition-collapse" onSubmit={handleSubmit}>
                        <Row className="align-items-end">
                            <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                                <Form.Label>Sportler 1</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleChange}
                                    name="member_1"
                                    value={newTeam.member_1}
                                >
                                    <option value="0"></option>
                                    {memberSelect}
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                                <Form.Label>Sportler 2</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleChange}
                                    name="member_2"
                                    value={newTeam.member_2}
                                >
                                    <option value="0"></option>
                                    {memberSelect}
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                                <Form.Label>Sportler 3</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleChange}
                                    name="member_3"
                                    value={newTeam.member_3}
                                >
                                    <option value="0"></option>
                                    {memberSelect}
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                                <Button
                                    type="submit"
                                    //disabled={this.state.submitButtonDisabled}
                                >
                                    {addTeamIsLoading ? (
                                        <Spinner animation="grow" />
                                    ) : (
                                        "Team hinzufügen"
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachCompetitionTeamsAdd;
