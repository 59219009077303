import React from "react";

import apiClient from "../services/api";
import { IMG_BASE_URL, IMG_URL_DEFAULT_MEMBER } from "../constants";

import { Spinner, Row, Col, Container } from "react-bootstrap";

import GradingsHelper from "./helpers/GradingsHelper";

import UnknownMember from "../images/unknown_member.jpg";

import withPublicPanel from "../withPublicPanel";

const Vorstand = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const [vorstand, setVorstand] = React.useState([]);

    React.useEffect(() => {
        setIsLoading(true);
        apiClient
            .get("/api/vorstand")
            .then((response) => {
                console.log(response.data);
                setVorstand(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const vorstandList = vorstand.map((member) => (
        <Col key={member.id} xs={6} md={3} xl={2} className="mb-5">
            <div class="mb-2">
                <a
                    href={"/mitglieder/" + member.slug}
                    title={member.firstname + " " + member.lastname}
                >
                    <img
                        src={
                            member.avatar_url
                                ? IMG_BASE_URL + member.avatar_url
                                : UnknownMember //IMG_BASE_URL + IMG_URL_DEFAULT_MEMBER
                        }
                        className="img-fluid"
                    />
                </a>
            </div>
            <p class="text-center">
                <span class="text-primary">{member.position}</span>
                <br />
                {member.firstname} {member.lastname} <br />
                <small>
                    {GradingsHelper.getMaxGrading(member.gradings).grade}
                </small>
            </p>
        </Col>
    ));

    return (
        <Row className="bg-light">
            <Col>
                <Container className="mt-3 mt-md-5">
                    <div class="page-header">
                        <h1>Unser Vorstand</h1>
                    </div>
                    {isLoading ? (
                        <div class="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <Row className="mb-7">{vorstandList}</Row>
                    )}
                </Container>
            </Col>
        </Row>
    );
};

const VorstandWithPublicPanel = withPublicPanel(Vorstand);
export default VorstandWithPublicPanel;
