import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import SchwitzkastenJuergen from "../images/content/20220716-dan-juergen-schwitzkasten.jpg";
import SVEdi from "../images/content/2021-trainingslager-sv.jpg";
import ManfredTakedown from "../images/content/AJS_7523.jpg";

const Hosinsul = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5">
                        <h1 class="page-header">Hosinsul</h1>
                        <p>
                            Hosinsul, damit ist die Selbstverteidigung gegen
                            bewaffnete und unbewaffnete plötzliche Angriffe
                            gemeint. Der Schüler soll in der Lage sein, sich
                            gegen unbewaffnete Angriffe (Festhalten,
                            Umklammerungen ...) oder bewaffnete Angriffe (mit
                            einem Messer, Schusswaffe, Stock, etc.) erfolgreich
                            zur Wehr zu setzen. Dies erfordert hochentwickelte
                            körperliche und geistige Fähigkeiten. Maßgebend
                            hierbei ist jedoch die eigene Schocksekunde
                            möglichst klein zu halten und sofort zu reagieren.
                        </p>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row className="align-items-center">
                            <Col
                                md="6"
                                className="d-none d-md-block pe-md-4 pe-xl-5"
                            >
                                <p>
                                    Es muss jedoch bei der Verteidigung die
                                    Verhältnismäßigkeit der Mittel eingehalten
                                    werden. Das heißt, dass die Abwehr der Art
                                    des Angriffes entsprechen muss und es muss
                                    die körperliche Relation zum Angreifer
                                    berücksichtigt werden. Das mildeste
                                    geeignete Mittel muss eingesetzt werden , um
                                    dem Gegner den geringsten Schaden zuzufügen.
                                </p>
                            </Col>
                            <Col md="6">
                                <img
                                    class="img-fluid"
                                    alt="Selbstverteidigung gegen Schwitzkasten"
                                    src={SchwitzkastenJuergen}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row className="align-items-center">
                            <Col md="6">
                                <img
                                    class="img-fluid"
                                    alt="Selbstverteidigung gegen Schwitzkasten"
                                    src={SVEdi}
                                />
                            </Col>
                            <Col
                                md="6"
                                className="d-none d-md-block ps-md-4 ps-xl-5"
                            >
                                <p>
                                    Der Schüler soll lernen, dass er viele
                                    Möglichkeiten hat, einen Angriff abzuwehren.
                                    Von Vorteil ist es hier, wenn man die Kraft
                                    des Gegners gegen ihn selbst verwenden kann,
                                    also nicht Kraft gegen Kraft, sondern die
                                    Kraft des Gegners ausnutzen, um ihn
                                    kampfunfähig zu machen. Die Kenntnis über
                                    die Vitalpunkte (Kup so) erleichtert die
                                    nach der Abwehr eines Angriffes
                                    herbeizuführende Kampfunfähigkeit des
                                    Gegners erheblich. Manchmal ist es taktisch
                                    jedoch besser sich zurückzuziehen, vor allem
                                    bei einem Angriff mit einem Messer oder
                                    einer Schusswaffe.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + ManfredTakedown + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={ManfredTakedown}
                    alt="Takedown von Manfred, Prüfung zum 3. Dan"
                />
            </Row>
        </>
    );
};

const HosinsulWithPublicPanel = withPublicPanel(Hosinsul);
export default HosinsulWithPublicPanel;
