import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import withPublicPanel from "../withPublicPanel";

import Yopchagi from "../images/content/20220514-steirische-lm-yopchagi.jpg";

const Poomsae = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6">
                    <Container className="mb-5">
                        <h1 class="page-header">Poomsae</h1>
                        <p>
                            Die Poomse (Bewegungsformen) stellen verschiedene
                            Kampfsituationen gegen imaginäre Angreifer dar. Von
                            alters her werden Poomse dazu benutzt, Techniken zu
                            erlernen, sie zu Abwehr und Angriff miteinander zu
                            verbinden und dabei gleichzeitig die Bewegungen und
                            die Atmung miteinander abzustimmen. Dabei wird dem
                            Taekwondoin auch die Gelegenheit gegeben, zu lernen,
                            wie er bei schnellen Bewegungen das Gleichgewicht
                            erhalten und Gewichtsverlagerungen ausnutzen kann.
                            Mit Taeguk (übers.: Größe und Ewigkeit - steht für
                            den Ursprung allen Seins) hat man die acht
                            grundlegenden Formen des Taekwondo bezeichnet, die
                            von der WT besonders propagiert werden und die in
                            der ganzen Welt Gültigkeit haben. Gleichzeitig
                            werden acht Grundgedanken der ostasiatischen
                            Philosophie aus dem Taeguk entwickelt: die acht
                            Zeichen (Trigramme) der Macht, auf deren Grundlinien
                            wir uns bei der Taeguk-Poomse bewegen.
                        </p>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row className="align-items-center">
                            <Col md="6">
                                <div class="ratio ratio-16x9 mt-4 mb-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/WhkjRruCBTo"
                                        title="Taeguk Il (1) Jang"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </Col>
                            <Col md="6" className="d-none d-md-block">
                                <p>
                                    Himmel und Licht. Vom Himmel kommt der Regen
                                    und das Licht der Sonne, damit alles wachsen
                                    und gedeihen kann. Mit Himmel wird die
                                    Schöpfung symbolisiert, der Anfang des
                                    Seins.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6">
                    <Container className=" mb-4">
                        <Row className="align-items-center">
                            <Col md="6">
                                <div class="ratio ratio-16x9 mt-4 mb-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/tGlrUplKHh8"
                                        title="Taeguk Ie (2) Jang"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </Col>
                            <Col md="6" className="d-none d-md-block">
                                <p>
                                    Frohsinn und Fröhlichkeit. In diesem Zustand
                                    verfügt der Mensch über eine gefestigte
                                    innere Kraft, die ihn ausgeglichen und ruhig
                                    erscheinen läßt. Demgemäß soll diese Poomse
                                    ruhig, aber kraftvoll ausgeführt werden.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="bg-light justify-content-center">
                <Col lg="8" xl="6" className="px-xl-6"></Col>
            </Row>

            <Row
                className="bg-fixed vh-50 d-none d-md-block"
                style={{ backgroundImage: "url(" + Yopchagi + ")" }}
            ></Row>
            <Row className="d-md-none bg-light">
                <img
                    src={Yopchagi}
                    alt="Jürgen beim Bruchtest mit Pandalchagi"
                />
            </Row>
        </>
    );
};

const PoomsaeWithPublicPanel = withPublicPanel(Poomsae);
export default PoomsaeWithPublicPanel;
