import React, { useState, useEffect } from "react";

import apiClient from "../../services/api";

import { Table, Row, Col, Form } from "react-bootstrap";

import CoachCompetitionTeamsAdd from "./CoachCompetitionTeamsAdd";

import withCoachPanel from "../../withCoachPanel";

const CoachCompetitionTeams = (props) => {
    /*
     *   Show Teams
     */
    const [teams, setTeams] = useState([]);
    const [updateSwitch, setUpdateSwitch] = useState(false);

    useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/competitionteam")
                .then((response) => {
                    setTeams(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const handleChangeCheckboxIsTeamActive = (event) => {
        if (props.loggedIn) {
            let team = teams.filter((team) => {
                return team.id == event.target.id;
            });

            if (team.length) {
                team = team[0];
                console.log(team);

                team.isActive = !team.isActive;
                team.member_1 = team.member_1.id;
                team.member_2 = team.member_2.id;
                if (team.member_3) {
                    team.member_3 = team.member_3.id;
                }

                console.log(team);

                apiClient
                    .post("/api/competitionteam/" + team.id, team)
                    .then((response) => {
                        setUpdateSwitch(!updateSwitch);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        if (props.checkAuthError(error)) {
                        } else {
                            console.error(error);
                        }
                    });
            }
        }
    };

    const teamsList = teams.map((team) => {
        return (
            <>
                {team.member_3 ? (
                    <tr key={team.id}>
                        <td>
                            {team.member_1.firstname} {team.member_1.lastname}
                        </td>
                        <td>
                            {team.member_2.firstname} {team.member_2.lastname}
                        </td>
                        <td>
                            {team.member_3.firstname} {team.member_3.lastname}
                        </td>
                        <td>
                            <Form.Group controlId={team.id}>
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    checked={team.isActive}
                                    onChange={handleChangeCheckboxIsTeamActive}
                                />
                            </Form.Group>
                        </td>
                    </tr>
                ) : (
                    ""
                )}
            </>
        );
    });

    const pairsList = teams.map((team) => {
        return (
            <>
                {team.member_3 == null ? (
                    <tr key={team.id}>
                        <td>
                            {team.member_1.firstname} {team.member_1.lastname}
                        </td>
                        <td>
                            {team.member_2.firstname} {team.member_2.lastname}
                        </td>
                        <td>
                            <Form.Group controlId={team.id}>
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    checked={team.isActive}
                                    onChange={handleChangeCheckboxIsTeamActive}
                                />
                            </Form.Group>
                        </td>
                    </tr>
                ) : (
                    ""
                )}
            </>
        );
    });

    const switchUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };

    if (props.loggedIn) {
        return (
            <div class="mt-3 mt-md-5">
                <h1>Poomsae Teams</h1>
                <CoachCompetitionTeamsAdd
                    {...props}
                    switchUpdateSwitch={switchUpdateSwitch}
                />

                <Row className="mt-4">
                    <Col lg={7}>
                        <h3>Teams</h3>
                        <div className="mb-4 pe-6">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sportler 1</th>
                                        <th>Sportler 2</th>
                                        <th>Sportler 3</th>
                                        <th>Aktiv</th>
                                    </tr>
                                </thead>
                                <tbody>{teamsList}</tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <h3>Paare</h3>
                        <div className="mb-4">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sportler 1</th>
                                        <th>Sportler 2</th>
                                        <th>Aktiv</th>
                                    </tr>
                                </thead>
                                <tbody>{pairsList}</tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachCompetitionTeamsWithCoachPanel = withCoachPanel(
    CoachCompetitionTeams
);

export default CoachCompetitionTeamsWithCoachPanel;
