import React, { useState } from "react";

import apiClient from "../../services/api";

import FileBrowser from "./FileBrowser";

import { MdClear } from "react-icons/md";

import {
    Form,
    Button,
    Spinner,
    InputGroup,
    FormControl,
} from "react-bootstrap";

const AvatarSelect = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    function handleClearButton() {
        props.setMemberAvatarUrl("");
    }

    return (
        <>
            <Form.Group controlId="avatarimage">
                <Form.Label id="avatarimage-label">Profilbild</Form.Label>
                <InputGroup>
                    <FormControl
                        aria-label="Profilbild"
                        aria-describedby="avatarimage-label"
                        value={props.memberAvatarUrl}
                        title={props.memberAvatarUrl}
                        readonly
                        disabled
                    />
                    <Button
                        variant="secondary"
                        id="avatarselect-button"
                        onClick={handleShow}
                    >
                        Auswählen
                    </Button>
                    <Button
                        id="avatarclear-button"
                        onClick={handleClearButton}
                        variant="light"
                    >
                        <MdClear className="text-danger" />
                    </Button>
                </InputGroup>
            </Form.Group>
            <FileBrowser
                {...props}
                setShow={setShow}
                show={show}
                fileUrl={props.memberAvatarUrl}
                setFileUrl={props.setMemberAvatarUrl}
            />
        </>
    );
};

export default AvatarSelect;
