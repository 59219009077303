import React from "react";

import apiClient from "../services/api";
import { IMG_BASE_URL, IMG_URL_DEFAULT_MEMBER } from "../constants";

import { Spinner, Row, Col, Container } from "react-bootstrap";

import GradingsHelper from "./helpers/GradingsHelper";

import UnknownMember from "../images/unknown_member.jpg";

import withPublicPanel from "../withPublicPanel";

const Coaches = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const [coaches, setCoaches] = React.useState([]);

    React.useEffect(() => {
        setIsLoading(true);
        apiClient
            .get("/api/coach")
            .then((response) => {
                console.log(response.data);
                let coachesHelper = GradingsHelper.sortMembersByGrade(
                    response.data
                );
                setCoaches(coachesHelper);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const coachesList = coaches.map((member) => (
        <>
            <Col key={member.id} xs={6} md={3} xl={2} className="mb-5">
                <div class="mb-2">
                    <a
                        href={"/trainer/" + member.slug}
                        title={member.firstname + " " + member.lastname}
                    >
                        <img
                            src={
                                member.avatar_url
                                    ? IMG_BASE_URL + member.avatar_url
                                    : UnknownMember //IMG_BASE_URL + IMG_URL_DEFAULT_MEMBER
                            }
                            className="img-fluid"
                        />
                    </a>
                </div>
                <p class="text-center mb-1">
                    {member.firstname} {member.lastname} <br />
                    {GradingsHelper.getMaxGrading(member.gradings).grade}
                </p>
                <p class="text-center text-primary">
                    <small>{member.qualifications[0].name}</small>
                </p>
            </Col>
        </>
    ));

    return (
        <Row className="bg-light">
            <Col>
                <Container>
                    <div class="page-header mt-3 mt-md-5 mb-0">
                        <h1 className="mb-3">Unsere Trainer</h1>
                    </div>
                    <Row className="justify-content-center mb-5">
                        <Col md={8} lg={6} className="text-center">
                            <p>
                                Alle unsere Trainer sind von der Landessport-
                                bzw. Bundessportorganisation und dem
                                Taekwondofachverband ausgebildete Lehrwarte oder
                                Übungsleiter mit jahrelanger Erfahrung.
                            </p>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <div class="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <Row className="mb-7">{coachesList}</Row>
                    )}
                </Container>
            </Col>
        </Row>
    );
};

const CoachesWithPublicPanel = withPublicPanel(Coaches);
export default CoachesWithPublicPanel;
