import React from "react";

import { Accordion } from "react-bootstrap";

const Faq = (props) => {
    const accordionContents = [
        {
            header: "Kann ich das Training kostenlos testen?",
            body: (
                <>
                    <p>
                        Ja klar! Der Einstieg bei uns ist jederzeit möglich.
                        Besuche uns einfach beim Training zu den angegebenen
                        Zeiten und mach mit oder sieh einfach nur zu. Die ersten
                        Schnuppertrainings sind völlig unverbindlich und
                        kostenlos. Alles was Du hierfür benötigst ist lockere
                        Trainingskleidung und gute Laune.
                    </p>
                    <p>
                        Wir bitten allerdings um{" "}
                        <a href="/kontakt">Voranmeldung</a>.
                    </p>
                </>
            ),
        },
        {
            header: "Bin ich zu alt? Zu jung?",
            body: (
                <p>
                    Die Antwort ist ganz einfach. Unser jüngstes Mitglied ist 7
                    Jahre alt und unsere ältesten Mitglieder sind über 60 Jahre
                    jung. Bei gesundheitlichen Einschränkungen oder ab einem
                    Alter von ca. 40 Jahren sollte man sicherheitshalber vorher
                    mit seinem Arzt Rücksprache halten.
                </p>
            ),
        },
        {
            header: "Was brauche ich für mein Schnuppertraining?",
            body: (
                <>
                    <p>
                        Lockere Trainingskleidung in der Du dich gut bewegen
                        kannst (Trainingshose und T-Shirt). Du brauchst keine
                        Schuhe, wir trainieren barfuß. Nimm dir bei Bedarf auch
                        eine Wasserflasche und ein Handtuch mit.
                    </p>
                    <p>
                        Den weißen Trainingsanzug in dem wir trainieren können
                        wir für dich bestellen sobald du dich entscheidest dem
                        Verein beizutreten.
                    </p>
                </>
            ),
        },
        {
            header: "Wer trainiert wen?",
            body: (
                <>
                    <p>
                        Das Training wird von unseren ausgebildeten{" "}
                        <a href="/trainer">Übungsleitern/Lehrwarten</a> geführt.
                        Erwachsene und Kinder trainieren gemeinsam, allerdings
                        gibt es Gruppen je nach Gürtelgrad.
                    </p>
                    <p>
                        Besonders achten wir darauf, dass in unserer
                        Gemeinschaft jeder jeden respektiert.
                    </p>
                </>
            ),
        },
    ];

    const accordionItems = accordionContents.map((element, index) => (
        <Accordion.Item eventKey={index}>
            <Accordion.Header>{element.header}</Accordion.Header>
            <Accordion.Body>{element.body}</Accordion.Body>
        </Accordion.Item>
    ));

    return (
        <>
            <h2>FAQ</h2>
            <Accordion flush>{accordionItems}</Accordion>
        </>
    );
};

export default Faq;
