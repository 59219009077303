import React from "react";

import apiClient from "../../services/api";
import TimeHelper from "../helpers/TimeHelper";

import { Table, Button } from "react-bootstrap";
import { MdLaunch, MdClear } from "react-icons/md";

const CoachMemberGradings = (props) => {
    //let memberships = JSON.parse(JSON.stringify(props.member.memberships));

    React.useEffect(() => {}, [props]); //update only when parent updates

    let gradings = props.member.gradings.sort(function (a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        if (dateA < dateB) {
            return 1;
        } else {
            return -1;
        }
    });

    const gradingsList = gradings.map((grading) => {
        return (
            <tr>
                <td>{TimeHelper.formatDate(grading.date)}</td>
                <td>{grading.grade}</td>
                <td>
                    <Button href={"/coach/pruefungen/" + grading.id}>
                        <MdLaunch />
                    </Button>
                </td>
            </tr>
        );
    });

    if (props.loggedIn) {
        return (
            <div class="mb-6">
                <h3>Prüfungen</h3>

                <Table responsive>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Graduierung</th>
                            <th>Gehe zu Prüfungstermin</th>
                        </tr>
                    </thead>
                    <tbody>{gradingsList}</tbody>
                </Table>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachMemberGradings;
