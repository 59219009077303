import React, { useState, useEffect } from "react";

import apiClient from "../../services/api";

import CoachGradingsAdd from "./CoachGradingsAdd";

import { Table, Button } from "react-bootstrap";
import { MdPeople } from "react-icons/md";

import withCoachPanel from "../../withCoachPanel";

const CoachGradings = (props) => {
    /*
     *   Show Traning Sessions
     */
    const [gradings, setGradings] = useState([]);
    const [updateSwitch, setUpdateSwitch] = useState(false);

    useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/gradingdate")
                .then((response) => {
                    let sessions = response.data;
                    sessions.sort(function (a, b) {
                        var dateA = new Date(a.date);
                        var dateB = new Date(b.date);
                        if (dateA < dateB) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    setGradings(sessions);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const gradingsList = gradings.map((grading) => {
        var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        var date = new Date(grading.date);

        return (
            <tr key={grading.id}>
                <td>{date.toLocaleDateString("de-DE", options)}</td>
                <td>
                    <Button href={"/coach/pruefungen/" + grading.id} size="sm">
                        <MdPeople />
                    </Button>
                </td>
            </tr>
        );
    });

    const switchUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };

    if (props.loggedIn) {
        return (
            <div class="mt-3 mt-md-5">
                <h1>Prüfungen</h1>
                <CoachGradingsAdd
                    {...props}
                    switchUpdateSwitch={switchUpdateSwitch}
                ></CoachGradingsAdd>

                <h3>Vergangene Prüfungen</h3>
                <div className="mb-4">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Prüflinge verwalten</th>
                            </tr>
                        </thead>
                        <tbody>{gradingsList}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachGradingsWithCoachPanel = withCoachPanel(CoachGradings);
export default CoachGradingsWithCoachPanel;
