import React from "react";

import { MdNavigateBefore } from "react-icons/md";

const Backlink = (props) => {
    let link = props.link,
        text = props.text;
    return (
        <a href={link} className="d-block mb-2 text-center">
            <MdNavigateBefore /> {text}
        </a>
    );
};

export default Backlink;
