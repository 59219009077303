import React from "react";

import apiClient from "../../services/api";
import TimeHelper from "../helpers/TimeHelper";

import CoachMemberPositionsAdd from "./CoachMemberPositionsAdd";

import { Row, Col, Spinner, Form, Button } from "react-bootstrap";
import { MdNavigateBefore, MdClear } from "react-icons/md";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachMemberPositions = (props) => {
    const [positions, setPositions] = React.useState([]);

    React.useEffect(() => {
        //create a deep-copy of props attribute so that we don't manipulate props
        let positions = props.member.qualifications.concat(
            props.member.funktionen
        );
        setPositions(positions);
    }, [props]); //update only when parent updates

    const handleRemovePosition = (evt) => {
        console.log("remove: ", evt.target.id);
        var targetId = evt.target.id ? evt.target.id : evt.target.parentNode.id;

        apiClient
            .delete("/api/memberposition/" + targetId)
            .then((response) => {
                console.log(response.data);
                props.toggleUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    const positionsOptions = props.positions.map((position) => {
        return <option value={position.id}>{position.name}</option>;
    });

    const positionsList = positions.map((position) => {
        let startDate = position.start_date
            ? new Date(position.start_date)
            : null;
        let endDate = position.end_date ? new Date(position.end_date) : null;
        return (
            <Row className="align-items-end">
                <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                    <Form.Group controlId="position">
                        <Form.Label>Funktion/Ausbildung</Form.Label>
                        <Form.Control
                            as="select"
                            name="position"
                            value={position.position_id}
                            readOnly
                            disabled
                        >
                            {positionsOptions}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                    <p class="mb-2">Startdatum</p>
                    <DatePicker
                        selected={startDate}
                        dateFormat="d. MMMM yyyy"
                        locale="de"
                        className="form-control"
                        calendarStartDay={1}
                        readOnly
                        disabled
                    />
                </Col>
                <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                    <p class="mb-2">Enddatum</p>
                    <DatePicker
                        selected={endDate}
                        dateFormat="d. MMMM yyyy"
                        locale="de"
                        className="form-control"
                        calendarStartDay={1}
                        isClearable
                        readOnly
                        disabled
                    />
                </Col>
                <Col className="mt-3 mb-1">
                    <Button
                        variant="danger"
                        id={position.id}
                        onClick={handleRemovePosition}
                    >
                        Löschen
                    </Button>
                </Col>
            </Row>
        );
    });

    if (props.loggedIn) {
        return (
            <div class="mb-6">
                <h3>Funktionen &amp; Ausbildungen</h3>
                {positionsList}
                <CoachMemberPositionsAdd
                    {...props}
                    positions={props.positions}
                    toggleUpdateSwitch={props.toggleUpdateSwitch}
                />
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachMemberPositions;
