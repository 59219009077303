import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
} from "react-router-dom";

//import "@popperjs/core";

import "bootstrap/dist/css/bootstrap.min.css";

/*
import "uikit/dist/css/uikit-core.min.css";
*/

import "uikit/dist/js/uikit.min.js";
//import UIkit from "uikit";

import ToastHelper from "./components/bootstrap-helpers/ToastHelper";

import Members from "./components/Member/Members";
import Member from "./components/Member/Member";
import Coaches from "./components/Coaches";
import Vorstand from "./components/Vorstand";
import MehrVomLeben from "./components/MehrVomLeben";

import Coach from "./components/Coach";

import CoachMembers from "./components/coach/CoachMembers";
import CoachMember from "./components/coach/CoachMember";
import CoachTrainingssessions from "./components/coach/CoachTrainingssessions";
import CoachTrainingssession from "./components/coach/CoachTrainingssession";
import CoachTrainingssessionMembersSum from "./components/coach/CoachTrainingssessionMembersSum";
import CoachGradings from "./components/coach/CoachGradings";
import CoachGrading from "./components/coach/CoachGrading";

import CoachCompetitions from "./components/coach/CoachCompetitions";
import CoachCompetition from "./components/coach/CoachCompetition";
import CoachCompetitionTeams from "./components/coach/CoachCompetitionTeams";

import Login from "./components/Login";
import Footer from "./components/Footer";
import Homepage from "./components/homepage";
import Clubhistory from "./components/Clubhistory";
import Pricing from "./components/Pricing";
import Hours from "./components/Hours";
import Taekwondo from "./components/Taekwondo";
import TaekwondoHistory from "./components/TaekwondoHistory";
import Dojang from "./components/Dojang";
import Dobok from "./components/Dobok";
import Poomsae from "./components/Poomsae";
import Hosinsul from "./components/Hosinsul";
import Kyokpa from "./components/Kyokpa";
import Kyorugi from "./components/Kyorugi";

import Contact from "./components/Contact";
import Imprint from "./components/Imprint";

import apiClient from "./services/api";
import checkLogin from "./services/checkLogin";
import Backlink from "./components/Backlink";

import Error404 from "./services/Error404";

const App = () => {
    const [loggedIn, setLoggedIn] = React.useState(
        sessionStorage.getItem("loggedIn") == "true" || false
    );

    React.useEffect(() => {
        document.title = "Taekwondo Kwonbaek Vorau";
    }, []);

    const login = () => {
        setLoggedIn(true);
        sessionStorage.setItem("loggedIn", true);
    };

    const logout = () => {
        apiClient
            .post("/logout")
            .then((response) => {
                if (response.status === 204) {
                    setLoggedIn(false);
                    sessionStorage.setItem("loggedIn", false);
                }
            })
            .catch((error) => {
                checkLogin(error);
                console.error(error);
            });
    };

    const authLink = loggedIn ? (
        <button onClick={logout} className="btn btn-secondary">
            Logout
        </button>
    ) : (
        <NavLink to="/login" className="nav-item">
            Login
        </NavLink>
    );

    const [toastBody, setToastBody] = React.useState("test");
    const [showToast, setShowToast] = React.useState(false);

    const [trainingsgroups, setTrainingsgroups] = React.useState([]);
    const [membershipPriceModels, setMembershipPriceModels] = React.useState(
        []
    );
    const [positions, setPositions] = React.useState([]);

    React.useEffect(() => {
        if (loggedIn) {
            apiClient
                .get("/api/trainingsgroup")
                .then((response) => {
                    setTrainingsgroups(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (checkLogin(error)) {
                    } else {
                        console.error(error);
                    }
                });

            apiClient
                .get("/api/membershipprice")
                .then((response) => {
                    setMembershipPriceModels(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (checkLogin(error)) {
                    } else {
                        console.error(error);
                    }
                });

            apiClient
                .get("/api/position")
                .then((response) => {
                    setPositions(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (checkLogin(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [loggedIn]); //use loggedIn as dependency - when loggedIn changes, re-render component

    return (
        <Router>
            <ToastHelper
                body={toastBody}
                show={showToast}
                setShowToast={setShowToast}
            ></ToastHelper>

            <Switch>
                <Route
                    exact
                    path="/"
                    render={(props) => (
                        <Homepage
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />
                <Route
                    exact
                    path="/mitglieder"
                    render={(props) => (
                        <Members
                            {...props}
                            fluidContent={true}
                            loggedIn={loggedIn}
                            authLink={authLink}
                        />
                    )}
                />
                <Route
                    exact
                    path="/mitglieder/:slug"
                    render={(props) => (
                        <Member
                            {...props}
                            fluidContent={true}
                            authLink={authLink}
                            loggedIn={loggedIn}
                            backlink={
                                <Backlink
                                    link="/mitglieder"
                                    text="Zurück zu allen Mitgliedern"
                                />
                            }
                        />
                    )}
                />

                <Route
                    exact
                    path="/trainer"
                    render={(props) => (
                        <Coaches
                            {...props}
                            fluidContent={true}
                            authLink={authLink}
                            loggedIn={loggedIn}
                        />
                    )}
                />
                <Route
                    exact
                    path="/trainer/:slug"
                    render={(props) => (
                        <Member
                            {...props}
                            fluidContent={true}
                            authLink={authLink}
                            loggedIn={loggedIn}
                            backlink={
                                <Backlink
                                    link="/trainer"
                                    text="Zurück zu allen Trainern"
                                />
                            }
                        />
                    )}
                />

                <Route
                    exact
                    path="/vorstand"
                    render={(props) => (
                        <Vorstand
                            {...props}
                            fluidContent={true}
                            authLink={authLink}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/vereinsgeschichte"
                    render={(props) => (
                        <Clubhistory
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/mehr-vom-leben"
                    render={(props) => (
                        <MehrVomLeben
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/trainingszeiten"
                    render={(props) => (
                        <Hours
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/mitgliedsbeitraege"
                    render={(props) => (
                        <Pricing
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/taekwondo"
                    render={(props) => (
                        <Taekwondo
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/taekwondo/geschichte"
                    render={(props) => (
                        <TaekwondoHistory
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/dojang"
                    render={(props) => (
                        <Dojang
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/dobok"
                    render={(props) => (
                        <Dobok
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/poomsae"
                    render={(props) => (
                        <Poomsae
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/hosinsul"
                    render={(props) => (
                        <Hosinsul
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/kyokpa"
                    render={(props) => (
                        <Kyokpa
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/kyorugi"
                    render={(props) => (
                        <Kyorugi
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                <Route
                    exact
                    path="/kontakt"
                    render={(props) => (
                        <Contact
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />
                <Route
                    exact
                    path="/impressum"
                    render={(props) => (
                        <Imprint
                            {...props}
                            authLink={authLink}
                            fluidContent={true}
                            loggedIn={loggedIn}
                        />
                    )}
                />

                {/*
                 * COACH SECTIONS
                 */}

                <Route
                    exact
                    path="/coach"
                    render={(props) => (
                        <Coach
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/mitglieder"
                    render={(props) => (
                        <CoachMembers
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                            trainingsgroups={trainingsgroups}
                            membershipPriceModels={membershipPriceModels}
                        />
                    )}
                />
                <Route
                    path="/coach/mitglieder/:id"
                    render={(props) => (
                        <CoachMember
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            trainingsgroups={trainingsgroups}
                            membershipPriceModels={membershipPriceModels}
                            positions={positions}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/trainingssessions"
                    render={(props) => (
                        <CoachTrainingssessions
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />
                <Route
                    path="/coach/trainingssession/:id"
                    render={(props) => (
                        <CoachTrainingssession
                            {...props}
                            loggedIn={loggedIn}
                            setShowToast={setShowToast}
                            setToastBody={setToastBody}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/trainingssessions-sum"
                    render={(props) => (
                        <CoachTrainingssessionMembersSum
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/pruefungen"
                    render={(props) => (
                        <CoachGradings
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    path="/coach/pruefungen/:id"
                    render={(props) => (
                        <CoachGrading
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/meisterschaften"
                    render={(props) => (
                        <CoachCompetitions
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/meisterschaften/:id"
                    render={(props) => (
                        <CoachCompetition
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    exact
                    path="/coach/teams"
                    render={(props) => (
                        <CoachCompetitionTeams
                            {...props}
                            loggedIn={loggedIn}
                            checkAuthError={checkLogin}
                            authLink={authLink}
                        />
                    )}
                />

                <Route
                    path="/login"
                    render={(props) => (
                        <Login {...props} login={login} fluidContent={true} />
                    )}
                />

                <Route path="*" render={(props) => <Error404 {...props} />} />
            </Switch>
            <Footer loggedIn={loggedIn} />
        </Router>
    );
};

export default App;
