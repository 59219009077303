import React from "react";

class TimeHelper extends React.Component {
    static noUTCDate = (date) => {
        if (!date) {
            return null;
        }
        return new Date(
            date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
        );
    };

    static formatDate = (
        dateString,
        options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        }
    ) => {
        if (dateString) {
            let date = new Date(dateString);
            return date.toLocaleDateString("de-DE", options);
        }
    };
}

export default TimeHelper;
