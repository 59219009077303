import React, { useState } from "react";

import apiClient from "../../services/api";

import withCoachPanel from "../../withCoachPanel";

import { Button, Form, Col, Row, Spinner, Table } from "react-bootstrap";
import { MdPeople } from "react-icons/md";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachTrainingssessions = (props) => {
    /*
     *   Show Traning Sessions
     */
    const [trainingssessions, setTrainingssessions] = React.useState([]);
    const [updateSwitch, setUpdateSwitch] = React.useState(false);

    React.useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/trainingssession")
                .then((response) => {
                    let sessions = response.data;
                    sessions.sort(function (a, b) {
                        var dateA = new Date(a.date);
                        var dateB = new Date(b.date);
                        if (dateA < dateB) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    setTrainingssessions(sessions);
                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const trainingssessionList = trainingssessions.map((trainingssession) => {
        var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        var date = new Date(trainingssession.date);
        var countPresentMembersText = "";
        if (trainingssession.presentMembersCount) {
            countPresentMembersText =
                " " + trainingssession.presentMembersCount + " logged in";
        }
        return (
            <tr key={trainingssession.id}>
                <td>{date.toLocaleDateString("de-DE", options)}</td>
                <td>
                    <Form.Group controlId={trainingssession.id}>
                        <Form.Check
                            type="checkbox"
                            label=""
                            checked={trainingssession.additionalSession}
                            //onChange={handleChangeCheckboxMemberPresence}
                        />
                    </Form.Group>
                </td>
                <td>
                    <Button
                        href={"/coach/trainingssession/" + trainingssession.id}
                        size="sm"
                    >
                        <MdPeople />
                    </Button>
                    {countPresentMembersText}
                </td>
            </tr>
        );
    });

    /*
     *   Add Traning Session
     */
    const date = new Date();
    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    const [newDate, setNewDate] = useState(isoDate);
    const [additionalSession, setAdditionalSession] = useState(false);

    const addSessionIsLoading = false;

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = {
            date: newDate,
            additionalSession: additionalSession,
        };

        apiClient
            .post("/api/trainingssession", data)
            .then((response) => {
                console.log(response.data);
                setUpdateSwitch(!updateSwitch);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    const handleChangeCheckboxAdditionalSession = (evt) => {
        setAdditionalSession(evt.target.checked);
    };

    if (props.loggedIn) {
        return (
            <div class="mt-3 mt-md-5">
                <h1>Anwesenheit</h1>

                <h3>Add new session</h3>
                <Form id="trainingssession-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <DatePicker
                                selected={newDate}
                                onChange={(date) => setNewDate(date)}
                                dateFormat="d. MMMM yyyy"
                                locale="de"
                                className="form-control"
                                calendarStartDay={1}
                            />
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Button
                                type="submit"
                                //disabled={this.state.submitButtonDisabled}
                            >
                                {addSessionIsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    "Add session"
                                )}
                            </Button>
                        </Col>
                        <Form.Group controlId="additionalSession">
                            <Form.Check
                                type="checkbox"
                                label="Additional session"
                                checked={additionalSession}
                                onChange={handleChangeCheckboxAdditionalSession}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <h3>Anwesenheit</h3>
                <div className="list-group mb-4">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Additional Session</th>
                                <th>Manage Members</th>
                            </tr>
                        </thead>
                        <tbody>{trainingssessionList}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachTrainingssessionsWithCoachPanel = withCoachPanel(
    CoachTrainingssessions
);
export default CoachTrainingssessionsWithCoachPanel;
