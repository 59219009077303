import React from "react";

import { Container } from "react-bootstrap";
import Navigation from "./components/Navigation";

function withPublicPanel(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <>
                    <Container fluid={this.props.fluidNav}>
                        <Navigation
                            loggedIn={this.props.loggedIn}
                            authLink={this.props.authLink}
                        />
                    </Container>
                    <Container fluid={this.props.fluidContent}>
                        <WrappedComponent {...this.props} />
                    </Container>
                </>
            );
        }
    };
}

export default withPublicPanel;
