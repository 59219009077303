import React from "react";

import { Table, Form, Container, Row, Col, Button } from "react-bootstrap";

import SteffiDwitDollyo from "../../images/content/20220716-steffi-dwitdollyo-bruchtest.jpg";

const WhyTaekwondo = (props) => {
    return (
        <>
            <div class=" bg-light position-relative">
                <Container fluid>
                    <Row className="min-vh-100 align-items-center justify-content-between bg-cloud">
                        <Col
                            lg={{ span: 5, offset: 1 }}
                            xl={4}
                            className="my-5 my-lg-3 mb-lg-10 mb-xl-20"
                        >
                            <h2 className="h1 mb-3">
                                <span class="text-primary">Warum</span>{" "}
                                Taekwondo?
                            </h2>
                            <p>
                                Taekwondo fördert durch das Training von Kraft,
                                Ausdauer, Koordination und Beweglichkeit nicht
                                nur das körperliche Wohlbefinden, sondern stellt
                                auch eine Art Lebensschule im geistigen Sinne
                                dar. Die zur Ausübung von Taekwondo
                                erforderliche Konzentration und Disziplin
                                erlaubt es den Alltag auszublenden und formt die
                                Persönlichkeit bis zur Überwindung des eigenen
                                „Ichs“.
                            </p>
                            <p>
                                Durch das ganzkörperliche Training und vor allem
                                die Taekwondotypischen Beintechniken kann
                                außerdem die Beweglichkeit gesteigert und
                                Haltungsschäden vorgebeugt werden. Taekwondo ist
                                also ein hervorragender Ausgleich zum Alltag für
                                alle Altersgruppen.
                            </p>

                            <p className="text-right mt-5">
                                <Button variant="secondary" href="/taekwondo">
                                    Mehr über Taekwondo
                                </Button>
                            </p>
                        </Col>
                        <Col lg={5} xl={6} className="no-overflow">
                            <div class="me-neggrid position-relative">
                                <div class="position-absolute bg-dark h-100 w-100 ms-10 content-box" />
                                <div class="position-relative py-5">
                                    <img
                                        className="img-fluid"
                                        src={SteffiDwitDollyo}
                                        alt="Pandalchagi auf Schlagpolster"
                                    />
                                </div>
                                <div class="w-100 my-6 mx-5 border-white border position-absolute top-0 bottom-0" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div class="bg-crosses vh-25 w-100 position-absolute bottom-0" />
            </div>
            {/*
            <div class="vh-50 position-relative mt-n20 d-none d-lg-block">
                <div class="h-100 bg-cloud "></div>
            </div>
            */}

            {/* this is only to move the lightgrey background unerneath the lower part of the cloud. */}
            <div class="bg-light pt-15 mt-n15 pt-xl-25 mt-xl-n25 d-none d-lg-block" />
        </>
    );
};

export default WhyTaekwondo;
