import React from "react";

import { Container, Form, Spinner, Row, Col, Button } from "react-bootstrap";

import LuisePandal from "../../images/luise-pandalchagi.jpg";
import LehrgangShin from "../../images/highlights/IMG_6829.jpg";
import Trainingslager from "../../images/highlights/IMG_9107.jpg";
import Grillfeier from "../../images/highlights/grillfeier.jpg";
import Meisterschaften from "../../images/highlights/20191130-steirische-lm-36.jpg";

const HighlightsOfTheYear = (props) => {
    let trainerList = [{ image: LuisePandal }, { image: LuisePandal }];

    while (trainerList.length < 6) {
        trainerList = trainerList.concat(trainerList);
    }

    const trainerSlides = trainerList.map((trainer) => {
        return (
            <li class="uk-width-1-1 uk-width-1-2@m uk-width-1-5@l">
                <img src={trainer.image} class="img-fluid" />
            </li>
        );
    });

    var xpos = 0,
        ypos = 0;

    var translX = 0,
        translY = 0;

    const translateObject = (e) => {
        /*
        let newx = e.clientX,
            newy = e.clientY;

        if (xpos === 0) {
            xpos = e.clientX;
        }
        if (ypos === 0) {
            ypos = e.clientY;
        }

        let diffx = xpos - newx,
            diffy = ypos - newy;

        translX = translX + diffx;
        translY = translY + diffy;

        e.currentTarget.style.transform =
            "translate(" +
            translX.toString() +
            "px, " +
            translY.toString() +
            "px)";

        xpos = newx;
        ypos = newy;
            */
    };

    const resetPos = (e) => {
        xpos = 0;
        ypos = 0;

        e.currentTarget.style.transform = "translate(0, 0)";

        translX = 0;
        translY = 0;
    };

    return (
        <div class="bg-pattern py-5 py-xl-1">
            <Container className="text-white">
                <Row className="min-vh-75 align-items-center">
                    <Col lg={6} className="mt-5 mb-5 mb-lg-7">
                        <h2 class="h1 mb-4 text-white">Highlights</h2>
                        <div class="pe-lg-10 pe-xl-15">
                            <p>
                                Zu den Fixpunkten im Kwonbaek-Kalender zählen
                                unser Trainingslager am Wörthersee sowie unsere
                                Grillfeier und Weihnachtsfeier. Außerdem nehmen
                                wir mit einem kleinen Wettkampfteam regelmäßig
                                an Turnieren und Landesmeisterschaften teil. Es
                                gibt auch immer wieder Seminare, Lehrgänge und
                                Landesverbandstrainings. Es wird also nicht so
                                schnell langweilig bei uns!
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} className="">
                        <Row className="justify-content-between">
                            <Col xs={6} lg={4} className="mt-5">
                                <div class="zoom">
                                    <div
                                        class="translateOnHover"
                                        onMouseMove={translateObject}
                                        onMouseOut={resetPos}
                                    >
                                        <img
                                            src={LehrgangShin}
                                            alt="Wettkampflehrgang bei GM Shin, Graz"
                                            class="img-fluid"
                                        />
                                        <p class="fst-italic">
                                            Seminare &amp; Lehrgänge
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} lg={3}>
                                <div class="zoom">
                                    <div
                                        class="translateOnHover"
                                        onMouseMove={translateObject}
                                        onMouseOut={resetPos}
                                    >
                                        <img
                                            src={Grillfeier}
                                            alt="Grillfeier"
                                            class="img-fluid"
                                        />
                                        <p class="fst-italic">
                                            Grillfeier, Weihnachtsfeier
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={6}
                                lg={4}
                                className="mt-n4 mt-lg-n10 order-lg-last"
                            >
                                <div class="zoom">
                                    <div
                                        class="translateOnHover"
                                        onMouseMove={translateObject}
                                        onMouseOut={resetPos}
                                    >
                                        <img
                                            src={Trainingslager}
                                            alt="Trainingslager am Wörthersee"
                                            class="img-fluid"
                                        />
                                        <p class=" fst-italic">
                                            Trainingslager
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={6}
                                lg={5}
                                className="ms-lg-n6 me-lg-5 mt-5 order-lg-first"
                            >
                                <div class="zoom">
                                    <div
                                        class="translateOnHover"
                                        onMouseMove={translateObject}
                                        onMouseOut={resetPos}
                                    >
                                        <img
                                            src={Meisterschaften}
                                            alt="Turniere &amp; Meisterschaften"
                                            class="img-fluid"
                                        />
                                        <p class=" fst-italic">
                                            Meisterschaften
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HighlightsOfTheYear;
