import React from "react";

import { Figure } from "react-bootstrap";

const Fig = (props) => {
    return (
        <Figure className={props.figureClass}>
            <Figure.Image
                alt={props.alt}
                src={props.src}
                className={props.imageClass}
            />
            <Figure.Caption className={props.captionClass}>
                {props.caption}
            </Figure.Caption>
        </Figure>
    );
};

export default Fig;
