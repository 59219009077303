import axios from "axios";

const apiClient = axios.create({
    //baseURL: "https://api.kwonbaek.test",
    //baseURL: "https://api.krauthos.at",
    baseURL: "https://api.kwonbaek.com",
    withCredentials: true,
});

export default apiClient;
