const checkLogin = (statuscode) => {
    if (statuscode === 401) {
        //browserHistory.push("/login");
        sessionStorage.setItem("loggedIn", false);
        console.log("checklogin");

        return true;
    } else {
        if (statuscode.response && statuscode.response.status === 401) {
            sessionStorage.setItem("loggedIn", false);
            console.log("checklogin");

            return true;
        }
    }
};

export default checkLogin;
