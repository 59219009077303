import React from "react";

import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

import LogoBlack from "../images/logo.svg";
import LogoWhite from "../images/logo-white.svg";

const Navigation = (props) => {
    var navLinks = [];

    const getNavItem = (name, link) => {
        return (
            <NavLink to={link} className="nav-item">
                {name}
            </NavLink>
        );
    };

    const getDropdownItem = (name, link) => {
        return (
            <NavDropdown.Item href={link} className="nav-item">
                {name}
            </NavDropdown.Item>
        );
    };

    navLinks.push(
        <NavDropdown title="Verein" id="VereinDropdown">
            {getDropdownItem("Mitglieder", "/mitglieder")}
            {getDropdownItem("Trainer", "/trainer")}
            {getDropdownItem("Vorstand", "/vorstand")}
            {getDropdownItem("Vereinschronik", "/vereinsgeschichte")}
            {getDropdownItem("Mehr vom Leben", "/mehr-vom-leben")}
        </NavDropdown>
    );

    navLinks.push(
        <NavDropdown title="Training" id="TrainingDropdown">
            {getDropdownItem("Zeit & Ort", "/trainingszeiten")}
            {getDropdownItem("Preise", "/mitgliedsbeitraege")}
        </NavDropdown>
    );

    navLinks.push(
        <NavDropdown title="Taekwondo" id="TaekwondoDropdown">
            {getDropdownItem("Über Taekwondo", "/taekwondo")}
            {getDropdownItem("Geschichte", "/taekwondo/geschichte")}
            {getDropdownItem("Dojang", "/dojang")}
            {getDropdownItem("Dobok", "/dobok")}
            {getDropdownItem("Poomsae", "/poomsae")}
            {getDropdownItem("Kyorugi", "/kyorugi")}
            {getDropdownItem("Hosinsul", "/hosinsul")}
            {getDropdownItem("Kyokpa", "/kyokpa")}
        </NavDropdown>
    );

    navLinks.push(getNavItem("Kontakt", "/kontakt"));

    if (props.loggedIn) {
        navLinks.push(getNavItem("Coach", "/coach"));
    }

    const Logo = props.bg === "primary" ? LogoWhite : LogoBlack;

    return (
        <Navbar expand="lg" bg={props.bg} variant={props.variant} sticky="top">
            <Navbar.Brand href="/">
                <img
                    src={Logo}
                    alt="Kwonbaek Vorau"
                    width="190.52px"
                    height="50px"
                />
            </Navbar.Brand>

            {/*Desktop Nav */}
            <Nav className="ms-auto d-none d-lg-flex navbar">
                {navLinks} {props.authLink}
            </Nav>

            {/*Offcanvas for mobile */}
            <Navbar.Toggle aria-controls="offcanvas" />
            <Navbar.Offcanvas
                id="offcanvas"
                className="justify-content-end"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="align-items-lg-center">
                        {navLinks}
                        <div class="mt-5" />
                        {props.authLink}
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    );
};

export default Navigation;
