import React from "react";

import apiClient from "../../services/api";

import GradingsHelper from "../helpers/GradingsHelper";
import MemberHelper from "../helpers/MemberHelper";
import TimeHelper from "../helpers/TimeHelper";

import { IMG_BASE_URL } from "../../constants";
import UnknownMember from "../../images/unknown_member.jpg";

import { Spinner, Row, Col, Container, Table } from "react-bootstrap";

import withPublicPanel from "../../withPublicPanel";
import MemberAchievements from "./MemberAchievements";

import Error from "../Error";

const Member = (props) => {
    const [member, setMember] = React.useState({
        gradings: [],
        memberships: [],
        achievements: { single: [], team: [] },
        qualifications: [],
        funktionen: [],
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        var { slug } = props.match.params;

        apiClient
            .get("/api/member/" + slug)
            .then((response) => {
                setMember(response.data);
                console.log(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                console.log(error.response);
                setIsLoading(false);
                setError({
                    status: error.response.status,
                    data: error.response.data,
                    responseURL: error.response.request.responseURL,
                });
            });
    }, []);

    const gradingsHeaderRow = GradingsHelper.sortGradings(member.gradings).map(
        (grading) => {
            return (
                <td>
                    <div class="pb-0 ps-0 pe-4">{grading.grade}</div>
                </td>
            );
        }
    );

    const gradingsDateRow = GradingsHelper.sortGradings(member.gradings).map(
        (grading) => {
            return (
                <td>
                    <small class="pt-0 ps-0 pe-4">
                        {TimeHelper.formatDate(grading.date, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        })}
                    </small>
                </td>
            );
        }
    );

    const numMedals =
        member.achievements.single.length + member.achievements.team.length;

    return (
        <>
            {isLoading ? (
                <div class="text-center">
                    <Spinner animation="border" />
                </div>
            ) : error ? (
                <Error error={error} />
            ) : (
                <>
                    <Row className="bg-blue-animated py-5 text-white">
                        <Col>
                            <Container>
                                <small>{props.backlink}</small>

                                <div class="d-md-none mt-5">
                                    <Row>
                                        <Col xs={4}>
                                            <img
                                                src={
                                                    member.avatar_url
                                                        ? IMG_BASE_URL +
                                                          member.avatar_url
                                                        : UnknownMember
                                                }
                                                alt={
                                                    member.firstname +
                                                    " " +
                                                    member.lastname
                                                }
                                                class="img-fluid"
                                            />
                                        </Col>
                                        <Col>
                                            <h1 className="my-1 h3">
                                                {member.firstname}{" "}
                                                {member.lastname}
                                                <span class="d-none d-md-inline-block">
                                                    ,{" "}
                                                    {
                                                        GradingsHelper.getMaxGrading(
                                                            member.gradings
                                                        ).grade
                                                    }
                                                </span>
                                                <div class="d-md-none h5">
                                                    {
                                                        GradingsHelper.getMaxGrading(
                                                            member.gradings
                                                        ).grade
                                                    }
                                                </div>
                                            </h1>
                                            <p>
                                                <small>
                                                    Mitglied seit:
                                                    <br />
                                                    {MemberHelper.memberSince(
                                                        member.memberships
                                                    )}
                                                </small>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>

                                <div class="text-center mb-5 d-none d-md-block">
                                    <h1 className="mb-1">
                                        {member.firstname} {member.lastname}
                                        <span class="d-none d-md-inline-block">
                                            ,{" "}
                                            {
                                                GradingsHelper.getMaxGrading(
                                                    member.gradings
                                                ).grade
                                            }
                                        </span>
                                        <div class="d-md-none h4">
                                            {
                                                GradingsHelper.getMaxGrading(
                                                    member.gradings
                                                ).grade
                                            }
                                        </div>
                                    </h1>
                                    <p>
                                        Mitglied seit:{" "}
                                        {MemberHelper.memberSince(
                                            member.memberships
                                        )}
                                    </p>
                                </div>
                                <Row className="justify-content-between mb-5">
                                    <Col
                                        md={4}
                                        xl={3}
                                        className="d-none d-md-block"
                                    >
                                        <img
                                            src={
                                                member.avatar_url
                                                    ? IMG_BASE_URL +
                                                      member.avatar_url
                                                    : UnknownMember
                                            }
                                            alt={
                                                member.firstname +
                                                " " +
                                                member.lastname
                                            }
                                            class="img-fluid"
                                        />
                                    </Col>
                                    <Col md={7} xl={8} className="offset-md-1">
                                        <Row>
                                            <Col
                                                xs={12}
                                                className="mt-4 mt-md-0"
                                            >
                                                {
                                                    //Prüfungen
                                                    member.gradings.length >
                                                    0 ? (
                                                        <>
                                                            <h2 class="display-5">
                                                                Prüfungen
                                                            </h2>
                                                            <Table
                                                                responsive
                                                                className="table-borderless text-nowrap text-white"
                                                            >
                                                                <tr>
                                                                    {
                                                                        gradingsHeaderRow
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    {
                                                                        gradingsDateRow
                                                                    }
                                                                </tr>
                                                            </Table>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            </Col>

                                            {
                                                //Ausbildungen
                                            }
                                            {member.qualifications.length >
                                            0 ? (
                                                <Col
                                                    md={6}
                                                    className="mt-4 mt-md-6"
                                                >
                                                    <>
                                                        <h2 class="display-5">
                                                            Ausbildungen
                                                        </h2>
                                                        {member.qualifications.map(
                                                            (qualification) => (
                                                                <p>
                                                                    {
                                                                        qualification.name
                                                                    }
                                                                    <br />
                                                                    <small>
                                                                        {TimeHelper.formatDate(
                                                                            qualification.start_date
                                                                        )}
                                                                    </small>
                                                                </p>
                                                            )
                                                        )}
                                                    </>
                                                </Col>
                                            ) : (
                                                ""
                                            )}

                                            {
                                                //Funktionen
                                            }
                                            {member.funktionen.length > 0 ? (
                                                <Col
                                                    md={6}
                                                    className="mt-4 mt-md-6"
                                                >
                                                    <h2 class="display-5">
                                                        Funktionen
                                                    </h2>
                                                    {member.funktionen.map(
                                                        (funktion) => (
                                                            <p>
                                                                {funktion.name}
                                                                <br />
                                                                <small>
                                                                    {funktion.end_date ==
                                                                    null
                                                                        ? "seit "
                                                                        : ""}
                                                                    {TimeHelper.formatDate(
                                                                        funktion.start_date
                                                                    )}

                                                                    {funktion.end_date
                                                                        ? " bis " +
                                                                          TimeHelper.formatDate(
                                                                              funktion.end_date
                                                                          )
                                                                        : ""}
                                                                </small>
                                                            </p>
                                                        )
                                                    )}
                                                </Col>
                                            ) : (
                                                ""
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    <div class="bg-crosses vh-25 w-100 position-absolute bottom-0 d-none d-md-block" />

                    <Row className="bg-black-animated pt-5 pb-6 text-center text-white">
                        <Col>
                            <h3 class="text-center mb-5">#FACTS</h3>

                            <Container fluid>
                                <Row>
                                    <Col
                                        xs={6}
                                        lg={4}
                                        className="mb-5 px-lg-3 order-lg-1"
                                    >
                                        {numMedals > 0 ? (
                                            <span class="display-1 text-stroke">
                                                {numMedals}
                                            </span>
                                        ) : (
                                            <>
                                                <span class="display-1 text-stroke">
                                                    &nbsp;
                                                </span>
                                                <br />
                                                Noch keine Meisterschaftsdaten
                                                verfügbar
                                            </>
                                        )}
                                        <br />
                                        <small>
                                            Medaillen bei Meisterschaften
                                        </small>
                                    </Col>
                                    <Col
                                        xs={6}
                                        lg={4}
                                        className="mb-5 px-lg-3 order-lg-3"
                                    >
                                        <span class="display-1 text-stroke">
                                            {member.numTrainingssessions}
                                        </span>
                                        <br />
                                        <small>
                                            Vereinstrainings absolviert
                                        </small>
                                    </Col>
                                    <Col lg={4} className="order-lg-2">
                                        <span class="display-1 text-stroke">
                                            {member.favouriteMove}
                                        </span>
                                        <br />
                                        {member.favouriteMove && (
                                            <small>Signature Move</small>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    <MemberAchievements achievements={member.achievements} />
                </>
            )}
        </>
    );
};

const MemberWithPublicPanel = withPublicPanel(Member);
export default MemberWithPublicPanel;
