import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

const CoachCompetitionResultAddSingle = (props) => {
    const [addResultIsLoading, setAddResultIsLoading] = React.useState(false);

    const [newSingleResult, setNewSingleResult] = React.useState({});

    const handleSubmit = (event) => {
        event.preventDefault();

        setAddResultIsLoading(true);

        var data = { ...newSingleResult, competition: props.competition_id };

        apiClient
            .post("/api/competition_member", data)
            .then((response) => {
                console.log(response.data);
                props.switchUpdateSwitch();
                //reset form
                setNewSingleResult({
                    member: "",
                    class: "",
                    result: "",
                    num_opponents: "",
                });
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setAddResultIsLoading(false);
            });
    };

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewSingleResult({
            ...newSingleResult,
            [evt.target.name]: value,
        });
    }

    const memberSelect = props.members.map((member) => (
        <option value={member.id}>
            {member.lastname}, {member.firstname}
        </option>
    ));

    const classesSelect = props.classes.map((cls) => (
        <option value={cls.id}>{cls.name}</option>
    ));

    if (props.loggedIn) {
        return (
            <>
                <Form id="competition-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Sportler</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleChange}
                                name="member"
                                value={newSingleResult.member}
                            >
                                <option value="0"></option>
                                {memberSelect}
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Klasse</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleChange}
                                name="class"
                                value={newSingleResult.class}
                            >
                                <option value="0"></option>
                                {classesSelect}
                            </Form.Control>
                        </Col>
                        <Col xs={6} md={3} lg={2} className="mt-3 mb-1">
                            <Form.Label>Platzierung</Form.Label>
                            <Form.Control
                                type="text"
                                name="result"
                                value={newSingleResult.result}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={6} md={3} lg={2} className="mt-3 mb-1">
                            <Form.Label>Anzahl Gegner</Form.Label>
                            <Form.Control
                                type="text"
                                name="num_opponents"
                                value={newSingleResult.num_opponents}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} className="mt-3 mb-1">
                            <Button type="submit">
                                {addResultIsLoading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    "Ergebnis hinzufügen"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachCompetitionResultAddSingle;
