import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { FiFacebook, FiInstagram } from "react-icons/fi";

import withPublicPanel from "../withPublicPanel";

import Juergen from "../images/content/20180721-kuppruefung-31.jpg";

const Contact = (props) => {
    return (
        <>
            <Row className="bg-light justify-content-center pt-5 pt-md-7">
                <Col lg="10" xxl="6" className="px-xl-6">
                    <Container className="mb-5 mb-md-6">
                        <Row className="justify-content-center">
                            <Col md="6">
                                <h1>Kontakt</h1>
                                <p>
                                    <a href="mailto:office@kwonbaek.com">
                                        office@kwonbaek.com
                                    </a>
                                </p>
                                <p>
                                    <small class="text-muted">
                                        Obmann Bernd Kraussler
                                    </small>
                                    <br />
                                    +43 676 3960153
                                </p>
                                <p>
                                    <span class="me-2">
                                        <a
                                            href="https://fb.com/kwonbaekvorau"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FiFacebook />
                                        </a>
                                    </span>
                                    <span class="me-3">
                                        <a
                                            href="https://instagram.com/kwonbaekvorau"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FiInstagram />
                                        </a>
                                    </span>
                                    <small>#teamkwonbaek</small>
                                </p>
                            </Col>

                            <Col md="6" className="mt-6 mt-md-0">
                                <h2 class="h1">Schnuppertraining</h2>

                                <Row>
                                    <Col xl="6">
                                        <p>
                                            <small class="text-muted">
                                                Obmann Bernd Kraussler
                                            </small>
                                            <br />
                                            +43 676 3960153
                                        </p>
                                        <p>
                                            <small class="text-muted">
                                                oder
                                            </small>
                                        </p>
                                        <p>
                                            <small class="text-muted">
                                                Christian Koller
                                            </small>
                                            <br />
                                            +43 660 5742098
                                        </p>
                                    </Col>
                                    <Col>
                                        <p>
                                            <small class="text-muted">
                                                oder per E-mail
                                            </small>
                                            <br />
                                            <a href="mailto:office@kwonbaek.com">
                                                office@kwonbaek.com
                                            </a>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

const ContactWithPublicPanel = withPublicPanel(Contact);
export default ContactWithPublicPanel;
