import React from "react";

import apiClient from "../../services/api";

import CoachGradingAdd from "./CoachGradingAdd";

import { Table, Button, Spinner } from "react-bootstrap";
import { MdNavigateBefore, MdClear } from "react-icons/md";

import withCoachPanel from "../../withCoachPanel";

const CoachGrading = (props) => {
    /*
     *   Show Single Traning Session
     */

    const [grading, setGrading] = React.useState({ gradings: [] });
    const [updateSwitch, setUpdateSwitch] = React.useState(false);
    const switchUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };
    const [sortGradingsSwitch, setSortGradingsSwitch] = React.useState(false);
    const switchSortGradingSwitch = () => {
        setSortGradingsSwitch(!sortGradingsSwitch);
    };

    const [members, setMembers] = React.useState([]);
    const [beltGrades, setBeltGrades] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (props.loggedIn) {
            apiClient
                .get("/api/member")
                .then((response) => {
                    setMembers(response.data);
                    console.log("members:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });

            apiClient
                .get("/api/beltgrade")
                .then((response) => {
                    setBeltGrades(response.data);
                    console.log("beltGrades:", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn]); //use loggedIn as dependency - when loggedIn changes, re-render component

    const sortGradings = () => {
        if (!grading.gradings.length) return;

        let copiedGrading = JSON.parse(JSON.stringify(grading));

        copiedGrading.gradings.sort((a, b) => {
            let aGradeOrder = getValueById(
                beltGrades,
                a.belt_grade_id,
                "order"
            );
            let bGradeOrder = getValueById(
                beltGrades,
                b.belt_grade_id,
                "order"
            );

            if (members.length) {
                //same belt -> alphabetical order
                if (aGradeOrder === bGradeOrder) {
                    let memberA = members.find((x) => x.id === a.member_id);
                    let memberB = members.find((x) => x.id === b.member_id);

                    if (memberA && memberB) {
                        return memberA.lastname.localeCompare(memberB.lastname);
                    }
                }
            }

            if (aGradeOrder < bGradeOrder) {
                return 1;
            } else {
                return -1;
            }
        });
        setGrading(copiedGrading);
    };

    React.useEffect(() => {
        sortGradings();
    }, [sortGradingsSwitch, members, beltGrades]);

    React.useEffect(() => {
        var { id } = props.match.params;

        if (props.loggedIn) {
            apiClient
                .get("/api/gradingdate/" + id)
                .then((response) => {
                    setGrading(response.data);
                    switchSortGradingSwitch();
                    console.log("gradingdate: ", response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]);

    const date = (grading) => {
        if (grading) {
            var options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            var date = new Date(grading.date);
            return <span>{date.toLocaleDateString("de-DE", options)}</span>;
        }
    };

    const getValueById = (arr, id, key) => {
        let obj = arr.find((x) => x.id === id);
        if (obj) {
            return obj[key];
        }
    };

    const handleRemoveGrading = (event) => {
        setIsLoading(true);
        var targetId = event.target.id
            ? event.target.id
            : event.target.parentNode.id;

        console.log(targetId, event.target);

        apiClient
            .delete("/api/grading/" + targetId)
            .then((response) => {
                console.log(response.data);
                switchUpdateSwitch();
                setIsLoading(false);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
                setIsLoading(false);
            });
    };

    var gradingsList = grading.gradings.map((grad) => (
        <tr key={grad.id}>
            <td>{getValueById(members, grad.member_id, "lastname")}</td>
            <td>{getValueById(members, grad.member_id, "firstname")}</td>
            <td>{getValueById(beltGrades, grad.belt_grade_id, "grade")}</td>
            <td>
                {!isLoading ? (
                    <Button
                        variant="danger"
                        size="sm"
                        id={grad.id}
                        onClick={!isLoading ? handleRemoveGrading : null}
                    >
                        <MdClear id={grad.id} />
                    </Button>
                ) : (
                    <Spinner animation="border" />
                )}
            </td>
        </tr>
    ));

    if (props.loggedIn) {
        var header = <h1>Prüfungen am {date(grading)}</h1>;
        var gradingsTable = (
            <div class="mb-3">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Nachname</th>
                            <th>Vorname</th>
                            <th>Graduierung</th>
                            <th>Prüfung löschen</th>
                        </tr>
                    </thead>
                    <tbody>{gradingsList}</tbody>
                </Table>
            </div>
        );

        return (
            <div className="mt-3 mt-md-5 mb-5">
                <a href="/coach/pruefungen">
                    <MdNavigateBefore /> Zurück zu allen Prüfungen
                </a>
                {header} {gradingsTable}
                <CoachGradingAdd
                    {...props}
                    switchUpdateSwitch={switchUpdateSwitch}
                    grading={grading}
                    members={members}
                    beltGrades={beltGrades}
                ></CoachGradingAdd>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachGradingWithCoachPanel = withCoachPanel(CoachGrading);
export default CoachGradingWithCoachPanel;
