import React from "react";

import { NavLink } from "react-router-dom";
import { Nav, Col } from "react-bootstrap";

const CoachNavigation = (props) => {
    var navLinks = [];

    const getNavItem = (name, link) => {
        return (
            <NavLink to={link} className="nav-item">
                {name}
            </NavLink>
        );
    };

    if (props.loggedIn) {
        navLinks.push(
            getNavItem("Trainingseinheiten", "/coach/trainingssessions-sum")
        );
    }

    return (
        <Col className="mt-3 mt-md-5">
            <Nav defaultActiveKey="/home" className="flex-column secondary-nav">
                <Nav.Item className="nav-header-2">Mitgliederbereich</Nav.Item>

                {navLinks}
            </Nav>
        </Col>
    );
};

export default CoachNavigation;
