import React from "react";

import apiClient from "../../services/api";

import GradingsHelper from "../helpers/GradingsHelper";
import MemberHelper from "../helpers/MemberHelper";

import withCoachPanel from "../../withCoachPanel";

import { Table, Form, Spinner, Row, Col, Button } from "react-bootstrap";
import { MdNavigateBefore, MdClear } from "react-icons/md";

import CoachMemberMemberships from "./CoachMemberMemberships";
import CoachMemberGradings from "./CoachMemberGradings";
import CoachMemberPositions from "./CoachMemberPositions";

import AvatarSelect from "./AvatarSelect";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachMember = (props) => {
    const [member, setMember] = React.useState({
        gradings: [],
        memberships: [],
    });
    const [updateSwitch, setUpdateSwitch] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [updateMemberIsLoading, setUpdateMemberIsLoading] =
        React.useState(true);

    const [updateMemberStatus, setUpdateMemberStatus] = React.useState("");

    const toggleUpdateSwitch = () => {
        setUpdateSwitch(!updateSwitch);
    };

    React.useEffect(() => {
        if (props.loggedIn) {
            var { id } = props.match.params;

            apiClient
                .get("/api/member/" + id)
                .then((response) => {
                    setMember(response.data);
                    console.log(response.data);
                    setIsLoading(false);
                    setUpdateMemberIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                });
        }
    }, [props.loggedIn, updateSwitch]); //use loggedIn as dependency - when loggedIn changes, re-render component

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setMember({
            ...member,
            [evt.target.name]: value,
        });
    }

    function setMemberAvatarUrl(avatarUrl) {
        setMember({
            ...member,
            ["avatar_url"]: avatarUrl,
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setUpdateMemberIsLoading(true);

        var data = {
            id: member.id,
            firstname: member.firstname,
            lastname: member.lastname,
            passnumber: member.passnumber,
            birthday: member.birthday,
            trainingsgroup: member.trainingsgroup,
            membership_price: member.membership_price,
            avatar_url: member.avatar_url,
            isActive: member.isActive,
            isCoach: member.isCoach,
        };

        apiClient
            .post("/api/member/" + member.id, data)
            .then((response) => {
                console.log(response.data);
                //props.setUpdateSwitch(!props.updateSwitch);
                setUpdateMemberStatus(
                    member.firstname + " " + member.lastname + " gespeichert."
                );
                setTimeout(function () {
                    setUpdateMemberStatus("");
                }, 6000);
                setUpdateMemberIsLoading(false);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
                setUpdateMemberIsLoading(false);
            });
    };

    const trainingsgroupsOptions = props.trainingsgroups.map((group) => {
        return <option value={group.id}>{group.name}</option>;
    });

    const membershipPriceOptions = props.membershipPriceModels.map((price) => {
        return <option value={price.id}>{price.name}</option>;
    });

    if (props.loggedIn) {
        return (
            <div class="mt-3 mt-md-5 ">
                <a href="/coach/mitglieder" className="d-block">
                    <MdNavigateBefore /> Zurück zu allen Mitgliedern
                </a>

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <div>
                        <h1 className="mb-1">
                            {member.firstname} {member.lastname},{" "}
                            {
                                GradingsHelper.getMaxGrading(member.gradings)
                                    .grade
                            }
                        </h1>

                        <p>
                            Mitglied seit:{" "}
                            {MemberHelper.memberSince(member.memberships)}
                        </p>

                        <Form
                            id="member-collapse"
                            onSubmit={handleSubmit}
                            className="my-5"
                        >
                            <Row className="mb-3">
                                <Col sm={6} lg={3}>
                                    <Form.Group controlId="isActive">
                                        <Form.Check
                                            type="checkbox"
                                            label="Aktiv"
                                            checked={member.isActive}
                                            onChange={handleChange}
                                            name="isActive"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <Form.Group controlId="isCoach">
                                        <Form.Check
                                            type="checkbox"
                                            label="Trainer"
                                            checked={member.isCoach}
                                            onChange={handleChange}
                                            name="isCoach"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="align-items-end">
                                <Col
                                    xs={12}
                                    md={6}
                                    xl={3}
                                    className="mt-3 mb-1"
                                >
                                    <Form.Group controlId="membershipprice">
                                        <Form.Label>
                                            Mitgliedsbeitrag
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={handleChange}
                                            name="membership_price"
                                            value={member.membership_price}
                                        >
                                            {membershipPriceOptions}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6} className="mt-3 mb-1">
                                    <AvatarSelect
                                        {...props}
                                        memberAvatarUrl={member.avatar_url}
                                        setMemberAvatarUrl={setMemberAvatarUrl}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-end">
                                <Col
                                    xs={12}
                                    md={6}
                                    xl={3}
                                    className="mt-3 mb-1"
                                >
                                    <Form.Group controlId="trainingsgroup">
                                        <Form.Label>Gruppe</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={handleChange}
                                            name="trainingsgroup"
                                            value={member.trainingsgroup}
                                        >
                                            <option value="0"></option>
                                            {trainingsgroupsOptions}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col
                                    xs={12}
                                    md={6}
                                    xl={3}
                                    className="mt-3 mb-1"
                                >
                                    <Form.Group controlId="formPassnumber">
                                        <Form.Label>Passnummer</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="passnumber"
                                            value={member.passnumber}
                                            onChange={handleChange}
                                        />
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col
                                    xs={12}
                                    md={6}
                                    xl={3}
                                    className="mt-3 mb-1"
                                >
                                    <Form.Group controlId="formBirthday">
                                        <Form.Label className="d-block">
                                            Geburtstag
                                        </Form.Label>
                                        <DatePicker
                                            selected={new Date(member.birthday)}
                                            onChange={(date) =>
                                                setMember({
                                                    ...member,
                                                    birthday: date,
                                                })
                                            }
                                            dateFormat="d. MMMM yyyy"
                                            locale="de"
                                            className="form-control"
                                            calendarStartDay={1}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="align-items-end">
                                <Col xs={12} className="mt-3 mb-4">
                                    <Button
                                        type="submit"
                                        //disabled={this.state.submitButtonDisabled}
                                    >
                                        {updateMemberIsLoading ? (
                                            <Spinner animation="border" />
                                        ) : (
                                            "Speichern"
                                        )}
                                    </Button>
                                    {updateMemberStatus}
                                </Col>
                            </Row>
                        </Form>

                        <CoachMemberPositions
                            {...props}
                            member={member}
                            toggleUpdateSwitch={toggleUpdateSwitch}
                        />

                        <CoachMemberGradings
                            {...props}
                            member={member}
                            toggleUpdateSwitch={toggleUpdateSwitch}
                        />

                        <CoachMemberMemberships
                            {...props}
                            member={member}
                            toggleUpdateSwitch={toggleUpdateSwitch}
                        />
                    </div>
                )}
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachMemberWithCoachPanel = withCoachPanel(CoachMember);

export default CoachMemberWithCoachPanel;
