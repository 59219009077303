import React from "react";

import apiClient from "../../services/api";
import TimeHelper from "../helpers/TimeHelper";

import { Row, Col, Spinner, Form, Button } from "react-bootstrap";
import { MdNavigateBefore, MdDone } from "react-icons/md";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachMemberPositionsAdd = (props) => {
    const noUTCDate = (date) => {
        return TimeHelper.noUTCDate(date);
    };

    const [newStartDate, setNewStartDate] = React.useState(null);
    const [newEndDate, setNewEndDate] = React.useState(null);
    const [newPosition, setNewPosition] = React.useState(null);

    const handleNewMemberPositionButton = (event) => {
        event.preventDefault();

        if (
            ((newStartDate && !newEndDate) ||
                (!newStartDate && !newEndDate) ||
                (newStartDate &&
                    newEndDate &&
                    newStartDate.getTime() < newEndDate.getTime())) &&
            newPosition
        ) {
            var data = {
                member_id: props.member.id,
                position_id: newPosition,
                start_date: newStartDate,
                end_date: newEndDate,
            };

            apiClient
                .post("/api/memberposition", data)
                .then((response) => {
                    console.log(response.data);
                    props.toggleUpdateSwitch();
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        } else {
            //TODO: display warning that select fields have to be chosen
            //and end_date can't be before start_date
        }
    };

    const positionsOptions = props.positions.map((position) => {
        return <option value={position.id}>{position.name}</option>;
    });

    function handleNewPositionChange(evt) {
        setNewPosition(evt.target.value);
    }

    if (props.loggedIn) {
        return (
            <div className="mt-5">
                <h5>Neue Funktion/Ausbildung</h5>

                <Row className="align-items-end">
                    <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                        <Form.Group controlId="position">
                            <Form.Label>Funktion/Ausbildung</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleNewPositionChange}
                                name="position"
                                value={newPosition}
                            >
                                <option value="0"></option>
                                {positionsOptions}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                        <p class="mb-2">Startdatum</p>
                        <DatePicker
                            selected={newStartDate}
                            onChange={(date) =>
                                setNewStartDate(noUTCDate(date))
                            }
                            dateFormat="d. MMMM yyyy"
                            locale="de"
                            className="form-control"
                            calendarStartDay={1}
                            isClearable
                        />
                    </Col>
                    <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                        <p class="mb-2">Enddatum</p>
                        <DatePicker
                            selected={newEndDate}
                            onChange={(date) => setNewEndDate(noUTCDate(date))}
                            dateFormat="d. MMMM yyyy"
                            locale="de"
                            className="form-control"
                            calendarStartDay={1}
                            isClearable
                        />
                    </Col>
                    <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                        <p className="mb-2"> </p>
                        <Button
                            className="mr-2"
                            onClick={handleNewMemberPositionButton}
                            disabled={newPosition === null ? "disabled" : false}
                        >
                            <MdDone />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachMemberPositionsAdd;
