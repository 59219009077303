import React from "react";

import apiClient from "../../services/api";
import { IMG_BASE_URL, IMG_URL_DEFAULT_MEMBER } from "../../constants";
import GradingsHelper from "../helpers/GradingsHelper";

import withPublicPanel from "../../withPublicPanel";

import { Spinner, Row, Col, Container } from "react-bootstrap";

import UnknownMember from "../../images/unknown_member.jpg";

const Members = (props) => {
    const [members, setMembers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        apiClient
            .get("/api/member")
            .then((response) => {
                let activeMembers = response.data.filter((member) => {
                    return member.isActive;
                });
                setMembers(activeMembers);
                console.log(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const memberList = members.map((member) => (
        <Col key={member.id} xs={6} md={4} lg={3} xl={2} className="mb-5">
            <div class="mb-2">
                <a
                    href={"/mitglieder/" + member.slug}
                    title={member.firstname + " " + member.lastname}
                >
                    <img
                        src={
                            member.avatar_url
                                ? IMG_BASE_URL + member.avatar_url
                                : UnknownMember //IMG_BASE_URL + IMG_URL_DEFAULT_MEMBER
                        }
                        className="img-fluid"
                    />
                </a>
            </div>
            <p class="text-center">
                {member.firstname} {member.lastname} <br />
                <small>
                    {GradingsHelper.getMaxGrading(member.gradings).grade}
                </small>
            </p>
        </Col>
    ));

    return (
        <Row className="bg-light">
            <Col>
                <Container className="mt-3 mt-md-5">
                    <div class="page-header">
                        <small className="text-primary">#kwonbaekfamily</small>
                        <h1>Mitglieder</h1>
                    </div>
                    {isLoading ? (
                        <div class="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <Row className="mb-7">{memberList}</Row>
                    )}
                </Container>
            </Col>
        </Row>
    );
};

const MembersWithPublicPanel = withPublicPanel(Members);
export default MembersWithPublicPanel;
