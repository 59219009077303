import React, { useState } from "react";

import apiClient from "../../services/api";

import { Button, Form, Col, Row, Spinner } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachCompetitionsAdd = (props) => {
    /*
     *   Add Competition
     */

    const [newCompetition, setNewCompetition] = useState({ date: new Date() });

    const setNewDate = (date) => {
        setNewCompetition({
            ...newCompetition,
            ["date"]: date,
        });
    };

    function handleChange(evt) {
        const value =
            evt.target.type === "checkbox"
                ? evt.target.checked
                : evt.target.value;

        setNewCompetition({
            ...newCompetition,
            [evt.target.name]: value,
        });
    }

    const addCompetitionIsLoading = false;

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = {
            date: newCompetition.date,
            name: newCompetition.name,
            location: newCompetition.location,
        };

        apiClient
            .post("/api/competition", data)
            .then((response) => {
                console.log(response.data);
                props.switchUpdateSwitch();
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            });
    };

    if (props.loggedIn) {
        return (
            <div>
                <h3>Neue Meisterschaft</h3>

                <Form id="competition-collapse" onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Datum</Form.Label>
                            <DatePicker
                                selected={newCompetition.date}
                                onChange={(date) => setNewDate(date)}
                                dateFormat="d. MMMM yyyy"
                                locale="de"
                                className="form-control"
                                calendarStartDay={1}
                            />
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Meisterschaft</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newCompetition.name}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Form.Label>Ort</Form.Label>
                            <Form.Control
                                type="text"
                                name="location"
                                value={newCompetition.location}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6} xl={3} className="mt-3 mb-1">
                            <Button
                                type="submit"
                                //disabled={this.state.submitButtonDisabled}
                            >
                                {addCompetitionIsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    "Meisterschaft anlegen"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

export default CoachCompetitionsAdd;
