import React, { useState } from "react";

import apiClient from "../../services/api";

import CoachTrainingssessionMembers from "./CoachTrainingssessionMembers";

import withCoachPanel from "../../withCoachPanel";

import { MdNavigateBefore } from "react-icons/md";

const CoachTrainingssession = (props) => {
    /*
     *   Show Single Traning Session
     */

    const [trainingssession, setTrainingssession] = React.useState(null);
    const [updateSwitch, setUpdateSwitch] = React.useState(false);

    React.useEffect(() => {
        var { id } = props.match.params;

        if (props.loggedIn) {
            apiClient
                .get("/api/trainingssession/" + id)
                .then((response) => {
                    setTrainingssession(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [props.loggedIn, updateSwitch]);

    const date = (trainingssession) => {
        if (trainingssession) {
            var options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            var tsdate = new Date(trainingssession.date);
            return <span>{tsdate.toLocaleDateString("de-DE", options)}</span>;
        }
    };

    if (props.loggedIn) {
        var header = <h1>Trainingseinheit am {date(trainingssession)}</h1>;
        var members = <div></div>;
        if (trainingssession) {
            members = (
                <CoachTrainingssessionMembers
                    {...props}
                    trainingssession={trainingssession}
                    updateSwitch={updateSwitch}
                    setUpdateSwitch={setUpdateSwitch}
                ></CoachTrainingssessionMembers>
            );
        }
        return (
            <div class="mt-3 mt-md-5">
                <a href="/coach/trainingssessions">
                    <MdNavigateBefore /> Zurück zu allen Einheiten
                </a>
                {header} {members}
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachTrainingssessionWithCoachPanel = withCoachPanel(
    CoachTrainingssession
);
export default CoachTrainingssessionWithCoachPanel;
