import React, { useState } from "react";

import apiClient from "../../services/api";

import { Modal, Button, Spinner } from "react-bootstrap";
import {
    MdInsertDriveFile,
    MdFolder,
    MdDelete,
    MdCreateNewFolder,
} from "react-icons/md";

import FileUpload from "./FileUpload";

const FileBrowser = (props) => {
    const handleClose = () => props.setShow(false);

    const [files, setFiles] = React.useState([]);
    const [dirs, setDirs] = React.useState([]);

    const [curDir, setCurDir] = React.useState("public");
    const [selectedFile, setSelectedFile] = React.useState({
        url: props.fileUrl,
    });

    const [fileStructureLoading, setFileStructureLoading] =
        React.useState(false);

    React.useEffect(() => {
        getDirContent(curDir); //initial directory
    }, [props.loggedIn]);

    React.useEffect(() => {
        setSelectedFile({ url: props.fileUrl });
    }, [props.fileUrl]);

    function getDirContent(dir = "public") {
        let encodeURI = dir; //encodeURIComponent(dir);

        if (props.loggedIn) {
            setFileStructureLoading(true);
            apiClient
                .get("/api/files-and-dirs/" + encodeURI)
                .then((response) => {
                    setFiles(response.data.files);
                    setDirs(response.data.directories);

                    console.log(response.data);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    setFileStructureLoading(false);
                });
        }
    }

    const handleFileClick = (evt, file) => {
        if (selectedFile && file.url === selectedFile.url) {
            //already selected
            setSelectedFile(undefined);
        } else {
            setSelectedFile(file);
        }
    };

    const handleDirClick = (evt, dirname) => {
        setCurDir(dirname);
        getDirContent(dirname);

        console.log("dirclick: ", selectedFile);
    };

    const dirUp = () => {
        let splitIdx = curDir.lastIndexOf("/");
        //if no subdirectory
        if (splitIdx != -1) {
            let dirAbove = curDir.substring(0, splitIdx);
            setCurDir(dirAbove);
            getDirContent(dirAbove);
        }
    };

    const getCurDirs = dirs.map((dir) => {
        return (
            <a className="d-block" onClick={(e) => handleDirClick(e, dir)}>
                <MdFolder /> {dir.replace(curDir, "").replace("/", "")}
            </a>
        );
    });

    const getCurFiles = files.map((file) => {
        let fileName = file.name.replace(curDir, "").replace("/", "");
        let className = "d-block";
        if (selectedFile)
            className =
                className + (file.url === selectedFile.url ? " selected" : "");

        return (
            <a onClick={(e) => handleFileClick(e, file)} className={className}>
                <MdInsertDriveFile /> {fileName}
            </a>
        );
    });

    const handleSelect = () => {
        props.setFileUrl(selectedFile.url);
        handleClose();
    };

    function afterUpload() {
        getDirContent(curDir);
    }

    function isSelectedFileInCurrentDir() {
        if (!selectedFile || !selectedFile.name) return false;

        let splitIdx = selectedFile.name.lastIndexOf("/");
        //get path of selected file
        if (splitIdx != -1) {
            let path = selectedFile.name.substring(0, splitIdx);
            if (path === curDir) return true;
            else return false;
        }
    }

    function deleteFile() {
        if (props.loggedIn) {
            let encodeURI = selectedFile.name; //encodeURIComponent(selectedFile.name);

            setFileStructureLoading(true);
            apiClient
                .delete("/api/file/" + encodeURI)
                .then((response) => {
                    console.log(response.data);
                    getDirContent(curDir);
                    setSelectedFile(undefined);
                })
                .catch((error) => {
                    if (props.checkAuthError(error)) {
                    } else {
                        console.error(error);
                    }
                    setFileStructureLoading(false);
                });
        }
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                size="lg"
                className="filebrowser"
            >
                <Modal.Header>
                    <Modal.Title>{curDir}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <Button size="sm" variant="secondary">
                            <MdCreateNewFolder /> Neuer Ordner
                        </Button>
                        <Button
                            disabled={!isSelectedFileInCurrentDir()}
                            size="sm"
                            className="ms-3"
                            variant="outline-danger"
                            onClick={deleteFile}
                        >
                            <MdDelete /> Löschen
                        </Button>
                    </div>

                    {fileStructureLoading ? (
                        <Spinner animation="border" />
                    ) : (
                        <>
                            {curDir != "public" ? (
                                <a className="d-block" onClick={dirUp}>
                                    <MdFolder /> ..
                                </a>
                            ) : (
                                <></>
                            )}
                            {getCurDirs}
                            {getCurFiles}
                        </>
                    )}

                    <div className="mt-5">
                        <FileUpload
                            {...props}
                            path={curDir}
                            afterUpload={afterUpload}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Abbrechen
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSelect}
                        disabled={
                            !selectedFile || (selectedFile && !selectedFile.url)
                        }
                    >
                        Auswählen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileBrowser;
