import React from "react";

import {
    Table,
    ProgressBar,
    Tabs,
    Tab,
    Spinner,
    Form,
    Button,
    Modal,
} from "react-bootstrap";

import { Bar } from "react-chartjs-2";
import { MdShowChart } from "react-icons/md";

import TrainingssessionMemberOverTime from "./TrainingssessionMemberOverTime";

import apiClient from "../../services/api";
import withCoachPanel from "../../withCoachPanel";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import GradingsHelper from "../helpers/GradingsHelper";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
setDefaultLocale("de");

const CoachTrainingssessionMembersSum = (props) => {
    const [members, setMembers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    var initialDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    );

    const [startDate, setStartDate] = React.useState(initialDate);

    const [showSessionsPerYearModal, setShowSessionsPerYearModal] =
        React.useState(false);
    const [sessionsPerYearData, setSessionsPerYearData] = React.useState([]);

    const handleCloseSessionsPerYearModal = () => {
        setShowSessionsPerYearModal(false);
        setSessionsPerYearData([]);
    };

    const handleShowSessionsPerYearModal = (sessionsPerYear) => {
        setShowSessionsPerYearModal(true);
        setSessionsPerYearData(sessionsPerYear);
    };

    function getMembersAttendance() {
        setIsLoading(true);

        var data = {
            startDate: startDate,
        };

        apiClient
            .get("/api/member/trainingssession")
            .then((response) => {
                let activeMembers = response.data.filter((member) => {
                    return member.isActive;
                });
                /*
                activeMembers.sort(function (a, b) {
                    if (a.lastname > b.lastname) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                */
                GradingsHelper.sortMembersByGrade(activeMembers, "order");

                setMembers(activeMembers);
                console.log(response.data);
            })
            .catch((error) => {
                if (props.checkAuthError(error)) {
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    React.useEffect(() => {
        if (props.loggedIn) {
            getMembersAttendance();
        }
    }, [props.loggedIn]);

    const handleSubmit = (event) => {
        event.preventDefault();
        getMembersAttendance();
    };

    const getProgressBarVariant = (member) => {
        if (member.countSessions / member.sumSessions > 0.66) return "success";
        else return "warning";
    };

    var memberList = members.map((member) => {
        var options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        var date = new Date(member.latest_grading);

        return (
            <tr key={member.id}>
                <td>
                    {member.lastname} {member.firstname}
                </td>
                <td>{member.belt_grade}</td>
                <td>
                    {member.latest_grading &&
                        date.toLocaleDateString("de-DE", options)}
                </td>
                <td>
                    <ProgressBar
                        now={(member.countSessions / member.sumSessions) * 100}
                        label={`${member.countSessions} von ${member.sumSessions}`}
                        variant={getProgressBarVariant(member)}
                    />
                </td>
                <td>
                    <Button
                        variant="primary"
                        onClick={() =>
                            handleShowSessionsPerYearModal(
                                member.sessionsPerYear
                            )
                        }
                        size="sm"
                    >
                        <MdShowChart />
                    </Button>
                </td>
            </tr>
        );
    });

    const memberListHeader = (
        <Table responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Graduierung</th>
                    <th>Letzte Prüfung</th>
                    <th>Trainingseinheiten</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>{memberList}</tbody>
        </Table>
    );

    const dateFilter = (
        <Form id="datefilter" onSubmit={handleSubmit} className="text-md-end">
            <Form.Group
                controlId="formStartDate"
                className="d-inline-block me-3"
            >
                <Form.Label className="text-start">Seit</Form.Label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="d. MMMM yyyy"
                    locale="de"
                    className="form-control form-control-sm"
                    calendarStartDay={1}
                />
            </Form.Group>

            <Button type="submit" variant="dark" size="sm" className="mb-1">
                Anwenden
            </Button>
        </Form>
    );

    const modalData = {
        labels: sessionsPerYearData.map((datapoint) => {
            return datapoint.year;
        }),
        datasets: [
            {
                label: "Trainingseinheiten",
                data: sessionsPerYearData.map((datapoint) => {
                    return datapoint.countSessions;
                }),
                fill: false,
                backgroundColor: "rgb(0, 149, 183)",
                borderColor: "rgba(0, 149, 183, 0.3)",
                tension: 0.1,
            },
        ],
    };

    const SessionsPerYearModal = (
        <Modal
            show={showSessionsPerYearModal}
            onHide={handleCloseSessionsPerYearModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Sessions/Year</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Bar data={modalData} />
            </Modal.Body>
        </Modal>
    );

    if (props.loggedIn) {
        return (
            <div className="mt-3 mt-md-5 mb-6">
                <h1 className="mb-lg-0">Trainingsheiten</h1>

                {isLoading ? (
                    <div class="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <>
                        {dateFilter}
                        <Tabs
                            defaultActiveKey="member"
                            id="trainingssession-stats"
                        >
                            <Tab eventKey="member" title="Mitglieder">
                                {memberListHeader}
                            </Tab>
                            <Tab eventKey="time" title="Zeit">
                                <TrainingssessionMemberOverTime
                                    loggedIn={props.loggedIn}
                                />
                            </Tab>
                        </Tabs>
                    </>
                )}

                {SessionsPerYearModal}
            </div>
        );
    }
    return <div className="alert alert-warning">You are not logged in.</div>;
};

const CoachTrainingssessionMembersSumWithCoachPanel = withCoachPanel(
    CoachTrainingssessionMembersSum
);
export default CoachTrainingssessionMembersSumWithCoachPanel;
