import React from "react";

import apiClient from "../../services/api";

import { Container } from "react-bootstrap";

import Navigation from "../Navigation";

import HomepageBanner from "./HomepageBanner";
import WhyTaekwondo from "./WhyTaekwondo";
import WhyKwonbaek from "./WhyKwonbaek";
import HighlightsOfTheYear from "./HighlightsOfTheYear";

const Homepage = (props) => {
    return (
        <>
            <Container>
                <Navigation
                    loggedIn={props.loggedIn}
                    authLink={props.authLink}
                />
            </Container>
            <HomepageBanner />
            <WhyTaekwondo />
            <WhyKwonbaek {...props} />
            <HighlightsOfTheYear />
        </>
    );
};

export default Homepage;
